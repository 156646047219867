import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import 'react-multi-carousel/lib/styles.css'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import bozt from '../assets/images/map_bozt.png'
import { BsSearch } from 'react-icons/bs'
import Loader from './common/Spinner.js'
import EmptyMessage from './common/EmptyMessage.js'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Input from './common/Input.js'
import Button from './common/Button.js'
import PageHeader from './common/PageHeader.js'
import { useSelector } from 'react-redux'
import RestaurantModal from './modal/RestaurantModal.js'
import RestaurantsList from './common/RestaurantsList.js'
import { CloseIcon } from './Search.js'
import { toast } from 'react-toastify'
import { doAddFavoriteRestaurant, doFetchList, doGetCurrentAddress, doGetCurrentLatLng } from './api/apis.js'

const AddFavoriteRest = () => {
	const [location, setLocation] = useState('')
	const [loading, setLoading] = useState(false)
	const [listData, setListData] = useState([])
	const [copyListData, setCopyListData] = useState([])
	const [page, setPage] = useState(0)
	const [searchRest, setSearchRest] = useState('')
	const [showMore, setShowMore] = useState(false)
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [error, setError] = useState(false)
	const [selectedRestaurant, setSelectedRestaurant] = useState()
	const [openRestDet, setOpenRestDet] = useState(false)
	const _latLng = useSelector((state) => state?.latLng)
	const [pageToken, setPageToken] = useState('')
	const [tmpLat, setTmpLat] = useState('')
	const [tmplng, setTmpLng] = useState('')

	const handleSelect = async (value) => {
		setLocation(value)
		setLoading(true)
		geocodeByAddress(value)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				setLat(latLng.lat)
				setLng(latLng.lng)
				setTmpLat(latLng.lat)
				setTmpLng(latLng.lng)
				fetchList(0, latLng.lat, latLng.lng)
				// setLoading(false)
			})
			.catch((error) => {
				console.error('Error', error)
				setLoading(false)
			})
	}

	useEffect(() => {
		getCurrentLatLng()
	}, [])

	const getCurrentLatLng = async () => {
		await doGetCurrentLatLng(setLoading, setLocation, setLat, setLng, fetchList, setTmpLat, setTmpLng)
	}

	const isMobileOrTablet = () => {
		const ua = navigator.userAgent.toLowerCase();
		return /mobile|tablet|ipad|android|ios/.test(ua);
	};

	const fetchList = async (pageNum = 0, lat, lng, searchByName) => {
		await doFetchList(pageNum, lat, lng, searchByName, setLoading, listData, setListData, setShowMore, setCopyListData, _latLng, setPageToken, pageToken, setLat, setLng, isMobileOrTablet())
	}

	const handleAddFavorite = async (item) => {
		await doAddFavoriteRestaurant(item, setLoading, listData, setListData)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader padding={'0'} title={'Add Favorite'} />
						<InnerWrapper>
							<SearchIcon />
							<SearcInput>
								<Input
									width={'100%'}
									borderRadius={'0.2rem'}
									margin={'0'}
									padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
									value={searchRest}
									onChange={(event) => {
										setSearchRest(event.target.value)
										if (event.target.value.length >= 2 && lat !== '' && lng !== '') {
											setError(false)
											if (isMobileOrTablet() && event.target.value.length >= 4) {
												fetchList(0, showMore ? lat : tmpLat, showMore ? lng : tmplng, event.target.value)
											}

										} else if (event.target.value.length == 0) {
											setSearchRest('')
											setListData(copyListData)
											setError(false)
										} else {
											setError(event.target.value.length >= 2 ? false : true)
										}
									}}
									placeholder="Restaurant Name"
									onKeyDown={(e) => {
										if (e?.key == "Enter" && searchRest?.trim()?.length >= 2 && lat && lng && tmpLat && tmplng) {
											fetchList(0, showMore ? lat : tmpLat, showMore ? lng : tmplng, searchRest)
										} else {
											if (e?.key == "Enter" && searchRest?.trim()?.length && lat == '' && lng == '') {
												toast.error('Please choose location first')
											}
										}
									}}
								/>
							</SearcInput>
							{error && <Error>Characters must be greater than two</Error>}
						</InnerWrapper>
						<PlacesAutocomplete
							value={location}
							onChange={(e) => {
								setPageToken('')
								setLocation(e)
							}}
							onSelect={(e) => {
								setPageToken('')
								handleSelect(e)
							}}>
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div style={{ position: 'relative' }}>
									<Input width={'100%'} borderRadius={'0.2rem'} margin={'0'} padding={'0.8rem 0.8rem 0.8rem 1rem'} value={location} {...getInputProps()} placeholder="Search location" />
									<CloseIcon
										onClick={() => {
											setPageToken('')
											setLocation('')
										}}
									/>
									<FetchLocationContainer
										onClick={() => {
											setLat(_latLng?.latitude)
											setLat(_latLng?.longitude)
											// doGetCurrentAddress(_latLng?.latitude, _latLng?.longitude, setLoading, setLocation)
											getCurrentLatLng()
											setSearchRest('')
										}}>
										<Image src={bozt} alt="fetch" />
										<LocationText>Current Location</LocationText>
									</FetchLocationContainer>
									{suggestions?.length > 0 ? (
										<List>
											{loading ? <Loader visible={loading} text={'Loading...'} top={'0'} /> : null}
											{suggestions?.length > 0
												? suggestions.map((suggestion, i) => {
													const style = {
														backgroundColor: suggestion.active ? '#FBFAF8' : 'white'
													}
													return (
														<LocationList
															key={i + 'mapkey'}
															{...getSuggestionItemProps(suggestion, {
																style
															})}>
															{suggestion.description}
														</LocationList>
													)
												})
												: ''}
										</List>
									) : (
										''
									)}
								</div>
							)}
						</PlacesAutocomplete>

						{listData.length
							? listData?.map((item, index) => {
								return <RestaurantsList restaurantsList={item} key={index.toString()} setOpenRestDet={(e) => setOpenRestDet(e)} setSelectedRestaurant={(e) => setSelectedRestaurant(e)} addFavourite={(e) => handleAddFavorite(e)} />
							})
							: ''}
						{showMore && searchRest.length < 1 ? (
							<Button
								width={'100%'}
								margin={'0.5rem 0'}
								color={color.White}
								bgtype={color.Orange}
								fontSize={'1rem'}
								height={'3.125rem'}
								label={'Load More'}
								onClick={() => {
									setPage(page + 50)
									fetchList(page + 50, lat, lng)
								}}
							/>
						) : null}
						{loading ? (
							<Loader visible={loading} text={'Loading...'} />
						) : searchRest === '' && listData?.length === 0 ? (
							location !== '' && <EmptyMessage message={'No restaurants found'} top={'4rem'} />
						) : (
							searchRest && listData?.length === 0 && <EmptyMessage message={'No restaurants found'} top={'4rem'} />
						)}
					</Col>
				</CustomRow>
				{openRestDet && <RestaurantModal setIsOpen={setOpenRestDet} r_id={selectedRestaurant?.r_id} id={selectedRestaurant?.id} lat={_latLng?.latitude} lng={_latLng?.longitude} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.5rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const InnerWrapper = styled.div`
	width: 100%;
	padding: 1rem 0rem 0rem 0rem;
	position: relative;
`
const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 1.8rem;
	z-index: 1;
	left: 0.6rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`
const SearcInput = styled.div`
	margin-bottom: 1.5rem;
`

const LocationList = styled.div`
	background-color: ${color.White};
	text-align: left;
	padding: 0.5rem;
	cursor: pointer;
`

const List = styled.div`
	position: absolute;
	background: ${color.White};
	z-index: 1;
	width: 98.5%;
`

const FetchLocationContainer = styled.div`
	display: flex;
	padding: 0.5rem 0rem;
	cursor: pointer;
`
const Image = styled.img`
	height: 2rem;
`
const LocationText = styled.p`
	margin: 0.1rem 0rem 0rem 0.5rem;
`

const Error = styled.span`
	color: ${color.Red};
	position: absolute;
	top: 3.8rem;
	left: 0.5rem;
	font-size: 0.9rem;
`

export default AddFavoriteRest
