import React from 'react'
import styled from 'styled-components'
import { Container, Row, media } from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { color } from '../../assets/css/Color.js'
import Button from '../common/Button.js'

const ConfirmationModel = (props) => {


	return (
		<Container>
			<Row>
				<Modal
					open={true}
					onClose={() => {
						props.openModal(false)
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'confirmationModel'
					}}>
					<Flexed direction="column" justify="center" align="center" width="20rem">
						{props?.title && <Span weight={500} style={{ fontSize: '1.5rem', padding: '0.5rem' }}>
							Share Restaurant
						</Span>}
						{props?.body && <span style={{ padding: '0.5rem', textAlign: 'center' }}>{props?.body}</span>}
						<HorizontalRow />


						<Flexed direction="row" justify="center" align="center" width="20rem" gap={'0.5rem'}>
							<CommonButton

								width={'30%'}
								radius={'1.5rem'}
								bgtype={color.LightGrey}
								border={'0.063rem solid transparent'}
								fontWeight={'bolder'}
								textColor={color.DarkPepper_40}
								margin={'1rem 0rem'}
								display={'flex'}
								padding={'0.7rem'}
								fontSize={'1rem'}

								onClick={() => {
									props.openModal(false)
								}}
							>
								No
							</CommonButton>
							<CommonButton
								width={'30%'}
								radius={'1.5rem'}
								bgtype={color.Orange}
								border={'0.063rem solid transparent'}
								fontWeight={'bolder'}
								textColor={color.White}
								margin={'1rem 0rem'}
								display={'flex'}
								padding={'0.7rem'}
								fontSize={'1rem'}

								onClick={() => {
									props.onClick()
								}}
							>Yes</CommonButton>
						</Flexed>

					</Flexed>
				</Modal>
			</Row>
		</Container>
	)
}

const CommonButton = styled.button`
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.8rem')};
	color: ${({ textColor }) => (textColor ? textColor : color.Grey)};
	width: ${({ width }) => (width ? width : '')};
	display: ${({ display }) => (display ? display : 'block')};
	background-color: ${({ bgtype }) => (bgtype ? bgtype : 'transparent')};
	justify-content: center;
	height: ${({ height }) => (height ? height : '')};
	border-radius: ${({ radius }) => (radius ? radius : '0rem')};
	align-items: center;
	border: ${({ border }) => (border ? border : 'none')};
	box-shadow: ${({ shadow }) => (shadow ? shadow : 'none')};
	cursor: ${({ disabled }) => (disabled === true ? 'no-drop' : 'pointer')};
	margin: ${({ margin }) => (margin ? margin : '0')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : '')};
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '')};
	flex-direction: ${({ flexDirection }) => flexDirection};
	padding: ${({ padding }) => padding};
	font: ${({ font }) => font};
	${media.xs`
		width: 100%;
	`}
	${media.sm`
		width: 50%;
	`}
	${media.md`
		width: 30%;
	`}
  	${media.lg`
		  width: 30%;
	`}
`

const Flexed = styled.div`
	position: relative;
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	gap: ${({ gap }) => gap};
	margin: ${({ margin }) => `${margin}`};
	width: ${({ width }) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({ width }) => `${width}`};
  `}
`
const Listview = styled.li`
	background: ${color.LightCream_60};
	position: relative;
	list-style: none;
	padding: 1rem;
	width: 100%;
	color: ${({ color }) => `${color}`};
	font-weight: ${({ weight }) => `${weight}`};
	cursor: pointer;
	display: flex;
	justify-content: start;
	align-items: center;
	box-shadow: 0rem 0.05rem 0.1rem ${color.LightCream_60};
`

const HorizontalRow = styled.div`
	margin-top: 0.6rem;
	width: 100%;
	border: none;
`
const IconContainer = styled.div`
	padding: 0rem 0.5rem;
	display: flex;
	align-items: center;
`

const Popup = styled(Listview)`
	position: absolute;
	width: 15rem;
	border-radius: 0.5rem;
	background-color: ${color.Grey};
	top: 7rem;
	color: ${color.White};
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`
const Span = styled.span`
	font-weight: ${(weight) => weight};
	font-family: sans-serif;
`
const Copy = styled.img`
	width: 1rem;
`
export default ConfirmationModel
