import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import Loader from './Spinner.js'

import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import PageHeader from './PageHeader.js'
import EmptyMessage from './EmptyMessage.js'
import { doFetchFavRestaurantUsers, doFollowUnfollowUser } from '../api/apis.js'
import { color } from '../../assets/css/Color.js'
import FollowersList from '../FollowersList.js'

const UserRestaurantLikesList = (props) => {
    const _navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [likedUsersList, setLikedUsersList] = useState([])
    const [loading, setLoading] = useState(false)
    const [nextPageToken, setNextPageToken] = useState(1)
    const [showLoadMore, setShowLoadMore] = useState(false)

    useEffect(() => {
        async function fetchData() {
            await doFetchUsersList()
        }
        fetchData()
    }, [props?.id])

    const doFetchUsersList = async (nextPage = 1) => {
        if (props?.id) {
            await doFetchFavRestaurantUsers(nextPage, setLoading, props?.id, setShowLoadMore, likedUsersList, setLikedUsersList)

        }
    }

    const doFollowUnfollow = async (f_id, follower_status) => {
        await doFollowUnfollowUser(f_id, follower_status, setLoading, likedUsersList, setLikedUsersList)
    }

    const navigationHandler = (id) => {
        _navigate({
            pathname: '/profile',
            search: createSearchParams({
                id: id
            }).toString()
        })
    }

    const doFetchMoreUsersList = async () => {
        setNextPageToken(nextPageToken + 1)
        await doFetchUsersList(nextPageToken + 1)
    }

    return (
        <>
            <Wrapper>
                <PageHeader padding={'0'} title={'Favorites By'} setIsRestPage={props.setIsRestPage} />
                {likedUsersList?.length > 0
                    ? likedUsersList?.map((item, index) => {
                        return (
                            <>
                                <FollowersList item={item} index={index} navigationHandlerCB={navigationHandler} doFollowUnfollowCB={doFollowUnfollow} />
                            </>
                        )
                    })
                    : !loading && <EmptyMessage message="No user found"></EmptyMessage>}
                {showLoadMore && (
                    <ShowMore
                        onClick={() => {
                            doFetchMoreUsersList()
                        }}>
                        Load More
                    </ShowMore>
                )}
                {loading && <Loader visible={loading} text={'Loading...'} />}
            </Wrapper>
        </>
    )
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 0rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	/* top: 3.5rem; */
`

const ShowMore = styled.button`
	font-size: 1rem;
	color: ${color.White};
	width: 100%;
	display: flex;
	background-color: ${color.Orange};
	justify-content: center;
	height: 3.125rem;
	align-items: center;
	cursor: pointer;
	border: none;
	outline: none;
	align-self: center;
`

export default UserRestaurantLikesList
