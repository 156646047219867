import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed} from '../assets/shared'
import FeedCard from './FeedCard'
import {Modal} from 'react-responsive-modal'
import {BsChevronLeft} from 'react-icons/bs'
import {color} from '../assets/css/Color'
import {useSelector} from 'react-redux'
import RestaurantModal from './modal/RestaurantModal'
import {doViewBozt} from './api/apis'

const OpenSharedModel = () => {
	const [openBoztModel, setOpenBoztModel] = useState(false)
	const [boztData, setBoztData] = useState()
	const [openRestDet, setOpenRestDet] = useState(false)
	const _navigate = useNavigate()
	const _latLng = useSelector((state) => state?.latLng)

	const [searchParams] = useSearchParams()
	let id = searchParams.get('id')

	useEffect(() => {
		viewBozt(id)
	}, [id])

	const viewBozt = async (id) => {
		await doViewBozt(id, setOpenRestDet, setBoztData, setOpenBoztModel)
	}

	return (
		<div>
			<Wrapper>
				<CustomRow>
					<Modal
						open={openBoztModel}
						onClose={() => {
							console.log('')
						}}
						center
						classNames={{
							overlay: 'customOverlay',
							modal: 'customRestModal'
						}}>
						<Flexed>
							<HeaderWrapper>
								<BackBtn
									xs={2}
									sm={2}
									md={1}
									lg={1}
									onClick={() => {
										_navigate('/')
									}}>
									<Icon /> Back
								</BackBtn>
								<Title xs={10} sm={10} md={11} lg={11} weight={'500'}>
									{'Bozt Details'}
								</Title>
							</HeaderWrapper>

							{openBoztModel && <FeedCard feedData={boztData} />}
						</Flexed>
					</Modal>
				</CustomRow>
				{openRestDet && <RestaurantModal setIsOpen={setOpenRestDet} r_id={boztData?.r_id ? boztData?.r_id : id?.substring(1)} lat={_latLng?.latitude} lng={_latLng?.longitude} isShare={true} />}
			</Wrapper>
		</div>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const HeaderWrapper = styled(Col)`
	width: 100%;
	padding: ${({padding}) => `${padding ? padding : '0'}`};
	display: flex;
	justify-content: start;
	font-weight: 500;
	align-items: center;
`

const BackBtn = styled.div`
	padding: 1.6rem 0rem;
	display: flex;
	font-size: 1rem;
	display: flex;
	align-items: center;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Icon = styled(BsChevronLeft)`
	font-size: 1rem;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Title = styled.h3`
	text-align: center;
	font-weight: ${({weight}) => `${weight}`};
	background-color: transparent;
	width: 84%;
`
export default OpenSharedModel
