import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, media} from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import {Modal} from 'react-responsive-modal'
import {color} from '../../assets/css/Color.js'
import 'react-toastify/dist/ReactToastify.min.css'
import Loader from '../common/Spinner.js'
import {doSubmitReport} from '../api/apis.js'

const WriteReport = (props) => {
	const [countWords, setCountWords] = useState(0)
	const [reportText, setReportText] = useState('')
	const [disableBtn, setDisableBtn] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (countWords === 0 || countWords > 1000) {
			setDisableBtn(true)
		} else setDisableBtn(false)
	}, [countWords])

	const submitReport = async () => {
		await doSubmitReport(props, reportText, closeModal)
	}

	const closeModal = async () => {
		props.setWriteReport(false)
		setLoading(false)
	}

	return (
		<Container>
			{loading === false && (
				<Row>
					<Modal
						open={true}
						onClose={() => {
							props.setWriteReport(false)
						}}
						center
						classNames={{
							overlay: 'customOverlay',
							modal: 'customModal'
						}}>
						<Flexed direction="column" justify="center" align="center" width="20rem">
							<Listview weight="500">Want to report?</Listview>
							<HorizontalRow />
							<TextArea
								min={1000}
								placeholder="Enter a Reason"
								value={reportText}
								onChange={(e) => {
									if (e.target.value.length <= 1000) {
										setReportText(e.target.value)
										setCountWords(e.target.value?.length)
									}
								}}></TextArea>
							<HorizontalRow />
							<Counter>{countWords}/1000</Counter>
							<HorizontalRow />
							<ContinueButton
								disable={disableBtn}
								onClick={() => {
									if (countWords > 0 && countWords <= 1000 && !disableBtn) {
										setDisableBtn(true)
										submitReport()
									}
								}}>
								Submit
							</ContinueButton>
							<HorizontalRow />
						</Flexed>
					</Modal>
				</Row>
			)}
			{loading && <Loader visible={loading} />}
		</Container>
	)
}

const Flexed = styled.div`
	position: relative;
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({width}) => `${width}`};
  `}
`
const Listview = styled.li`
	list-style: none;
	font-weight: ${({weight}) => `${weight}`};
	cursor: pointer;
	font-size: 1.5rem;
	font-family: sans-serif;
	background-color: transparent;
`

const HorizontalRow = styled.div`
	margin-top: 1rem;
	width: 100%;
`
const TextArea = styled.textarea`
	width: 90%;
	min-height: 10rem;
	resize: none;
	padding: 0.4rem;
`
const Counter = styled.div`
	width: 90%;
	display: flex;
	justify-content: end;
`

const ContinueButton = styled.button`
	width: 90%;
	padding: 0.85rem;
	border-radius: 0.2rem;
	background-color: ${({disable}) => (disable === true ? '' : `${color.Orange}`)};
	color: ${({disable}) => (disable === true ? `${color.LightGrey}` : `${color.White}`)};
	font-weight: bold;
	border: none;
	cursor: ${({disable}) => (disable ? 'no-drop' : 'pointer')};
`

export default WriteReport
