import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import 'react-multi-carousel/lib/styles.css'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import Loader from './common/Spinner.js'
import arrow from '../assets/images/arrow-right.png'
import website from '../assets/images/website.png'
import 'react-toastify/dist/ReactToastify.min.css'
import share from '../assets/images/share.png'
import star from '../assets/images/star_empty.png'
import starFilled from '../assets/images/favorite_filled.png'
import ShareModal from './modal/ShareModal.js'
import { api } from './api/callAxios.js'
import PageHeader from './common/PageHeader.js'
import { useSelector } from 'react-redux'
import MediaViewer from './common/MediaViewer.js'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { doAddToWishlist, doFavoriteResturaunt, doGetBoztedByDetails, doGetDetails, doRemoveFavoritesResturaunt } from './api/apis.js'
import ConfirmationModel from './modal/ConfirmationModel.js'

const ResturantDetails = (props) => {
	const [loading, setLoading] = useState(true)
	const [resturantData, setResturantData] = useState([])
	const [data, setData] = useState({})
	const [distance, setDistance] = useState('')
	const [isClosedNow, setIsClosedNow] = useState(false)
	const [favorite, setFavorite] = useState(false)
	const [openShareModal, setOpenShareModal] = useState(false)
	const [favoriteRestaurants, setFavoriteRestaurants] = useState([])
	const [boztedUsers, setBoztedUsers] = useState([])
	const [removeFavoriteModel, setRemoveFavoriteModel] = useState(false)
	const [favLoading, setFavLoading] = useState(true)
	const user = useSelector((state) => state?.userData)

	useEffect(() => {
		if (props.r_id) {
			doFetchData()
		}
	}, [props])

	const doFetchData = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				await getDetails(position.coords.latitude, position.coords.longitude)
			},
			function (error) {
				getDetails()
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{ timeout: 5000, enableHighAccuracy: true }
		)
	}

	const getDetails = async (_lat, _lng) => {
		let res = await doGetDetails(_lat, _lng, setLoading, props, setFavorite, setResturantData, setData, setDistance, setIsClosedNow, setFavoriteRestaurants)
		if (res) {
			await doGetBoztedByDetails(setFavLoading, props, setFavoriteRestaurants, setBoztedUsers)
		}
	}

	const addToWishlist = async () => {
		await doAddToWishlist(data, setLoading)
	}

	const doFavoritesResturaunt = async () => {
		let response = await doFavoriteResturaunt(resturantData, props, setFavorite, setLoading)

		if (response) {
			setFavoriteRestaurants([...favoriteRestaurants, { id: user?.id, name: user?.first_name + ' ' + user?.last_name, degree: user?.degree }])
		}
	}

	const doDeleteFavoritesResturaunt = async () => {
		let response = await doRemoveFavoritesResturaunt(props, setFavorite, setLoading)
		if (response) {
			let res = favoriteRestaurants.filter((e) => e.id != user?.id)
			setFavoriteRestaurants(res)
			setRemoveFavoriteModel(false)
		}
	}

	const navigateToResturant = async () => {
		const mapLink = `https://www.google.com/maps/dir/${props?.lat ? props.lat : '38.892708'},${props?.lng ? props.lng : '-94.642674'}/@${data.lat},${data.lng}/${data.title}`
		window.open(mapLink)
	}

	const doInvited = async () => {
		await api
			.post(`/user/share_bozt`)
			.then((response) => { })
			.catch((error) => {
				console.log('Error: ', error.message)
			})
	}

	let categories = process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false' ? resturantData.restaurantDetails?.categories : resturantData.restaurantDetails?.types

	return (
		<>
			<Wrapper>
				<CustomRow isPopup={props.background}>
					<PageHeader title={'Restaurant Details'} background={props.background === true} restCBHandler={props.restCBHandler} setOpenRestDetails={props.setOpenRestDetails} setResturantData={setResturantData} isShare={props.isShare} />
					{loading ? (
						<SpinnerWrapper>
							<CustomRow>
								<SpinnerCol xs={12} sm={props.background ? 12 : 10} md={props.background ? 12 : 8} lg={props.background ? 12 : 5}>
									<Loader visible={loading} width={'auto'} />
								</SpinnerCol>
							</CustomRow>
						</SpinnerWrapper>
					) : resturantData.restaurantDetails ? (
						<ContentWrapper xs={props.background ? 12 : 12} sm={props.background ? 8 : 10} md={props.background ? 10 : 8} lg={props.background ? 10 : 6}>
							{resturantData.restaurantDetails?.photos?.length > 0 && (
								<Imagediv>
									{resturantData.restaurantDetails?.photos?.length > 1 ? (
										<MediaViewer boztMedia={resturantData.restaurantDetails?.photos} isResraurant={true} parent="restaurant" />
									) : (
										<Image2
											src={
												process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false'
													? resturantData.restaurantDetails?.photos[0]
													: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${resturantData.restaurantDetails?.photos[0]?.width}&photoreference=${resturantData.restaurantDetails?.photos[0]?.photo_reference}&key=${process.env.REACT_APP_MAP_API_KEY}`
											}
											trans={props.background}
										/>
									)}
								</Imagediv>
							)}
							<ContentDiv>
								<Header>
									<Title>{resturantData.restaurantDetails?.name}</Title>
									<IconsContainer>
										<Icon src={favorite ? starFilled : star} onClick={() => (favorite ? setRemoveFavoriteModel(true) : doFavoritesResturaunt())} />
										<Icon
											src={share}
											onClick={() => {
												setOpenShareModal(true)
											}}
										/>
									</IconsContainer>
								</Header>

								{/* <CatagoriesContainer margin={'0.2rem 0'}>
									{categories?.map((cat, index) => {
										return (
											<Cats weight={500} key={index.toString()}>
												{process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false' ? cat.title + ' ,' : cat + ' ,'}
											</Cats>
										)
									})}
								</CatagoriesContainer> */}
								<Location>
									{distance}
									{!isClosedNow ? 'Closed' : data.open}
									{' ' + data?.price ? data?.price : ''}
								</Location>
								{process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false' ? (
									<Location>{resturantData?.restaurantDetails?.location?.display_address?.length > 0 ? resturantData.restaurantDetails.location.display_address.toString() : ''}</Location>
								) : (
									<Location>{resturantData?.restaurantDetails?.formatted_address ? resturantData.restaurantDetails.formatted_address.toString() : ''}</Location>
								)}
								{favLoading ? (
									<LoadingSpinner />
								) : (
									<React.Fragment>
										<CatagoriesContainer margin={'0rem 0'}>
											{boztedUsers?.length > 0 && <BoztedBy weight={'bold'}>Bōzted by:</BoztedBy>}
											{boztedUsers?.length > 2
												? boztedUsers?.map((item, index) => {
													return boztedUsers?.length > 2 && index == 1 ? (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsRestPage(false)
																}}>
																{item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' + ',' : item.name + '(' + item.degree + ')'}
															</Bozters>
															<Bozters>
																{` and `}
																{boztedUsers?.length - 2} {boztedUsers?.length > 3 ? ' others' : ' other'}
															</Bozters>
														</>
													) : (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsRestPage(false)
																}}>
																{index == 0 ? (item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')') : ''}
															</Bozters>
															<Bozters margin={'0'}>{index < 2 ? ',' : ''}</Bozters>
														</>
													)
												})
												: boztedUsers?.map((item, index) => {
													return boztedUsers?.length - 1 == index ? (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsRestPage(false)
																}}>
																{boztedUsers?.length > 1 ? ' and ' : ''}
																{item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')'}
															</Bozters>
														</>
													) : (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsRestPage(false)
																}}>
																{index == 0 ? (item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')') : ''}
															</Bozters>
														</>
													)
												})}
										</CatagoriesContainer>

										<CatagoriesContainer margin={'0rem 0'}>
											{favoriteRestaurants?.length > 0 && <BoztedBy weight={'bold'}>Favorites by:</BoztedBy>}
											{favoriteRestaurants?.length > 2
												? favoriteRestaurants?.map((item, index) => {
													return favoriteRestaurants?.length > 2 && index == 1 ? (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsFavouritePage(true)
																}}>
																{item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' + ',' : item.name + '(' + item.degree + ')'}
															</Bozters>
															<Bozters>
																{` and `}
																{favoriteRestaurants.length - 2} {favoriteRestaurants?.length > 3 ? ' others' : ' other'}
															</Bozters>
														</>
													) : (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsFavouritePage(true)
																}}>
																{index == 0 ? (item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')') : ''}
															</Bozters>
															<Bozters margin={'0'}>{index < 2 ? ',' : ''}</Bozters>
														</>
													)
												})
												: favoriteRestaurants?.map((item, index) => {
													return favoriteRestaurants?.length - 1 == index ? (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsFavouritePage(true)
																}}>
																{favoriteRestaurants?.length > 1 ? ' and ' : ''}
																{item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')'}
															</Bozters>
														</>
													) : (
														<>
															<Bozters
																weight={'600'}
																key={index.toString()}
																onClick={() => {
																	props.setIsFavouritePage(true)
																}}>
																{index == 0 ? (item?.name === user?.first_name + ' ' + user?.last_name ? 'Me' : item.name + '(' + item.degree + ')') : ''}
															</Bozters>
														</>
													)
												})}
										</CatagoriesContainer>
									</React.Fragment>
								)}

								{resturantData?.restaurantDetails?.is_awarded === true && <Location style={{ color: color.Orange }}>{'Awarded restaurant'}</Location>}
							</ContentDiv>
							<BoxDiv>
								<Flex>
									<ActionButton onClick={async () => await addToWishlist()} margin={'1rem'}>
										<ListIcon />
										Want to go
									</ActionButton>
									<ActionButton
										onClick={async () => {
											await navigateToResturant()
										}}>
										<ArrowImage src={arrow} alt="arrow" />
										Directions
									</ActionButton>
								</Flex>
							</BoxDiv>
							{resturantData?.restaurantDetails?.website_url ||
								(resturantData?.restaurantDetails?.website && (
									<ParentDiv style={{ padding: '0 1.2rem' }}>
										<ActionButton width={'100%'}>
											<a style={{ color: 'black', textDecoration: 'none', display: 'flex', alignItems: 'center' }} target='_blank' href={resturantData?.restaurantDetails?.website_url ? resturantData.restaurantDetails.website_url : resturantData?.restaurantDetails?.website ? resturantData?.restaurantDetails?.website : ''} rel="noreferrer">
												<ArrowImage iconHeight={'1.5rem'} src={website} alt="arrow" />
												Website
											</a>
										</ActionButton>
									</ParentDiv>
								))}
						</ContentWrapper>
					) : (
						'Restaurant data  not found'
					)}
				</CustomRow>
			</Wrapper>

			{openShareModal && (
				<ShareModal openModal={setOpenShareModal} invitedCB={doInvited} id={props?.parent == 'discover' ? props?.r_id : props?.id ? props?.id : props?.r_id} isDiscover={props?.parent == 'discover' ? true : false} parent={'restaurant'} />
			)}

			{removeFavoriteModel && <ConfirmationModel body={'Are you sure you want to remove this favorite ?'} openModal={() => setRemoveFavoriteModel(false)} onClick={() => doDeleteFavoritesResturaunt()} />}
		</>
	)
}

const Wrapper = styled(Container)`
	padding: 0;
	margin: 0;
	width: ${({ trans }) => (trans === true ? '40rem' : `auto`)};
	::-webkit-scrollbar {
		display: none;
	}
`

const CustomRow = styled(Row)`
	justify-content: center;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	${media.xs`
		justify-content: ${({ isPopup }) => (isPopup === true ? 'start' : `center`)};
		flex-direction:column;
	`}
	${media.sm`
		flex-direction:column;
		justify-content: ${({ isPopup }) => (isPopup === true ? 'start' : `center`)};
	`}
	${media.md`
		justify-content:center;
		flex-direction:column;
		align-items: center;
	`}
	${media.lg`
		justify-content: center;
		flex-direction:column;
		align-items: center;
	`};
`

const ContentWrapper = styled(Col)`
	background-color: ${color.White};
	border-radius: 4px;
	min-width: 34rem;
	height: fit-content;
	padding: 1rem;
	${media.xs`
		min-width: 20rem;
		padding: 1rem;
	`}
	${media.sm`
		min-width: 24rem;
		padding: 1rem;
	`}
	${media.md`
		min-width: 34rem;
		padding: 1.5rem;
	`}
`

const Imagediv = styled.div`
	background-color: ${color.White};
	position: relative;
	padding: 0;
	${media.xs`
  border-radius: 0.313rem;
  `}
	${media.sm`
  border-radius: 0.313rem;
  `}
	${media.md`
  border-radius: 0.313rem;

  `}
	${media.lg`
  border-radius: 0rem;
  `}
`

const Image2 = styled.img`
	width: 100%;
	height: ${({ trans }) => (trans === true ? '20rem' : `auto`)};
	${media.xs`
	height: ${({ trans }) => (trans === true ? '20rem' : `auto`)};
     width: 100%;
    `}
	${media.sm`
  height: ${({ trans }) => (trans === true ? '20rem' : `auto`)};
  width: 100%;
  object-fit:contain;
    `}
	${media.md`
	height: ${({ trans }) => (trans === true ? '20rem' : `auto`)};
    width: 100%;
    object-fit:contain;

    `}
	${media.lg`
	height: ${({ trans }) => (trans === true ? '20rem' : `auto`)};
	width: 100%;
    justify-content: center;
    object-fit:contain;

    `}
`

const ContentDiv = styled(Imagediv)`
	justify-content: start;
	align-items: start;
	margin: 1rem 0;
	height: fit-content;
`

const Flex = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	/* gap: 1rem; */
`

const ParentDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
`

const ActionButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	background-color: ${color.White};
	box-shadow: 1px 0.8px 2px ${color.Grey};
	width: ${({ width }) => (width ? width : '42%')};
	margin-right: ${({ margin }) => (margin ? margin : '0')};
	border-radius: 0.4rem;
	margin-bottom: 0.5rem;
	cursor: pointer;
	font-size: 1rem;

	${media.xs`
	width: auto ;
	`}
	${media.sm`
	 width: auto ;
	`}
	${media.md`
	width: ${({ width }) => (width ? width : '42%')} ;
	`}
	${media.lg`
	 width: ${({ width }) => (width ? width : '42%')} ;
	`};
`

const BoxDiv = styled(ContentDiv)`
	margin-top: 2rem;
`

const CatagoriesContainer = styled.div`
	align-items: center;
	line-height: 1.5em;
	flex-wrap: wrap;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: ${({ margin }) => `${margin}`};
	${media.xs`
	display: flex;
	`}
	${media.sm`
	 display: flex;
	`}
	${media.md`
	display: flex;
	`}
	${media.lg`
	 display: flex;
	`};
`

const Cats = styled.p`
	font-weight: ${({ weight }) => `${weight}`};
	font-family: sans-serif;
	margin: 0;
	color: ${color.DarkPepper_60};
`

export const Title = styled.h1`
	margin-bottom: 0rem;
	font-weight: 500;
	color: ${color.Black};
	font-size: 1.3rem;
`
const Location = styled.p`
	line-height: 1.2rem;
	margin: 0;
	color: ${color.DarkPepper_40};
	margin-bottom: 0.5rem;
`
const BoztedBy = styled.span`
	font-weight: ${({ weight }) => `${weight}`};
	font-size: 0.9rem;
	font-weight: 500;
	color: ${color.DarkPepper_80};
`

const Bozters = styled.p`
	font-weight: ${({ weight }) => `${weight}`};
	margin: ${({ margin }) => (margin ? margin : '0rem 0rem 0rem 0.2rem')};
	font-size: 0.8rem;
	font-size: 0.9rem;
	font-weight: 500;
	color: ${color.Flambe};
	&:hover {
		color: ${color.Green} !important;
	}
	cursor: pointer;
`

const ArrowImage = styled.img`
	max-height: ${({ iconHeight }) => (iconHeight ? iconHeight : '1rem')};
	margin-right: 0.5rem;
`
const ListIcon = styled(AiOutlineUnorderedList)`
	max-height: 1rem;
	margin-right: 0.5rem;
	font-size: 1.3rem;
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`
export const IconsContainer = styled.div`
	display: flex;
	align-items: end;
	margin-bottom: 0.3rem;
`
export const Icon = styled.img`
	cursor: pointer;
	height: 1.875rem;
	width: 1.875rem;
	color: ${color.Black};
	margin: 0;
	padding: 0;
	margin-left: 0.5rem;
	${media.xs`
	height: 1.25rem;
	width: 1.25rem;
	`}
	${media.sm`
		height: 1.25rem;
		width: 1.25rem;
	`}
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
  `};
`
const SpinnerWrapper = styled(Container)`
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: transparent !important;
`

const SpinnerCol = styled(Col)`
	width: 28rem !important;
	min-height: 16rem !important;
	display: flex;
	justify-content: center;
`

const LoadingSpinner = styled.div`
	/* Add your styling for the loading spinner here */
	border: 2.5px solid rgba(0, 0, 0, 0.1);
	border-top: 2.5px solid ${color.DarkPepper_40};
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		};
		100% {
			transform: rotate(360deg);
		}
	}
`
export default ResturantDetails
