import React, { useState, useEffect, Suspense } from 'react'
import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'
import { HiBookmark } from 'react-icons/hi'
import { color } from '../assets/css/Color.js'
import chat from '../assets/images/chat.png'
import like from '../assets/images/like.png'
import likefill from '../assets/images/heartfilled.png'
import share from '../assets/images/share.png'
import bookmark from '../assets/images/favorite.png'
import ClipLoader from 'react-spinners/ClipLoader'
import CommentModal from './modal/CommentModal.js'
import ReportModal from './modal/ReportModal.js'
import RestaurantModal from './modal/RestaurantModal.js'
import WriteReport from './modal/WriteReport.js'
import ShareModal from './modal/ShareModal.js'
import ShieldImage from '../assets/images/shield.png'
import 'react-multi-carousel/lib/styles.css'
import { media } from 'styled-bootstrap-grid'
import { useNavigate, createSearchParams } from 'react-router-dom'
import moment from 'moment'
import Input from './common/Input.js'
import Button from './common/Button.js'
import { useDispatch, useSelector } from 'react-redux'
import { doAddBozt, doAddBoztComment, doBookmarkBozts, doFavoriteResturaunt, doFetchNewComments, doHandleFollowUser, doInvite, doRemoveBookmark, doRemoveFavoritesResturaunt, doRemoveLike } from './api/apis.js'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Header, IconsContainer } from './ResturantDetails.js'
import star from '../assets/images/star_empty.png'
import starFilled from '../assets/images/favorite_filled.png'
import ConfirmationModel from './modal/ConfirmationModel.js'
import { api } from './api/callAxios.js'

const MediaViewer = React.lazy(() => import('./common/MediaViewer.js'))

const FeedCard = ({ feedData, hide, setDoFilter, setFollowId, setCongrate, feedCircle, style }) => {
	const _navigate = useNavigate()
	const [isLiked, setisLiked] = useState(false)
	const [likescount, setLikesCount] = useState(0)
	const [commentscount, setCommentsCount] = useState()
	const [isSaved, setisSaved] = useState(feedData?.isSaved)
	const [loading, setLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [reportOpen, setReportOpen] = useState(false)
	const [writeReport, setWriteReport] = useState(false)
	const [feeddata, setfeeddata] = useState(false)
	const [comment, setComment] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [restModal, setRestModal] = useState(false)
	const [restID, setRestID] = useState('')
	const userLocation = useSelector((state) => state?.latLng)
	const [media, setMedia] = useState([])
	const [addComment, setAddComment] = useState(false)
	const [favorite, setFavorite] = useState(false)
	const [removeFavoriteModel, setRemoveFavoriteModel] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		if (feedData) {
			setisSaved(feedData?.isSaved)
			setisLiked(feedData?.isLiked)
			setLikesCount(feedData?.likes)
			setCommentsCount(feedData?.comments)
			setFavorite(feedData?.r_favorite)
			let _media = []
			if (feedData?.images?.length > 0) {
				_media = feedData?.images
			}
			if (feedData?.video != '') {
				if (_media != undefined) {
					_media.unshift(feedData?.video)
				} else {
					_media = [feedData?.video]
				}
			}
			let uniq = [...new Set(_media)]
			_media = uniq.map((i) => {
				return { media: i, loading: true }
			})
			setMedia(_media)
		}
	}, [feedData])

	//==================likes=====================//
	const doLikeBozt = async (id) => {
		await doAddBozt(id, setLoading, setisLiked, setLikesCount)
	}

	const doUnLikeBozt = async (id) => {
		await doRemoveLike(id, setLoading, setLikesCount, setisLiked)
		setLoading(false)
	}

	//=================saved================//
	const doSaveBozts = async (id) => {
		await doBookmarkBozts(id, setLoading, setisSaved)
	}

	const doUnSaveBozts = async (id) => {
		await doRemoveBookmark(id, setLoading, setisSaved)
	}

	const togglePopup = (data) => {
		setIsOpen(true)
		setfeeddata(data)
	}

	const handleCloseCommentModal = async (e) => {
		setIsOpen(false)
		await fetchNewComments()
	}

	const fetchNewComments = async (id) => {
		await doFetchNewComments(id, feeddata, setCommentsCount)
	}

	const Reportpopuptoggle = (data) => {
		setReportOpen(!reportOpen)
	}

	const doAddBoztComments = async (id) => {
		await doAddBoztComment(id, comment, setAddComment, setComment, setCommentsCount, commentscount)
	}

	const reportSheetHandler = async () => {
		setReportOpen(false)
		setWriteReport(true)
	}
	const likenavigationHandler = async () => {
		setReportOpen(false)
		_navigate({
			pathname: '/boztLikedUserList',
			search: createSearchParams({
				id: feedData?.id
			}).toString()
		})
	}
	const commentsHandler = () => {
		setReportOpen(false)
		togglePopup(feedData)
	}

	const doInvited = async () => {
		await doInvite(feedData)
	}

	const handleFollow = async () => {
		await doHandleFollowUser(setLoading, feedData, setCongrate, setDoFilter, setFollowId,)
		doLogin()
	}



	const doLogin = async () => {
		if (localStorage.getItem('token')) {
			await api
				.post(`/user/auto_login`, { fcm_token: '' })
				.then(async (response) => {
					dispatch({
						type: 'SET_USER',
						payload: response?.data.data.user
					})
					dispatch({
						type: 'DESIGNATION',
						payload: response?.data.data.user.designation
					})
					dispatch({
						type: 'CIRCLE_BOZT',
						payload: response?.data.data.my_cicle_bozts.bozts
					})
					dispatch({
						type: 'NOTIFICATIONS_COUNT',
						payload: response.data.data.my_cicle_bozts.notificationCount
					})
					localStorage.removeItem('currentTab')
					localStorage.setItem('token', response?.data.data.user.auth_token)
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}


	const doFavoritesResturaunt = async () => {
		await doFavoriteResturaunt(feedData, feedData, setFavorite, setLoading, true)


	}

	const doDeleteFavoritesResturaunt = async () => {
		let res = await doRemoveFavoritesResturaunt(feedData, setFavorite, setLoading)
		if (res) {
			setRemoveFavoriteModel(false)
		}

	}

	return (
		<Card style={style}>
			{loading && (
				<Spinner>
					<ClipLoader color="#000000" height={50} width={50} />
				</Spinner>
			)}

			{/* {loading == false ? ( */}
			<>
				<CardHeader>
					<ProfileWrapper>
						<Flex
							gap="0.5rem"
							hide={hide}
							onClick={() => {
								_navigate({
									pathname: '/profile',
									search: createSearchParams({
										id: feedData?.user.id
										// tab: feedCircle
									}).toString()
								})
							}}>
							<div>{hide === true ? '' : <Icon style={{ cursor: 'pointer' }} src={feedData?.user?.profile_image ? `${process.env.REACT_APP_IMAGE_URL + feedData?.user?.profile_image}` : null} />}</div>
							{hide === true ? (
								''
							) : (
								<FlexedCol>
									<AdditionalContent>
										<UserName weight="500"> {feedData?.user?.full_name} </UserName>
										{feedData?.user?.founder === true && <ShieldIcon src={ShieldImage} />}
										<Flex gap="0.3rem">
											{hide === true
												? ''
												: feedData?.user?.degree.length > 2 && (
													<>
														{' '}
														{feedData?.user?.founder === false && <Dot />}
														<DegreeText>{feedData?.user?.degree}</DegreeText>
													</>
												)}
										</Flex>
									</AdditionalContent>
									<UserDesignation> {feedData?.user?.designation}</UserDesignation>
								</FlexedCol>
							)}
						</Flex>
					</ProfileWrapper>

					<RightTopContent>
						<DotsIcon hide={feedCircle && feedCircle ? feedCircle : hide} onClick={Reportpopuptoggle} />
						{hide === true
							? ''
							: feedCircle === false && (
								<FollowText weight={'500'} check={feedData.is_favorite === false ? false : hide} onClick={handleFollow}>
									Follow
								</FollowText>
							)}
					</RightTopContent>
				</CardHeader>

				<Wrapper>
					{media !== undefined && (
						<>
							{media?.length > 0 && (
								<Suspense fallback={'Loading...'}>
									<MediaViewer boztMedia={media} />
								</Suspense>
							)}
						</>
					)}
				</Wrapper>
				<CardBody marginTop={feedData?.images?.length > 0 ? true : false}>
					<Title
						height={feedData?.r_title?.length > 40 ? true : false}
						onClick={() => {
							setRestModal(true)
							setRestID(feedData?.r_id)
						}}>
						{feedData?.r_title}
					</Title>
					<Address>{feedData?.state && feedData?.city && feedData?.city + ', ' + feedData?.state} </Address>
					<Discription> {feedData?.recommendation} </Discription>

					<Taste>
						{feedData?.tastes?.map((data) => {
							return (
								<Flex gap="0.3rem" key={Math.random()}>
									<FoodIcon src={data.icon} />
									<FoodName> {data.name} </FoodName>
								</Flex>
							)
						})}
					</Taste>
					<FeedBack margin="0.8rem 0rem">
						<Flex gap="1rem">
							<Flex gap="0.3rem">
								<HeartIcon
									src={isLiked === true ? likefill : like}
									isLike={isLiked}
									onClick={() => {
										isLiked === true ? doUnLikeBozt(feedData?.id) : doLikeBozt(feedData?.id)
									}}
								/>
								<LikesCount
									style={{ cursor: 'pointer' }}
									onClick={() => {
										_navigate({
											pathname: '/boztLikedUserList',
											search: createSearchParams({
												id: feedData?.id
											}).toString()
										})
									}}>
									{likescount}
								</LikesCount>
							</Flex>
							<Flex gap="0.3rem">
								<ChatIcon isGreen={commentscount > 0 ? true : false} src={chat} onClick={() => togglePopup(feedData)} /> <LikesCount>{commentscount}</LikesCount>
							</Flex>
						</Flex>
						<Flex gap="0.75rem">
							<Header>
								<IconsContainer>
									<StartIcon src={favorite ? starFilled : star} onClick={() => favorite ? setRemoveFavoriteModel(true) : doFavoritesResturaunt()} />

								</IconsContainer>
							</Header>
							<ShareIcon
								src={share}
								onClick={() => {
									setOpenModal(true)
								}}
							/>
							{isSaved ? (
								<BookmarkFill
									onClick={() => {
										isSaved === true ? doUnSaveBozts(feedData.id) : doSaveBozts(feedData.id)
									}}
								/>
							) : (
								<SavedIcon
									isSaved={isSaved === true ? true : false}
									src={bookmark}
									onClick={() => {
										isSaved === true ? doUnSaveBozts(feedData.id) : doSaveBozts(feedData.id)
									}}
								/>
							)}
						</Flex>
					</FeedBack>
					<YearsText>{moment(feedData?.createdAt).fromNow()}</YearsText>
					<Hrow />
					<FeedBack>
						<TextWrapper>
							<Input placeholder="Add a comment" onChange={(event) => setComment(event.target.value)} value={comment} height={'3rem'} border={'none'} padding={'0'} margin={'0'} fontWeight={500}></Input>
						</TextWrapper>
						{addComment && (
							<Spinner left={'-20rem'} position={'relative'}>
								<ClipLoader color="#000000" height={50} width={50} />
							</Spinner>
						)}
						<Flex>
							<Button
								disabled={comment?.trimStart()?.length === 0 || loading ? true : false}
								onClick={() => {
									doAddBoztComments(feedData?.id)
								}}
								label="Post"
								color={comment?.trimStart()?.length === 0 || loading ? color.Grey : color.Orange}
								fontSize={'100%'}
								font="inherit"></Button>
						</Flex>
					</FeedBack>
				</CardBody>
			</>
			{/* // ) : (
			// 	<Skeleton height={500} count={1} />
			// )} */}
			{isOpen && <CommentModal handleClose={(e) => handleCloseCommentModal(e)} data={feeddata} media={media} />}
			{reportOpen && <ReportModal data={feedData} reportOpenCB={reportSheetHandler} cancelCB={() => setReportOpen(false)} likenavigationHandlerCB={likenavigationHandler} commentsHandlerCB={commentsHandler} />}
			{writeReport && <WriteReport setWriteReport={setWriteReport} id={feedData?.id} />}
			{openModal && <ShareModal openModal={setOpenModal} invitedCB={doInvited} id={feedData?.id} />}
			{restModal && <RestaurantModal setIsOpen={setRestModal} r_id={restID} id={feedData?.id} lat={userLocation?.latitude} lng={userLocation?.longitude} />}
			{removeFavoriteModel && <ConfirmationModel body={'Are you sure you want to remove this favorite ?'} openModal={() => setRemoveFavoriteModel(false)} onClick={() => doDeleteFavoritesResturaunt()} />}
		</Card>
	)
}

const Card = styled.div`
	position: relative;
	border-radius: 0.625rem;
	margin: 0rem 0rem 0.563rem 0rem;
	background-color: ${color.White};
`

const Icon = styled.img`
	height: 3.125rem;
	width: 3.125rem;
	border-radius: 1.563rem;
	display: flex;
	flex-direction: column;
	justify-content: start;
	background-color: ${color.LightGrey};
	position: relative;
	border-radius: 1.563rem;
	object-fit: cover;
`

export const StartIcon = styled.img`
	cursor: pointer;
	height: 1.875rem;
	width: 1.875rem;
	color: ${color.Black};
	margin: 0;
	padding: 0;
	margin-left: 0.5rem;
	${media.xs`
	height: 1.25rem;
	width: 1.25rem;
	`}
	${media.sm`
		height: 1.25rem;
		width: 1.25rem;
	`}
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
  `};
`
const FoodIcon = styled.div`
	background: ${({ src }) => (src ? `url(${process.env.REACT_APP_IMAGE_URL + src}) no-repeat` : `none`)};
	background-size: cover;
	height: 1.875rem;
	background-position: center;
	width: 1.875rem;
	border-radius: 0.1875rem;
`
const Wrapper = styled.div`
	width: 100%;
`

const CardHeader = styled.div`
	gap: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.8rem 0.625rem;
`

const ProfileWrapper = styled.div`
	width: 14rem;
	${media.sm`
		width:18rem;
	`}
	${media.md`
		width:24rem;
	`}
`
const UserName = styled.p`
	font-weight: ${({ weight }) => weight};
	font-family: sans-serif;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
	font-size: 0.938rem;
	max-width: 28rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
	&:hover {
		color: ${color.Orange};
	}
	${media.md`
		font-size: 1.25rem;
	`}
`
const UserDesignation = styled.p`
	font-size: 0.8rem;
	margin: 0;
	color: ${color.DarkPepper_60};
	${media.md`
		font-size: 0.875rem;
	`}
`

const FlexedCol = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
`

const AdditionalContent = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	align-items: center;
`

const DegreeText = styled.p`
	margin: 0;
	font-size: 0.7rem;
	color: ${color.DarkPepper_60};
	${media.md`
		font-size: 0.8rem;
	`}
`

const Dot = styled.div`
	width: 0.25rem;
	height: 0.25rem;
	border-radius: 1rem;
	background: ${color.DarkPepper_60};
`

const ShieldIcon = styled.img`
	width: 1.3rem;
	height: 1.3rem;
`

const RightTopContent = styled.div`
	padding-right: 0.3rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.65rem;
	gap: 0.5rem;
	${media.xs`
		gap:0rem;
	`}
	${media.sm`
		gap:0rem;
	`}
	${media.md`
		gap:0.5rem;
	`}
`

const DotsIcon = styled(BsThreeDots)`
	cursor: pointer;
	color: ${({ check }) => (check === true ? `${color.White}` : `${color.Green}`)};
`

const FollowText = styled.p`
	font-size: 0.8rem;
	font-weight: ${({ weight }) => weight};
	font-family: sans-serif;
	cursor: pointer;
	position: ${({ check }) => (check === true ? 'absolute' : `relative`)};
	top: ${({ check }) => (check === true ? '-1rem' : `0rem`)};
	margin: 0;
	color: ${color.Orange};
`

const Title = styled.p`
	font-family: sans-serif;
	cursor: pointer;
	color: ${color.Orange};
	font-size: 1.25rem;
	margin: 0.3rem 0rem;
`
const Address = styled.p`
	color: ${color.DarkPepper_60};
	font-size: 0.938rem;
	margin: 0.3rem 0rem;
`
const Discription = styled.p`
	color: ${color.DarkPepper_60};
	margin: 0;
	font-size: 0.938rem;
	max-width: 100%;
    word-wrap: break-word;
`

const Taste = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	margin: 0.3rem 0rem;
	gap: 0.8rem;
`

const FoodName = styled.p`
	font-size: 0.875rem;
	margin: 0;
`

const HeartIcon = styled.img`
	height: 1.875rem;
	width: 1.875rem;
	type: button;
	cursor: pointer;
	filter: ${({ isLike }) => (isLike ? `invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%)` : `brightness(50%)`)};
	height: 1.25rem;
	width: 1.25rem;
	outline: none !important;
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
	`}
`
const LikesCount = styled.p`
	margin: 0;
`

const ChatIcon = styled.img`
	filter: ${({ isGreen }) => isGreen === true && 'invert(55%) sepia(8%) saturate(2242%) hue-rotate(138deg) brightness(88%) contrast(89%)'};
	font-weight: bold;
	height: 1.875rem;
	width: 1.875rem;
	color: ${color.Black};
	cursor: pointer;
	height: 1.25rem;
	width: 1.25rem;
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
	`}
`

const ShareIcon = styled.img`
	color: ${color.Black};
	height: 1.25rem;
	width: 1.25rem;
	cursor: pointer;
	:focus-visible {
		outline: none !important;
	}
	:focus {
		outline: none !important;
	}
	outline: none !important;
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
	`}
`

const BookmarkFill = styled(HiBookmark)`
	color: ${color.Black};
	height: 1.25rem;
	width: 1.25rem;
	:focus-visible {
		outline: none !important;
	}
	:focus {
		outline: none !important;
	}
	cursor: pointer;
	outline: none !important;
	${media.md`
		height: 1.563rem;
		width: 1.563rem;
	`}
`

const SavedIcon = styled.img`
	filter: ${(isSaved) => (isSaved ? 'invert(0%) sepia(100%) saturate(0%) hue-rotate(147deg) brightness(97%) contrast(106%)' : '')};
	color: ${color.Black};
	cursor: pointer;
	height: 1.25rem;
	width: 1.25rem;
	:focus-visible {
		outline: none !important;
	}
	:focus {
		outline: none !important;
	}
	outline: none !important;
	${media.md`
  		height: 1.563rem;
  		width: 1.563rem;
  	`}
`
const Flex = styled.div`
	display: flex;
	align-items: center;
	visibility: ${({ hide }) => (hide === true ? 'hidden' : `visible`)};
	/* cursor: pointer; */
	:focus-visible {
		outline: none !important;
	}
	:focus {
		outline: none !important;
	}
	background-color: white;
	outline: none !important;
	gap: ${({ gap }) => (gap ? gap : '0rem')};
`
const TextWrapper = styled(Flex)`
	width: 90%;
`

const YearsText = styled.p`
	margin: 0;
	padding-bottom: 0.5rem;
	font-size: 0.8rem;
	color: ${color.DarkPepper_80};
`

const FeedBack = styled.div`
	width: 100%;
	margin: ${({ margin }) => margin};
	display: flex;
	align-items: center;
	justify-content: space-between;
`
export const Spinner = styled.div`
	position: ${({ position }) => (position ? position : 'absolute')};
	z-index: 99;
	right: 0;
	left: ${({ left }) => (left ? left : 0)};
	bottom: 0;
	top: ${({ top }) => (top ? top : 0)};
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Hrow = styled.div`
	border-top: 0.063rem solid ${color.LightGrey};
	width: 100%;
	margin-top: 0.3rem;
`

const CardBody = styled.div`
	width: 100%;
	padding: 0rem 0.625rem;
	margin-top: ${({ marginTop }) => (marginTop === true ? '0.5rem' : `0`)};
`

export default FeedCard
