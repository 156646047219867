/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import {BsSearch} from 'react-icons/bs'
import 'react-toastify/dist/ReactToastify.min.css'
import CatagoryCard from './card/CatagoryCard.js'
import {useSearchParams} from 'react-router-dom'
import Input from './common/Input.js'
import PageHeader from './common/PageHeader.js'
import {doFetchTastes, doFetchUserTastes} from './api/apis.js'

const Tastes = () => {
	const [searchParams] = useSearchParams()
	const [tasteList, setTasteList] = useState([])
	const [tasteListCopy, setTasteListCopy] = useState([])
	const [validRequest, setvalidRequest] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [_selectedTastesIds] = useState([])
	const [selectedTastesIds] = useState([])
	let u_id = searchParams.get('id')

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const fetchData = async () => {
		await doFetchUserTaste()
		await doFetchTaste()
	}

	const doFetchUserTaste = async () => {
		await doFetchUserTastes(setLoading, u_id, _selectedTastesIds)
	}

	const doFetchTaste = async () => {
		await doFetchTastes(setLoading, _selectedTastesIds, selectedTastesIds, setTasteList, setTasteListCopy)
	}

	const setTasteData = async (item, selection) => {
		if (selection === true) {
			selectedTastesIds.push(item.id)
		} else {
			if (selectedTastesIds.length > 0) {
				selectedTastesIds.splice(selectedTastesIds.indexOf(item.id), 1)
				return true
			}
		}
	}

	const onSelection = async (item, selection) => {
		await setTasteData(item, selection)
		let allTastes = tasteList
		for (let i = 0; i < allTastes.length; i++) {
			if (allTastes[i].id === item.id) {
				allTastes[i].isSelected = !allTastes[i].isSelected
			}
		}
		if (selectedTastesIds.length > 0) {
			if (selectedTastesIds.sort().join(',') === _selectedTastesIds.sort().join(',')) {
				setvalidRequest(false)
			} else {
				setvalidRequest(true)
			}
		}
	}

	const doSearch = async (text) => {
		if (text.length >= 2) {
			for (let index = 0; index < tasteListCopy.length; index++) {
				if (selectedTastesIds.length > 0) {
					for (let index1 = 0; index1 < selectedTastesIds.length; index1++) {
						if (tasteListCopy[index].id === selectedTastesIds[index1]) {
							tasteListCopy[index].isSelected = true
							break
						} else {
							tasteListCopy[index].isSelected = false
						}
					}
				} else {
					tasteListCopy[index].isSelected = false
				}
			}
			const _tasteList = tasteListCopy.filter((taste) => taste.name.toLowerCase().includes(text.toLowerCase()))
			setTasteList(_tasteList)
		} else {
			setTasteList(tasteListCopy)
		}
	}

	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader title={'Tastes'} padding={'0'} />
						<Flexed>
							<SearchIcon />
							<Input
								width={'100%'}
								placeholder="Search"
								borderRadius={'0.2rem'}
								margin={'1rem 0rem 1.5rem 0rem'}
								padding={'0.8rem 2rem'}
								onChange={async (event) => {
									if (event.target.value.length >= 2) {
										setError('')
										await doSearch(event.target.value)
									} else if (event.target.value.length === 0) {
										await doSearch(event.target.value)
										setError('')
									} else {
										setError('Text must be of length 2 or  greater...')
									}
								}}
							/>
							<Error>{error} </Error>
						</Flexed>
					</Col>
				</Row>
				<Row justifyContent="center">
					<ContentCol xs={11} sm={10} md={8} lg={5}>
						<Flex>
							{tasteList.map((item) => {
								return <CatagoryCard cardData={item} selectionCB={() => ''} key={Math.random()} />
							})}
						</Flex>
					</ContentCol>
				</Row>
				{loading && <Loader visible={loading} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`

const ContentCol = styled(Col)`
	min-height: 25rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${media.xs`
	min-height: 36rem;
	`}
	${media.sm`
		`}
	${media.md`
	min-height: 25rem;
	`}
	${media.lg`
	min-height: 25rem;
	`}
`

const Flexed = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`

const Error = styled.span`
	color: ${color.Red};
	position: absolute;
	top: 3.9rem;
	left: 1.2rem;
`
const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 1.9rem;
	z-index: 1;
	left: 0.5rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`
export const Flex = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 0.5rem;
	max-height: 22rem;
	${media.xs`
	grid-template-columns: 1fr 1fr;
	`}
	${media.sm`
	grid-template-columns: 1fr 1fr 1fr;
		`}
	${media.md`
	grid-template-columns: 1fr 1fr 1fr;
	`}
	${media.lg`
	grid-template-columns: 1fr 1fr 1fr ;
	`}
`

export default Tastes
