import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import FollowersList from '../components/FollowersList'
import {useNavigate, createSearchParams, useSearchParams} from 'react-router-dom'
import PageHeader from '../components/common/PageHeader.js'
import EmptyMessage from '../components/common/EmptyMessage.js'
import {doFetchUserLikesList, doFollowUnfollowUser} from '../components/api/apis.js'

const BoztLikedUserList = (props) => {
	const _navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [likedUsersList, setLikedUsersList] = useState([])
	const [loading, setLoading] = useState(false)
	const [nextPageToken, setNextPageToken] = useState(1)
	const [showLoadMore, setShowLoadMore] = useState(false)
	let _id = searchParams.get('id')

	useEffect(() => {
		async function fetchData() {
			await doFetchUsersList()
		}
		fetchData()
	}, [])

	const doFetchUsersList = async (nextPage = 1) => {
		await doFetchUserLikesList(nextPage, setLoading, _id, setShowLoadMore, likedUsersList, setLikedUsersList)
	}

	const doFollowUnfollow = async (f_id, follower_status) => {
		await doFollowUnfollowUser(f_id, follower_status, setLoading, likedUsersList, setLikedUsersList)
	}

	const navigationHandler = (id) => {
		_navigate({
			pathname: '/profile',
			search: createSearchParams({
				id: id
			}).toString()
		})
	}

	const doFetchMoreUsersList = async () => {
		setNextPageToken(nextPageToken + 1)
		await doFetchUsersList(nextPageToken + 1)
	}

	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<Col xs={11} sm={10} md={8} lg={6}>
						<PageHeader padding={'0'} title={'Bōzt Likes'} />
						{likedUsersList?.length > 0
							? likedUsersList?.map((item, index) => {
									return (
										<>
											<FollowersList item={item} index={index} navigationHandlerCB={navigationHandler} doFollowUnfollowCB={doFollowUnfollow} />
										</>
									)
							  })
							: !loading && <EmptyMessage message="No bōzts found"></EmptyMessage>}
						{showLoadMore && (
							<ShowMore
								onClick={() => {
									doFetchMoreUsersList()
								}}>
								Load More
							</ShowMore>
						)}
					</Col>
				</Row>
				{loading && <Loader visible={loading} text={'Loading...'} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.5rem;
`

const ShowMore = styled.button`
	font-size: 1rem;
	color: ${color.White};
	width: 100%;
	display: flex;
	background-color: ${color.Orange};
	justify-content: center;
	height: 3.125rem;
	align-items: center;
	cursor: pointer;
	border: none;
	outline: none;
	align-self: center;
`

export default BoztLikedUserList
