import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import UserCard from './common/UserCard.js'
import Restaurantmodal from './modal/RestaurantModal.js'
import {doGetFavouriteBozt} from './api/apis.js'

const UserFavourite = (props) => {
	const _latLng = useSelector((state) => state?.latLng)
	const [searchParams] = useSearchParams()
	const [loading, setLoading] = useState(false)
	const [favRest, setFavRest] = useState([])
	const [showMore, setShowMore] = useState(false)
	const [nextPage, setNextPage] = useState(1)

	const [restID, setRestID] = useState()
	const [boztId, setBoztId] = useState('')

	const [openRestDet, setOpenRestDet] = useState(false)

	let _u_id = searchParams.get('id')

	useEffect(() => {
		getFavouriteBozt()
	}, [_latLng])

	const getFavouriteBozt = async (nPage = 1) => {
		await doGetFavouriteBozt(nPage, setLoading, _latLng, setShowMore, favRest, setFavRest, _u_id)
	}

	const getNextPage = () => {
		setNextPage(nextPage + 1)
		getFavouriteBozt(nextPage + 1)
	}

	return (
		<div style={{top: '0.5rem', position: 'relative'}}>
			<UserCard
				userData={favRest}
				title="Favorites"
				emptyMessage={'No favorites found'}
				loading={loading}
				showMore={showMore}
				fetchMore={() => getNextPage()}
				path={'/rest'}
				setOpenRestDet={(e) => setOpenRestDet(e)}
				setRestID={(e) => setRestID(e)}
				setBoztID={(e) => setBoztId(e)}
			/>
			{openRestDet && <Restaurantmodal setIsOpen={setOpenRestDet} r_id={restID} id={boztId} lat={_latLng.lat} lng={_latLng.lng} />}
		</div>
	)
}

export default UserFavourite
