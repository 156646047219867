import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import Loader from './common/Spinner.js'

import EmptyMessage from './common/EmptyMessage.js'
import Button from './common/Button.js'
import {useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import PageHeader from './common/PageHeader.js'
import RestaurantsList from './common/RestaurantsList.js'
import RestaurantModal from './modal/RestaurantModal.js'
import {doGetWishlist, doRemoveFromWishlist} from './api/apis.js'

const WishList = () => {
	const [wishlists, setWishlists] = useState([])
	const [loading, setLoading] = useState(false)
	const _latLng = useSelector((state) => state?.latLng)
	const [showMore, setShowMore] = useState(false)
	const [nextPage, setNextPage] = useState(1)
	const [selectedRestaurant, setSelectedRestaurant] = useState()
	const [openRestDet, setOpenRestDet] = useState(false)

	useEffect(() => {
		getWishlist()
	}, [_latLng])

	const getWishlist = async (nextPage = 1) => {
		await doGetWishlist(nextPage, setLoading, _latLng, setShowMore, wishlists, setWishlists)
	}

	const removeFromWishlist = async (id) => {
		setNextPage(1)
		await doRemoveFromWishlist(id, setLoading, getWishlist)
	}

	const getNextPage = () => {
		setNextPage(nextPage + 1)
		getWishlist(nextPage + 1)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<PageHeader title="Want to go" padding="0" />
				</CustomRow>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						{wishlists.length
							? wishlists?.map((index) => {
									return <RestaurantsList parent={'wishlist'} key={Math.random()} restaurantsList={index} addFavourite={(e) => removeFromWishlist(e?.r_id)} setSelectedRestaurant={(e) => setSelectedRestaurant(e)} setOpenRestDet={(e) => setOpenRestDet(e)} />
							  })
							: loading === false && <EmptyMessage message="Wish list is empty"></EmptyMessage>}
						{showMore && <Button margin={'0.8rem 0rem'} color={color.White} bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} width={'100%'} label={'Load More'} onClick={() => getNextPage()} />}
					</Col>
				</CustomRow>
				{openRestDet && <RestaurantModal setIsOpen={setOpenRestDet} r_id={selectedRestaurant?.r_id} id={selectedRestaurant?.id} lat={_latLng?.latitude} lng={_latLng?.longitude} />}
				{loading && <Loader visible={loading} text={'Loading...'} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

export default WishList
