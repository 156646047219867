import React, {useState} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import 'react-multi-carousel/lib/styles.css'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {BsChevronRight} from 'react-icons/bs'
import Logout from '../assets/images/logout.png'
import {useNavigate, createSearchParams} from 'react-router-dom'
import {useSelector} from 'react-redux'

const Menu = () => {
	const [wishlist, setWishList] = useState(false)
	const [fav, setFav] = useState(false)
	const [badges, setBadges] = useState(false)
	const [designation, setDesignation] = useState(false)
	const [taste, setTaste] = useState(false)
	const [bookmarks, setBookmarks] = useState(false)
	const _userData = useSelector((state) => state?.userData)
	const _navigate = useNavigate()
	let _id = _userData?.id

	const askForSuppport = async () => {
		window.open(`mailto:support@bozt.app?subject=Support from Bōzt`)
	}
	let menuItems = [
		{path: '/profile', name: 'Profile'},
		{path: '/designations', name: 'Designations'},
		{path: '/myTaste', name: 'Tastes'},
		{path: '/myFav', name: 'Favorites'},
		{path: '/myBookmarks', name: 'Bookmarks'},
		{path: '/wishList', name: 'Want to go'},
		{path: '/myBadges', name: 'Badges'},
		{path: '/notificationSettings', name: 'Notifications'}
	]
	return (
		<>
			{badges === false && bookmarks === false && fav === false && taste === false && wishlist === false && designation === false && (
				<Wrapper>
					<Row justifyContent="center">
						<Col xs={11} sm={10} md={8} lg={5}>
							<Text>Menu</Text>
							{menuItems.map((item, index) => {
								return (
									<Content
										key={index}
										onClick={() => {
											_navigate({
												pathname: item.path,
												search: createSearchParams({
													id: _id
												}).toString()
											})
										}}>
										<Option>{item.name}</Option>
										<BsChevronRight size={'1rem'} />
									</Content>
								)
							})}
							<Content
								onClick={async () => {
									await askForSuppport()
								}}>
								<Option>Support</Option>
								<BsChevronRight size={'1rem'} />
							</Content>

							<Content
								onClick={() => {
									window.localStorage.clear()
									_navigate('/')
									window.location.reload()
								}}>
								<Option>Sign Out</Option>
								<LogoutIcon src={Logout} alt="logout" />
							</Content>
						</Col>
					</Row>
				</Wrapper>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.5rem;
`

const Text = styled.h1`
	padding: 0.5rem;
	margin: 0;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 500;
	text-align: center;
	width: 100%;
`
const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${color.White};
	cursor: pointer;
	padding: 0.8rem;
	&:not(:last-child) {
		border-bottom: 0.063rem solid #dedbd4;
	}
`
const Option = styled.p`
	margin: 0;
	font-weight: 500;
	color: ${color.DarkPepper_60};
	&:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
`
const LogoutIcon = styled.img`
	height: 1rem;
	filter: brightness(0) saturate(100%);
`

export default Menu
