import React, {useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import UserCard from './common/UserCard.js'
import RestaurantModal from './modal/RestaurantModal.js'
import {doGetFavorites, doRemoveFromFavourite} from './api/apis.js'
import ConfirmationModel from './modal/ConfirmationModel.js'

const Favorite = () => {
	const [searchParams] = useSearchParams()
	let _id = searchParams.get('id')

	const [favorite, setFavorites] = useState([])
	const [loading, setLoading] = useState(false)
	const _userData = useSelector((state) => state?.userData)
	const _latLng = useSelector((state) => state?.latLng)

	const [nextPage, setNextPage] = useState(1)
	const [showMore, setShowMore] = useState(false)
	const [openRestDet, setOpenRestDet] = useState(false)
	const [restID, setRestID] = useState('')
	const [boztID, setBoztID] = useState('')
	const [removeFavoriteModel, setRemoveFavoriteModel] = useState(false)
	const [id, setId] = useState('')

	useEffect(() => {
		getFavorites()
	}, [_userData, _latLng])

	const getFavorites = async (nPage = 1) => {
		await doGetFavorites(nPage, setLoading, _latLng, _id, setShowMore, favorite, setFavorites)
	}

	const removeFromFavourite = async (id) => {
		await doRemoveFromFavourite(id, setLoading, favorite, setFavorites)
		setRemoveFavoriteModel(false)
	}

	const getNextPage = () => {
		setNextPage(nextPage + 1)
		getFavorites(nextPage + 1)
	}

	return (
		<>
			<UserCard
				userData={favorite}
				title="Favorites"
				emptyMessage={'No favorites found'}
				loaderText={'Loading...'}
				loading={loading}
				userFav={true}
				showMore={showMore}
				fetchMore={() => getNextPage()}
				setOpenRestDet={(e) => setOpenRestDet(e)}
				setRestID={(e) => setRestID(e)}
				setBoztID={(e) => setBoztID(e)}
				removeRestaurant={async (id) => {
					if (id) {
						setId(id)
						setRemoveFavoriteModel(true)
					}
				}}
			/>
			{openRestDet && <RestaurantModal setIsOpen={setOpenRestDet} r_id={restID} id={boztID} lat={_latLng?.latitude} lng={_latLng?.longitude} />}
			{removeFavoriteModel && <ConfirmationModel body={'Are you sure you want to remove this favorite ?'} openModal={() => setRemoveFavoriteModel(false)} onClick={async () => await removeFromFavourite(id)} />}
		</>
	)
}

export default Favorite
