import React from 'react'
import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {color} from '../../assets/css/Color'

const Button = ({onClick, label, type, bgtype, disabled, height, fontSize, color, borderRadius, width, shadow, margin, maxHeight, minWidth, fontWeight, label2, display, flexDirection, border, padding, font, ref}) => {
	return (
		<CommonButton
			onClick={onClick}
			height={height}
			radius={borderRadius}
			fontSize={fontSize}
			textColor={color}
			type={type}
			bgtype={bgtype}
			disabled={disabled}
			width={width}
			shadow={shadow}
			margin={margin}
			maxHeight={maxHeight}
			minWidth={minWidth}
			fontWeight={fontWeight}
			display={display}
			flexDirection={flexDirection}
			border={border}
			padding={padding}
			ref={ref}
			font={font}>
			{' '}
			{label}
			{label2 && <span>{label2}</span>}
		</CommonButton>
	)
}
const CommonButton = styled.button`
	font-size: ${({fontSize}) => (fontSize ? fontSize : '0.8rem')};
	color: ${({textColor}) => (textColor ? textColor : color.Grey)};
	width: ${({width}) => (width ? width : '')};
	display: ${({display}) => (display ? display : 'block')};
	background-color: ${({bgtype}) => (bgtype ? bgtype : 'transparent')};
	justify-content: center;
	height: ${({height}) => (height ? height : '')};
	border-radius: ${({radius}) => (radius ? radius : '0rem')};
	align-items: center;
	border: ${({border}) => (border ? border : 'none')};
	box-shadow: ${({shadow}) => (shadow ? shadow : 'none')};
	cursor: ${({disabled}) => (disabled === true ? 'no-drop' : 'pointer')};
	margin: ${({margin}) => (margin ? margin : '0')};
	max-height: ${({maxHeight}) => (maxHeight ? maxHeight : '')};
	min-width: ${({minWidth}) => (minWidth ? minWidth : '')};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '')};
	flex-direction: ${({flexDirection}) => flexDirection};
	padding: ${({padding}) => padding};
	font: ${({font}) => font};
	order: 3;
	${media.xs`
		order:2;
	`}
	${media.sm`
		order:2;
	`}
	${media.md`
		order:3;
	`}
  	${media.lg`
	  	order:3;
	`}
`
export default Button
