import React from 'react'
import styled from 'styled-components'
import { color } from '../../assets/css/Color'
import { RightWrapper } from '../modal/FilterModal'
import { media } from 'styled-bootstrap-grid'
import Button from './Button'

const RestaurantsList = ({ restaurantsList, key, addFavourite, setOpenRestDet, setSelectedRestaurant, discover, parent }) => {
	return (
		<InnerContentWrapper discover={discover} key={key}>
			<MilesContent>
				<Content fontWeight={'500'} color={restaurantsList?.billboard ? color.Orange : restaurantsList?.is_awarded ? color.Green : color.DarkPepper_60}>
					{restaurantsList.distance?.toFixed(2)}
				</Content>
				<Content fontWeight={'400'} fontSize="0.875rem" color={restaurantsList?.billboard ? color.Orange : restaurantsList?.is_awarded ? color.Green : color.DarkPepper_40}>
					miles
				</Content>
			</MilesContent>
			<VerticleRow />
			<RightWrapper>
				<Details
					discover={discover}
					onClick={() => {
						if (parent === 'map') {
						} else if (parent === 'search') {
							setSelectedRestaurant(restaurantsList)
							setOpenRestDet(true)
						} else {
							setSelectedRestaurant(restaurantsList)
							if (discover) {
							} else {
								setOpenRestDet(true)
							}
						}
					}}>
					<Title style={{ color: restaurantsList?.billboard ? color.Orange : restaurantsList?.is_awarded ? color.Green : '' }}> {parent === 'wishlist' ? restaurantsList.title : restaurantsList.r_title}</Title>
					<Content fontWeight={'400'} fontSize="0.875rem" color={restaurantsList?.billboard ? color.Orange : restaurantsList?.is_awarded ? color.Green : color.DarkPepper_40}>
						{restaurantsList.address}
					</Content>
				</Details>
				{discover ? (
					''
				) : (
					<Button
						maxHeight={'3rem'}
						minWidth={window.innerWidth <= 450 ? '5rem' : '7rem'}
						fontSize={'1rem'}
						bgtype={color.White}
						borderRadius={'2rem'}
						color={color.SteelBlue}
						shadow={'0rem 0rem 1.25rem rgba(0, 0, 0, 0.1)'}
						padding={'0.8rem'}
						fontWeight={'bold'}
						label={parent === 'wishlist' ? 'Remove' : 'Select'}
						onClick={() => addFavourite(restaurantsList)}></Button>
				)}
			</RightWrapper>
		</InnerContentWrapper>
	)
}

const InnerContentWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: ${({ discover }) => (discover ? 'flex-start' : 'space-between')};
	background-color: ${color.White};
	cursor: pointer;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem;
	&:not(:last-child) {
		border-bottom: 0.063rem solid ${color.LightGrey};
	}
`
export const ResText = styled.p`
	margin: 0rem;
`
export const Title = styled(ResText)`
	font-weight: 500;
	width: 100%;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
`
const Content = styled.p`
	margin: 0rem;
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
	color: ${({ color }) => color};
	width: 100%;
	// text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
	overflow: hidden;
	line-height: 1.2rem;
	${media.xs`
    -webkit-line-clamp: 3;
	font-size: 0.7rem;
    `};
	${media.sm`
    -webkit-line-clamp: 2;
	font-size: 0.7rem;
    `};
	${media.md`
    font-size: 1rem;
    `};
	${media.lg`
    font-size: 1rem;
    `};
`

const MilesContent = styled.div`
margin-right: 0.2rem;
${media.xs`
    font-size: 0.7rem;
    `};
	${media.sm`
    font-size: 0.7rem;
    `};

`

const Details = styled.div`
    margin-left: 0.5rem;
	width: ${({ discover }) => (discover ? 'calc(100% - 80px)' : 'calc(100% - 200px)')};
	${media.xs`
	width: ${({ discover }) => (discover ? 'calc(100% - 80px)' : 'calc(129% - 202px)')};
	font-size: 0.8rem;
    `};
	${media.sm`
	width: ${({ discover }) => (discover ? 'calc(100% - 80px)' : 'calc(129% - 202px)')};
	font-size: 0.8rem;
    `};
	${media.md`
	width: ${({ discover }) => (discover ? 'calc(100% - 80px)' : 'calc(100% - 200px)')};
	font-size: 1rem;
    `};
	${media.lg`
   width: ${({ discover }) => (discover ? 'calc(100% - 80px)' : 'calc(100% - 200px)')};
   font-size: 1rem;
    `};
`

const VerticleRow = styled.div`
	border-left: 0.063rem solid ${color.LightGrey};
	height: 3rem;
`

export default RestaurantsList
