/* eslint-disable eqeqeq */
import React, { useState, useEffect, Suspense, useRef } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { color } from '../assets/css/Color.js'
import styled from 'styled-components'
import { BsSearch } from 'react-icons/bs'
import Loader from '../components/common/Spinner.js'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ShieldImage from '../assets/images/shield.png'
import { Flexed, Spacer } from '../assets/shared.js'
import Button from './common/Button.js'
import Input from './common/Input.js'
import PageHeader from './common/PageHeader.js'
import EmptyMessage from './common/EmptyMessage.js'
import { doFollowBozter, doGetUserBozt, doSearchBozt, doUnfollowBozter } from './api/apis.js'
import { debounce } from 'lodash'
import TextWithSeeMore from './common/SeeMore.js'
const FeedCard = React.lazy(() => import('./FeedCard.js'))

const Profile = (props) => {
	const [searchParams] = useSearchParams()
	const [userBozt, setUserBozt] = useState([])
	const [userData, setuserData] = useState({})
	const [copyData, setCopyData] = useState([])
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const [following, setFollowing] = useState(false)
	const [showMore, setShowMore] = useState(false)
	const [nextPage, setNextPage] = useState(1)
	const [searchBozts, setSearchBozts] = useState()
	const [followers, setFollowers] = useState(0)
	const [text, setText] = useState('')
	const _own_id = useSelector((state) => state?.userData?.id)
	let _u_id = searchParams.get('id')
	const _navigate = useNavigate()
	const loaderRef = useRef(null)

	useEffect(() => {
		getUserBozt(1, true)
	}, [])

	const getUserBozt = async (nextPage = 1, loading = false) => {
		await doGetUserBozt(nextPage, loading, setLoading, props, _u_id, userBozt, setuserData, setFollowing, setShowMore, setUserBozt, setFollowers, setCopyData)
	}

	const searchBozt = async (value) => {
		setUserBozt([])
		setLoading(true)
		await doSearchBozt(value, setUserBozt, _u_id)
		setLoading(false)
	}

	const unfollowBozter = async () => {
		await doUnfollowBozter(_u_id, setFollowers, setFollowing, setLoading, followers)
	}

	const followBozter = async () => {
		await doFollowBozter(_u_id, setFollowers, setFollowing, setLoading, followers)
	}

	const fetchMoreBoztData = () => {
		setNextPage(nextPage + 1)
		getUserBozt(nextPage + 1, true)
	}

	useEffect(() => {
		const delayedAPICall = debounce((text) => {
			searchBozt(text)
		}, 500)

		if (text !== '') {
			delayedAPICall(text)
		}

		return () => {
			delayedAPICall.cancel()
		}
	}, [text])

	const handleSearchTextChange = (event) => {
		setText(event)
	}

	return (
		<>
			<Container>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader padding={'0'} />{' '}
					</Col>
					<CustomCol xs={11} sm={10} md={8} lg={5}>
						<Spacer height={1} />
						<Flexed direction="row" align="center" justify="center">
							<Text margin="0rem 0.5rem 0rem 0rem">{userData?.user?.full_name}</Text>
							{userData?.user?.degree?.length > 2 && (
								<>
									<span style={{ paddingBottom: '0.5rem' }}>.</span>&nbsp;
									<Text margin="0rem 0.5rem 0rem 0rem" size="0.8rem">
										{userData?.user?.degree}
									</Text>
								</>
							)}
							{userData.user?.founder && _own_id != _u_id && <ShieldIcon src={ShieldImage} />}
						</Flexed>
						<Text size="0.9rem" margin="0.5rem">
							{userData.user?.designation}
						</Text>
						<ImageContainer>
							<Image src={userData?.user?.profile_image} />
						</ImageContainer>
						<Text size="0.9rem" >
							<UserBio> <TextWithSeeMore text={userData.user?.bio} maxLength={150} /></UserBio>
						</Text>
						{_own_id != _u_id && (
							<ButtonWrapper>
								<Button
									bgtype={color.White}
									color={'black'}
									fontWeight={'500'}
									width={'4.5rem'}
									border={`0.0625rem solid ${color.Grey}`}
									onClick={() => {
										if (following) {
											unfollowBozter()
										} else followBozter()
									}}
									label={following === true ? 'UnFollow' : 'Follow'}></Button>
								<Button disabled fontWeight={'500'} border={`0.0625rem solid ${color.Grey}`} bgtype={color.White} color={'black'} label="Message" />
								<Button
									fontWeight={'500'}
									label={'Tastes'}
									color={'black'}
									width={'4.5rem'}
									border={`0.0625rem solid ${color.Grey}`}
									onClick={() =>
										_navigate({
											pathname: '/userTaste',
											search: createSearchParams({
												id: _u_id
											}).toString()
										})
									}></Button>
								<Button
									fontWeight={'500'}
									label={'Badges'}
									bgtype={color.White}
									color={'black'}
									width={'4.5rem'}
									border={`0.0625rem solid ${color.Grey}`}
									onClick={() => {
										_navigate({
											pathname: '/userBadges',
											search: createSearchParams({
												id: _u_id
											}).toString()
										})
									}}></Button>
							</ButtonWrapper>
						)}

						<ButtonWrapper>
							<Button display={'flex'} width={'4.5rem'} color={color.Green} flexDirection={'column'} fontWeight={'500'} border={'none'} label={userData?.user?.total_bozt ? userData.user?.total_bozt : '0'} label2={'bōzts'}></Button>
							<Button
								display={'flex'}
								color={'black'}
								flexDirection={'column'}
								fontWeight={'500'}
								width={'4.5rem'}
								border={'none'}
								onClick={() => {
									_own_id != _u_id
										? _navigate({
											pathname: '/userFav',
											search: createSearchParams({
												id: _u_id
											}).toString()
										})
										: _navigate({
											pathname: '/myFav',
											search: createSearchParams({
												id: _own_id
											}).toString()
										})
								}}
								label={userData?.allFavoriteRestaurants ? userData?.allFavoriteRestaurants : '0'}
								label2={'Faves'}></Button>
							<Button
								display={'flex'}
								color={'black'}
								flexDirection={'column'}
								fontWeight={'500'}
								border={'none'}
								width={'4.5rem'}
								onClick={() => {
									_own_id != _u_id
										? _navigate({
											pathname: '/userCircle',
											search: createSearchParams({
												id: _u_id
											}).toString()
										})
										: _navigate({
											pathname: '/circle',
											search: createSearchParams({
												back: 'back'
											}).toString()
										})
								}}
								label={userData?.followingCount ? userData?.followingCount : '0'}
								label2="Circle"
							/>
							<Button
								display={'flex'}
								color={'black'}
								flexDirection={'column'}
								fontWeight={'500'}
								width={'4.5rem'}
								border={'none'}
								onClick={() => {
									_navigate({
										pathname: '/userFollower',
										search: createSearchParams({
											id: _u_id
										}).toString()
									})
								}}
								label={followers ? followers : '0'}
								label2="Followers"></Button>
						</ButtonWrapper>
						<BoztCol>
							<Spacer height={1} />
							<SearchWrapper>
								<SearchIcon />
								<Input
									width={'100%'}
									placeholder="Search by name or taste"
									borderRadius={'0.2rem'}
									margin={'0'}
									padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
									value={text}
									onChange={(event) => {
										setText(event.target.value)
										if (event.target.value.length >= 2) {
											setError('')
											setUserBozt([])

											handleSearchTextChange(event.target.value)
										} else if (event.target.value.length === 0) {
											setUserBozt(copyData)
											setError('')
											setSearchBozts()
										} else {
											// setUserBozt(copyData)
											setError('Minimum 2 characters required')
										}
									}}
								/>
								<Error> {error}</Error>
							</SearchWrapper>
							<Spacer height={1} />
							{userBozt?.length > 0
								? userBozt?.map((data) => {
									return (
										<span key={Math.random()}>
											<Suspense fallback={'Loading...'}>
												<FeedCard feedData={data} hide={true} setFetchAgain={(e) => e == true && getUserBozt(1, false)} />
											</Suspense>
										</span>
									)
								})
								: loading == false && <EmptyMessage message={'No bōzts found'}></EmptyMessage>}
						</BoztCol>
					</CustomCol>
					<CustomCol xs={11} sm={10} md={8} lg={5} bg={'none'}>
						{searchBozts ? (
							''
						) : loading == false && showMore && text == '' ? (
							<Button ref={loaderRef} color={color.White} bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} width={'100%'} label={'Load More'} onClick={() => fetchMoreBoztData()} />
						) : (
							''
						)}
					</CustomCol>
				</CustomRow>
				{loading && <Loader visible={loading} text={'Loading...'} />}
			</Container>
		</>
	)
}
const CustomRow = styled(Row)`
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 4rem;

`

const CustomCol = styled(Col)`
	background-color: ${({ bg }) => (bg ? bg : color.White)};
	padding: 0;
	overflow: hidden;
`

const BoztCol = styled.div`
	background-color: ${color.LightCream_40};
	// overflow-y: scroll;
`

const Text = styled.h1`
	margin: 0;
	text-align: center;
	font-size: ${({ size }) => (size ? size : '1rem')};
	margin: ${({ margin }) => (margin ? margin : '0')};
	font-weight: ${({ weight }) => (weight ? weight : '500')};
	display: flex;
	justify-content: center;
`
const UserBio = styled.div`
	width: 81%;
	height: 100px;
	max-height: 4rem;
    overflow-y: auto ;
`
const ShieldIcon = styled.img`
	width: 1.3rem;
	height: 1.3rem;
`

const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 1rem;
`

const Image = styled.div`
	background: ${({ src }) => (src ? `url(${process.env.REACT_APP_IMAGE_URL + src}) no-repeat` : `${color.LighterGrey}`)};
	background-size: cover;
	background-position: center;
	height: 4rem;
	width: 4rem;
	border-radius: 2rem;
`
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	padding: 1rem;
	align-items: center;
	border: none;
`

const SearchWrapper = styled.div`
	background-color: ${color.LightCream_40};
	position: relative;
`

const Error = styled.span`
	color: ${color.Red};
	position: absolute;
	font-size: 0.8rem;
	top: 2.8rem;
	left: 1.2rem;
`
const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 0.8rem;
	z-index: 1;
	left: 0.6rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`

export default Profile
