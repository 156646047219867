import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import Loader from './common/Spinner.js'
import FeedCard from './FeedCard.js'
import {useSearchParams} from 'react-router-dom'
import EmptyMessage from './common/EmptyMessage.js'
import PageHeader from './common/PageHeader.js'
import {doFetchBoztDetail} from './api/apis.js'

const NotificationDetails = () => {
	const [searchParams] = useSearchParams()
	const [loading, setLoading] = useState(false)
	const [boztData, setBoztData] = useState(null)
	let id = searchParams.get('id')
	let title = searchParams.get('title')

	useEffect(() => {
		doFetchBoztDetails()
	}, [])

	const doFetchBoztDetails = async () => {
		await doFetchBoztDetail(setLoading, id, setBoztData)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader title={title} padding={'0'} />
					</Col>
				</CustomRow>
				{boztData !== undefined && boztData !== null ? (
					<CustomRow>
						<Col xs={11} sm={10} md={8} lg={5}>
							<FeedCard feedData={boztData} hide={true} />
						</Col>
					</CustomRow>
				) : (
					<CustomRow>
						<Col xs={11} sm={10} md={8} lg={6}>
							{!loading && <EmptyMessage message={'Bozt not found'} />}
						</Col>
					</CustomRow>
				)}
				{loading && <Loader visible={loading} />}
			</Wrapper>
		</>
	)
}
const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3rem;
`
const CustomRow = styled(Row)`
	justify-content: center;
`

export default NotificationDetails
