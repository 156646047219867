import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import 'react-multi-carousel/lib/styles.css'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { BsSearch, BsXLg, BsCursor } from 'react-icons/bs'
import Loader from './common/Spinner.js'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Spacer } from '../assets/shared.js'
import EmptyMessage from './common/EmptyMessage.js'
import Input from './common/Input.js'
import Button from './common/Button.js'
import RestaurantModal from './modal/RestaurantModal.js'
import RestaurantsList from './common/RestaurantsList.js'
import { doFetchRestaurantsList, doGetCurrentAddress } from './api/apis.js'
import { toast } from 'react-toastify'

const Search = () => {
	const [location, setLocation] = useState('')
	const [loading, setLoading] = useState(false)
	const [listData, setListData] = useState([])
	const [copyListData, setCopyListData] = useState([])
	const [page, setPage] = useState(0)
	const [searchRest, setSearchRest] = useState('')
	const [showMore, setShowMore] = useState(false)
	const [showMoreClicked, setShowMoreClicked] = useState(false)
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [new_lat, setNew_Lat] = useState('')
	const [new_lng, setNew_Lng] = useState('')
	const [error, setError] = useState(false)
	const [tmpLat, setTmpLat] = useState('')
	const [tmplng, setTmpLng] = useState('')
	const [fetching, setFetching] = useState(true)
	const [pageToken, setPageToken] = useState('')

	const [openRestDetails, setOpenRestDetails] = useState(false)
	const [selectedRestID, setSelectedRestID] = useState('')
	const [boztID, setBoztID] = useState('')

	const [showCurrentLocationTxt, setShowCurrentLocationTxt] = useState(false)

	useEffect(() => {
		getCurrentLatLng()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSelect = async (value) => {
		setLocation(value)
		geocodeByAddress(value)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				setLat(latLng.lat)
				setLng(latLng.lng)
				setTmpLat(latLng.lat)
				setTmpLng(latLng.lng)
				fetchList(0, latLng.lat, latLng.lng, new_lat, new_lng)
			})
			.catch((error) => console.error('Error', error))
	}

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				await doGetCurrentAddress(position.coords.latitude, position.coords.longitude, setLoading, setLocation)
				setLat(position.coords.latitude)
				setLng(position.coords.longitude)
				setTmpLat(position.coords.latitude)
				setTmpLng(position.coords.longitude)
				setNew_Lat(position.coords.latitude)
				setNew_Lng(position.coords.longitude)
				await fetchList(0, position.coords.latitude, position.coords.longitude, position.coords.latitude, position.coords.longitude, '', true)
				setFetching(false)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				console.log('Location Permission Denied.', error)
				if (error.code == error.PERMISSION_DENIED) {
					setLoading(false)
				} else {
					setLoading(false)
				}
			},
			{ timeout: 5000, enableHighAccuracy: true }
		)
	}

	const isMobileOrTablet = () => {
		const ua = navigator.userAgent.toLowerCase();
		return /mobile|tablet|ipad|android|ios/.test(ua);
	};

	const fetchList = async (pageNum, lat, lng, uLat, uLng, searchByName, isCurrent = false) => {
		await doFetchRestaurantsList(pageNum, lat, lng, uLat, uLng, searchByName, setLoading, setListData, setShowMore, listData, setCopyListData, setPageToken, isCurrent ? '' : pageToken, setLat, setLng, isMobileOrTablet())
	}

	return (
		<>
			{!openRestDetails ? (
				<Wrapper>
					<CustomRow>
						<Col xs={11} sm={10} md={8} lg={5}>
							<TitleContainer>
								<Text>Search</Text>
							</TitleContainer>
							<Spacer height={1} />
							<InnerWrapper>
								<SearchIcon />
								<SearcInput>
									<Input
										width={'100%'}
										placeholder=" Category, Taste, or Name"
										borderRadius={'0.2rem'}
										margin={'0'}
										padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
										value={searchRest}
										onChange={(event) => {
											setPageToken('')
											setSearchRest(event.target.value)
											if (event.target.value.length >= 2 && lat !== '' && lng !== '') {
												setError(false)
												if (isMobileOrTablet() && event.target.value.length >= 4) {
													fetchList(0, showMoreClicked ? lat : tmpLat, showMoreClicked ? lng : tmplng, new_lat, new_lng, event.target.value);
												}
											} else if (event.target.value.length == 0) {
												setSearchRest('')
												setListData(copyListData)
												setError(false)
											} else {
												setError(event.target.value.length >= 2 ? false : true)
											}
										}}
										onKeyDown={(e) => {
											if (e?.key == 'Enter' || e?.key == 'Next' || e?.key == 'Go' || e?.key == 'Done') {
												e.preventDefault();
												if (searchRest?.trim()?.length >= 2 && tmpLat && tmplng && lat && lng) {
													fetchList(0, showMoreClicked ? lat : tmpLat, showMoreClicked ? lng : tmplng, new_lat, new_lng, searchRest);

												} else {
													if (lat == '' && lng == '') {
														toast.error('Please choose location first')
													}
												}
											}
										}}
									/>
								</SearcInput>
								<CloseIcon
									onClick={() => {
										setSearchRest('')
									}}
								/>

								{error && <Error>Minimum 2 characters required</Error>}
							</InnerWrapper>
							<PlacesAutocomplete
								value={location}
								onChange={(e) => {
									setPageToken('')
									setLocation(e)
								}}
								onSelect={(e) => {
									setLocation('')
									handleSelect(e)
								}}>
								{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
									<div style={{ position: 'relative' }}>
										<LocationIcon />
										<Input width={'100%'} placeholder="Neighborhood, City or Zip Code" borderRadius={'0.2rem'} margin={'0'} value={location} padding={'0.8rem 0.8rem 0.8rem 2.5rem'} {...getInputProps()} />
										<CloseIcon
											onClick={() => {
												setLocation('')
												setShowCurrentLocationTxt(true)
											}}
										/>
										<ResturantsText>RESTAURANTS NEARBY</ResturantsText>
										{showCurrentLocationTxt && (
											<FetchLocationContainer onClick={() => { setPageToken(''); getCurrentLatLng() }}>
												<LocationText>Current Location</LocationText>
											</FetchLocationContainer>
										)}
										<List>
											{suggestions.map((suggestion, i) => {
												const style = {
													backgroundColor: suggestion.active ? '#FBFAF8' : 'white'
												}
												return (
													<LocationList
														key={i + 'mapkey'}
														{...getSuggestionItemProps(suggestion, {
															style
														})}>
														{suggestion.description}
													</LocationList>
												)
											})}
										</List>
									</div>
								)}
							</PlacesAutocomplete>
							{listData?.map((item, index) => {
								return (
									<RestaurantsList
										key={index.toString()}
										restaurantsList={item}
										discover={true}
										setSelectedRestaurant={(e) => {
											setSelectedRestID(e?.r_id)
											setBoztID(e?.id)
										}}
										parent={'search'}
										setOpenRestDet={(e) => setOpenRestDetails(e)}
									/>
								)
							})}
							{showMore && searchRest.length < 1 ? (
								<Button
									color={color.White}
									bgtype={color.Orange}
									fontSize={'1rem'}
									height={'3.125rem'}
									width={'100%'}
									label={'Load More'}
									onClick={() => {
										setShowMoreClicked(true)
										setPage(page + 50)
										fetchList(page + 50, lat, lng, new_lat, new_lng)
									}}
								/>
							) : null}
						</Col>
					</CustomRow>
					{loading ? (
						<Loader visible={loading} text={'Loading...'} />
					) : searchRest === '' && listData?.length === 0 ? (
						location !== '' && <EmptyMessage message={'No restaurants found'} top={'4rem'} />
					) : (
						searchRest && listData?.length === 0 && <EmptyMessage message={'No restaurants found'} top={'4rem'} />
					)}
				</Wrapper>
			) : (
				<RestaurantModal r_id={selectedRestID} setIsOpen={setOpenRestDetails} id={boztID} lat={new_lat} lng={new_lng} />
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 4.2rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
`

const Text = styled.h1`
	font-weight: 500;
	text-align: center;
	width: 100%;
	font-size: 1.5rem;
	margin: 0;
`

const InnerWrapper = styled.div`
	width: 100%;
	position: relative;
`

const SearcInput = styled.div`
	margin-bottom: 2rem;
`

const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 0.8rem;
	z-index: 1;
	left: 0.6rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`

export const CloseIcon = styled(BsXLg)`
	cursor: pointer;
	position: absolute;
	top: 1rem;
	z-index: 1;
	right: 0.6rem;
	font-size: 0.85rem;
	padding: 0.2rem;
	color: ${color.Black};
	background-color: ${color.White};
	border-radius: 50%;
	box-shadow: 0.5px 1px 2px 0.5px ${color.Grey};
`

const LocationList = styled.div`
	background-color: ${color.White};
	text-align: left;
	padding: 0.5rem;
	cursor: pointer;
	border-bottom: 0.1px solid ${color.Grey};
`

const List = styled.div`
	position: absolute;
	z-index: 1;
	width: 98.5%;
`

const FetchLocationContainer = styled.div`
	display: flex;
	padding: 0.5rem 0rem;
	cursor: pointer;
`

const LocationText = styled.p`
	color: ${color.Orange};
	margin: 0.1rem 0rem 0rem 0.5rem;
`

const ResturantsText = styled.div`
	margin: 0.5rem 0rem 0.3rem 0.5rem;
`

const LocationIcon = styled(BsCursor)`
	position: absolute;
	top: 0.8rem;
	z-index: 1;
	left: 0.6rem;
	font-size: 1.4rem;
	color: ${color.Grey};
`

const Error = styled.span`
	color: ${color.Red};
	position: absolute;
	top: 3rem;
	left: 0.5rem;
	font-size: 0.9rem;
`

export default Search
