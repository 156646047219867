import React, {useState} from 'react'
import styled from 'styled-components'
import {color} from '../../assets/css/Color.js'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import shield from '../../assets/images/shield.png'

const CatagoryCard = ({cardData, selectionCB, type, tasteFlag, key}) => {
	const [displayIcon] = useState(cardData?.icon ? true : false)
	const [badgeIcon] = useState(type === 'badges' ? (cardData?.isSelected ? false : true) : false)
	const [isSelect, setIsSelect] = useState(cardData.isSelected)
	const handleClick = async () => {
		if (type === 'badges') {
			await selectionCB(cardData.title)
		} else {
			if (selectionCB) {
				if (isSelect) {
					await selectionCB(cardData, !isSelect)
					setIsSelect(false)
				} else {
					await selectionCB(cardData, !isSelect)
					setIsSelect(true)
				}
			}
		}
	}

	return (
		<Flex type={type}
			onClick={async () => {
				if (tasteFlag !== 'other') {
					await handleClick()
				}
			}}
			toggle={cardData.isSelected ? cardData.isSelected : false}>
			<InitialWrapper visible={cardData.isSelected ? cardData.isSelected : false}>
				{badgeIcon && <BadgeIcon src={shield} visible={cardData.isSelected ? cardData.isSelected : false} />}
				{displayIcon && <Icon src={cardData.icon} visible={cardData.isSelected ? cardData.isSelected : false} />}
				<Text visible={cardData.isSelected ? cardData.isSelected : false}>{type === 'badges' ? cardData.title : cardData.name}</Text>
			</InitialWrapper>
			<Overlay visible={cardData.isSelected ? cardData.isSelected : false}>
				<SelectIcon />
				<OverLayText color={type === 'badges' ? color.DarkPepper_80: color.Green}>{type === 'badges' ? cardData.title : cardData.name}</OverLayText>
			</Overlay>
		</Flex>
	)
}

const Flex = styled.div`
	cursor:${({type})=> (type ==='badges' && 'pointer')} ;
	padding: 0.5rem;
	min-height: 7.5rem;
	min-width: 7.5rem;
	border: none;
	background-color: ${({toggle}) => (toggle === true ? `${color.LighterGrey}` : `${color.White}`)};
	border-radius: 0.5rem;
`
const Text = styled.p`
	text-transform: uppercase;
	font-family: sans-serif;
	font-weight: 500;
	text-align: center;
	vertical-align: middle;
	display: ${({visible}) => (visible === false ? `block` : `none`)}; ;
`
const OverLayText = styled.p`
	text-transform: uppercase;
	font-family: sans-serif;
	font-weight: 500;
	text-align: center;
	margin: 0;
	color: ${({color})=> (color ? color : color.Green)};
`

const Overlay = styled.div`
	height: 100%;
	display: ${({visible}) => (visible === true ? `flex` : `none`)};
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
const SelectIcon = styled(AiOutlineCheckCircle)`
	font-size: 2rem;
	color: ${color.Green};
`
const Icon = styled.div`
	margin-top: 0.5rem;
	background: ${({src}) => (src ? `url(${process.env.REACT_APP_IMAGE_URL + src}) no-repeat` : ``)};
	background-size: contain;
	height: 2rem;
	background-position: center;
	width: 100%;
	display: ${({visible}) => (visible === false ? `block` : `none`)}; ;
`
const BadgeIcon = styled.div`
	background: ${({src}) => (src ? `url(${src}) no-repeat` : ``)};
	background-size: contain;
	height: 1.75rem;
	margin: 1rem 0 0 0;
	background-position: center;
	width: 100%;
	display: ${({visible}) => (visible === false ? `block` : `none`)};
`

const InitialWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	height: ${({visible}) => (visible === true ? `auto` : `100%`)};
	justify-content: center;
`

export default CatagoryCard
