/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flex} from './Tastes.js'
import Loader from '../components/common/Spinner.js'
import 'react-toastify/dist/ReactToastify.min.css'
import {useSelector} from 'react-redux'
import CatagoryCard from './card/CatagoryCard.js'
import {Spacer} from '../assets/shared.js'
import PageHeader from './common/PageHeader.js'
import {doFetchDesignations} from './api/apis.js'
const Designations = () => {
	const userData = useSelector((state) => state?.userData)
	const [designation, setDesignation] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchDesignations()
	}, [])

	const fetchDesignations = async () => {
		await doFetchDesignations(setLoading, userData, setDesignation)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader padding={'0'} title={'Designations'} />
					</Col>
				</CustomRow>
				<Spacer height={1} />
				<CustomRow>
					<ContentCol xs={11} sm={10} md={8} lg={5}>
						<Flex>
							{designation.map((item, index) => {
								return <CatagoryCard cardData={item} selectionCB={() => ''} key={index.toString()} />
							})}
						</Flex>
					</ContentCol>
				</CustomRow>
				{loading && <Loader visible={loading} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const ContentCol = styled(Col)`
	min-height: 25rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`
export default Designations
