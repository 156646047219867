import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import menu from '../assets/images/menu.png'
import search from '../assets/images/search.png'
import title from '../assets/images/logo_title.png'
import mapdummy from '../assets/images/mapdummy.png'
import community from '../assets/images/circle.png'
import feed from '../assets/images/feed.png'
import notification from '../assets/images/notification.png'
import { color } from '../assets/css/Color.js'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { api } from './api/callAxios'

const Navbar = () => {
	const _navigate = useNavigate()
	const { pathname } = useLocation()
	const notificationCount = useSelector((state) => state.notificationCount)
	const dispatch = useDispatch()

	const doLogin = async () => {
		if (localStorage.getItem('token')) {
			await api
				.post(`/user/auto_login`, { fcm_token: '' })
				.then(async (response) => {
					dispatch({
						type: 'SET_USER',
						payload: response?.data.data.user
					})
					dispatch({
						type: 'DESIGNATION',
						payload: response?.data.data.user.designation
					})
					dispatch({
						type: 'CIRCLE_BOZT',
						payload: response?.data.data.my_cicle_bozts.bozts
					})
					dispatch({
						type: 'NOTIFICATIONS_COUNT',
						payload: response.data.data.my_cicle_bozts.notificationCount
					})
					localStorage.removeItem('currentTab')
					localStorage.setItem('token', response?.data.data.user.auth_token)
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}
	return (
		<NavbarWrapper className="navbar">
			<Wrapper>
				<CustomRow>
					<Logo xs={3} sm={3} md={2} lg={3.5} xl={3.5} xxl={3.5}>
						<a href="https://bozt.app/">
							<Image src={title} />
						</a>
					</Logo>
					<CenterCol xs={6} sm={6} md={8} lg={5} xl={5} xxl={5}>
						<NavbarLink to="/" isactive={pathname === '/'} onClick={() => localStorage.removeItem('currentTab')}>
							<HomeIcon src={feed} />
							<IconText>Home</IconText>
						</NavbarLink>
						<NavbarLink to="/discover" isactive={pathname === '/discover'} onClick={() => localStorage.removeItem('currentTab')}>
							<LocationIcon src={mapdummy} />
							<IconText>Discover</IconText>
						</NavbarLink>
						<NavbarLink to="/circle" isactive={pathname === '/circle'} onClick={() => localStorage.removeItem('currentTab')}>
							<CommunityIcon src={community} />
							<IconText>Circle</IconText>
						</NavbarLink>
						<NavbarLink to="/search" isactive={pathname === '/search'} onClick={() => localStorage.removeItem('currentTab')}>
							<MenuIcon src={search} />
							<IconText>Search</IconText>
						</NavbarLink>
					</CenterCol>
					<RightCol xs={3} sm={3} md={2} lg={3.5} xl={3.5} xxl={3.5}>
						<NotificationWrapper
							onClick={() => {
								_navigate('/notification')
								localStorage.removeItem('currentTab')
							}}>
							{notificationCount > 0 && (
								<CountDiv>
									<Count>{notificationCount}</Count>
								</CountDiv>
							)}
							<NotificationIcon
								src={notification}
								onClick={() => {
									_navigate('/notification')
									localStorage.removeItem('currentTab')
								}}
								isactive={pathname === '/notification'}
							/>
						</NotificationWrapper>
						<MenuIcon
							menuImg={true}
							src={menu}
							onClick={() => {
								_navigate('/menu')
								doLogin()
								localStorage.removeItem('currentTab')
							}}
							isactive={pathname === '/menu'}
						/>
					</RightCol>
				</CustomRow>
			</Wrapper>
		</NavbarWrapper>
	)
}

const NavbarWrapper = styled.div`
	display: flex;
	justify-content: center;
	background-color: ${color.LightCream_10};
	${media.lg`
  background-color: ${color.LightCream_20};
  `}
`

const Wrapper = styled(Container)`
	// max-width: 100%;
	margin: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
`
const CustomRow = styled(Row)`
	justify-content: center;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	${media.xs`
	height:2.5rem;
  `}
	${media.sm`
	height:2.5rem;

  `}
  ${media.md`
  height:auto;

  `}
`

const Logo = styled(Col)`
	@media (max-width: 768px) {
		max-width: 100%;
		display: flex;
		justify-content: start;
		margin-left: 0;
		padding-left: 0;
	}
`

const CenterCol = styled(Col)`
	display: flex;
	justify-content: space-around;
	align-items: center;
	${media.xs`
	gap:1rem;
  `}
	${media.sm`
	justify-content: space-between;
	gap:1.5rem;
  `}
`
const RightCol = styled(Col)`
	display: flex;
	justify-content: space-betweem;
	align-items: center !important;
	gap: 0.3rem;
	${media.xs`
	align-items: center !important;
  justify-content: flex-end;
  `}
	${media.sm`
  justify-content: flex-end;
 `}
  ${media.md`
  justify-content: flex-end;
  `}
  ${media.lg`
  justify-content: flex-end;
  `}
`

const HomeIcon = styled.img`
	font-weight: 100;
	filter: brightness(50%);
	opacity: 0.68;
	${media.xs`
  height: 1.375rem;
  width: 1.375rem;
`}
	${media.sm`
  height: 1.563rem;
  width: 1.563rem;
`}
${media.md`
height: 1.563rem;
  width: 1.563rem;
`}
${media.lg`
height: 1.7rem;
  width: 1.7rem;
  `}
`
const LocationIcon = styled.img`
	filter: brightness(50%);
	${media.xs`
  height: 1.2rem;
  width: 1rem;
`}
	${media.sm`
	height: 1.563rem;
	width: 1rem;
`}
${media.md`
height: 1.4rem;
  width: 1rem;
`}
${media.lg`
height: 1.6rem;
  width: 1.3rem;
  `}
`
const NotificationWrapper = styled.div`
	position: relative;
`
const CountDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 1rem;
	height: 1rem;
	background-color: red;
	border-radius: 0.5rem;
	position: absolute;
	padding: 0.2rem;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	cursor: pointer;
`
const Count = styled.p`
	margin: 0;
	font-size: 0.6rem;
	line-height: 0;
	color: white;
`
const CommunityIcon = styled.img`
	filter: brightness(50%);
	cursor: pointer;
	${media.xs`
  height: 1.675rem;
  width: 1.375rem;

`}
	${media.sm`
  height: 1.563rem;
  width: 1.563rem;

`}
${media.md`
  height: 1.563rem;
  width: 1.563rem;

`}
${media.lg`
  height: 1.7rem;
  width: 1.7rem;

  `}
`
const MenuIcon = styled.img`
	margin-right: ${({ menuImg }) => menuImg && '1rem !important'};
	filter: ${({ isactive }) => (isactive ? `invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%)` : `brightness(50%)`)};
	&:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
	cursor: pointer;
	${media.xs`
  height: 1.375rem;
  width: 1.375rem;
`}
	${media.sm`
  height: 1.563rem;
  width: 1.563rem;

`}
${media.md`
  height: 1.563rem;
  width: 1.563rem;
`}
${media.lg`
margin-right:${({ menuImg }) => menuImg && '2rem !important'} ;
height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
 `}
 &:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
`

const NotificationIcon = styled.img`
	filter: ${({ isactive }) => (isactive ? `invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%)` : `brightness(50%)`)};
	cursor: pointer;
	${media.xs`
  height: 1.175rem;
  width: 1.175rem;

`}
	${media.sm`
  height: 1.563rem;
  width: 1.563rem;

`}
${media.md`
height: 1.563rem;
  width: 1.563rem;

`}
${media.lg`
height: 1.7rem;
  width: 1.7rem;
  `}
  &:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
`

const Image = styled.img`
	margin-left: 1rem;
	cursor: pointer;
	${media.xs`
  height: auto;
  width: 4rem;
  margin-left: 1rem;
`}
	${media.sm`
  height: auto;
  width: 6.25rem;
`}
${media.md`
height: auto;
  width: 5.25rem;
`}
${media.lg`
margin-left: 2rem;
height: auto;
width: 6.25rem;`}
`
export const NavbarLink = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${color.DarkPepper_20};
	filter: ${({ isactive }) => (isactive ? `invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%)` : `${color.DarkPepper_20}`)};
	text-decoration: none;
	&:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
	// &:active {
	// 	filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	// }
`
const IconText = styled.p`
	margin: 0;
	font-family: monospace;
	color: ${color.Black};
	display: none;
	${media.sm`
		display:block;
	`}
	${media.md`
		display:block;
	`}
	${media.lg`
		display:block;
	`}
`

export default Navbar
