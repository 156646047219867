import React from 'react'
import styled from 'styled-components';
import { color } from '../../assets/css/Color';

const Input = ({
    value,
    onChange,
    placeholder,
    type,
    className,
    readonly,
    disabled,
    width,
    height,
    border,
    padding,
    borderRadius,
    margin,
    fontWeight,
    fontSize,
    onKeyDown
}) => {
    return (
        <InputField
            width={width}
            height={height}
            value={value}
            border={border}
            type={type}
            className={className}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder}
            padding={padding}
            borderRadius={borderRadius}
            margin={margin}
            fontWeight={fontWeight}
            fontSize={fontSize}
            onChange={(event) => {
                onChange(event);
            }}
            onKeyDown={onKeyDown}
        />
    )
}

const InputField = styled.input`
  padding: ${({ padding }) => (padding ? padding : `0.5rem`)};
  outline: none;
  border-radius: 0.5rem;
  margin: ${({ margin }) => (margin ? margin : `0.5rem`)};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  border: ${({ border }) => (border ? border : `0.063rem solid ${color.LightGrey}`)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '0rem')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : `1rem`)};
  font-weight: 400;
  max-height: 80rem;
  font-weight: ${({ fontWeight }) => (fontWeight)};
`;
export default Input
