import { useEffect, useRef, useState } from 'react'
import Carosel from 'nuka-carousel'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { BsChevronLeft, BsChevronRight, BsPlay, BsPause, BsArrowsAngleExpand } from 'react-icons/bs'
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi'
import { color } from '../../assets/css/Color.js'
import ClipLoader from 'react-spinners/ClipLoader'
import Slider from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const MediaViewer = ({ boztMedia, isResraurant, parent }) => {
	const [lightBoxOpen, setLightBoxOpen] = useState(false)
	const [photoIndex, setPhotoIndex] = useState(0)
	const [videoRef, setVideoRef] = useState(null)
	const [loading, setLoading] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)
	const [isMuted, setIsMuted] = useState(true)
	const [previuos, setPrevious] = useState(0)
	const [imgType, setImgType] = useState(false)
	const [loadedData, setLoadedData] = useState()
	const [loadedImageData, setLoadedImageData] = useState()
	const [imageLoading, setImageLoading] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const _videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [thumbnailUrl, setThumbnailUrl] = useState(null);
	const [videoWidth, setVideoWidth] = useState()

	const captureSnapshot = async () => {
		try {
			const videoURL = _videoRef.current.src;
			const proxyURL = videoURL;
			const response = await fetch(proxyURL);
			const blob = await response.blob();
			const dataURL = await blobToDataURL(blob);
			setThumbnailUrl(dataURL);
		} catch (error) {
			console.error(error);
		}
	};

	const blobToDataURL = (blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	};
	useEffect(() => {
		if (_videoRef?.current) {
			_videoRef?.current.addEventListener("playing", captureSnapshot);
		}

		return () => {
			if (_videoRef?.current) {
				_videoRef?.current.removeEventListener("playing", captureSnapshot);
			}
		};
	}, []);

	const handleVideoPlay = (control) => {
		const elem = _videoRef?.current;
		if (elem && elem.paused && isPlaying == false) {

			setTimeout(() => {
				elem.play()
				const videoElement = document.getElementById('video');
				if (videoElement) {
					videoElement.addEventListener('click', () => {
						_videoRef?.current?.play();
					});
				}

			}, 150);
			setIsPlaying(true)
			setLoading(false)
		} else if (elem && !elem.paused && isPlaying) {
			setTimeout(() => {
				elem.pause();
				const videoElement = document.getElementById('video');
				if (videoElement) {
					videoElement.addEventListener('click', () => {
						_videoRef?.current?.pause();
					});
				}

			}, 150);
			setIsPlaying(false)

		}
	}

	const handleVolume = (control) => {
		if (control == 'unmute') {
			videoRef.target.muted = false
			setIsMuted(false)
		} else if (control == 'mute') {
			videoRef.target.muted = true
			setIsMuted(true)
		}
	}

	const fullScreen = () => {
		const elem = _videoRef?.current;
		if (elem) {
			setTimeout(() => {
				if (elem?.requestFullscreen) {
					elem?.requestFullscreen();
					if (elem && elem.paused) {
						setTimeout(() => {
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.pause();
							});
							elem.pause();
						}, 150);
						setIsPlaying(false)
						// setLoading(true)

					} else if (elem && !elem.paused) {
						setTimeout(() => {
							elem.play()
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.play();
							});

						}, 150);
						setIsPlaying(true)
						setLoading(false)
					}
				} else if (elem?.mozRequestFullScreen) {
					elem?.mozRequestFullScreen();
					if (elem && elem.paused) {
						setTimeout(() => {
							elem.pause();
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.pause();
							});
						}, 150);
						setIsPlaying(false)
						// setLoading(true)
					} else if (elem && !elem.paused) {
						setTimeout(() => {
							elem.play()
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.play();
							});

						}, 150);
						setIsPlaying(true)
						setLoading(false)
					}
				} else if (elem?.webkitRequestFullscreen) {
					elem?.webkitRequestFullscreen();
					if (elem && elem.paused) {
						setTimeout(() => {
							elem.pause();
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.pause();
							});
						}, 150);
						setIsPlaying(false)
						// setLoading(true)
					} else if (elem && !elem.paused) {
						setTimeout(() => {
							elem.play()
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.play();
							});

						}, 150);
						setIsPlaying(true)
						setLoading(false)
					}
				} else if (elem?.msRequestFullscreen) {
					elem?.msRequestFullscreen();
					if (elem && elem.paused) {
						setTimeout(() => {
							elem.pause();
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.pause();
							});
						}, 150);
						setIsPlaying(false)
						// setLoading(true)
					} else if (elem && !elem.paused) {
						setTimeout(() => {
							elem.play()
							setIsPlaying(true)
							setLoading(false)
							document.getElementById('video').addEventListener('click', () => {
								_videoRef?.current?.play();
							});

						}, 150);

					}
				}

			}, 150)
		}
	};


	const fullScreenHandler = () => {
		const elem = _videoRef?.current;
		const fullscreenElement =
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement;

		if (fullscreenElement) {
			handleVideoPlay()
		} else {
			if (elem) {
				setTimeout(() => {
					elem.pause();
					document.getElementById('video').addEventListener('click', () => {
						_videoRef?.current?.pause();
					});
				}, 150);
				setIsPlaying(false)
			}

		}
	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', fullScreenHandler);
		document.addEventListener('webkitfullscreenchange', fullScreenHandler);
		document.addEventListener('mozfullscreenchange', fullScreenHandler);
		document.addEventListener('MSFullscreenChange', fullScreenHandler);

		return () => {
			document.removeEventListener('fullscreenchange', fullScreenHandler);
			document.removeEventListener('webkitfullscreenchange', fullScreenHandler);
			document.removeEventListener('mozfullscreenchange', fullScreenHandler);
			document.removeEventListener('MSFullscreenChange', fullScreenHandler);
		};
	}, []);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
			slidesToSlide: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1
		}
	}
	const isSafari = () => {
		const ua = navigator.userAgent.toLowerCase();
		return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
	};


	useEffect(() => {
		const userAgent = navigator.userAgent;
		if (isSafari) {
			setIsMobile(true)
		} else
			if (/(android|iphone|ipad)/i.test(userAgent)) {
				setIsMobile(true)
			} else {
				setIsMobile(false)
			}

	}, []);

	useEffect(() => {
		const videoHeight = _videoRef?.current?.clientHeight;
		const _videoWidth = _videoRef?.current?.clientWidth;
		_videoRef?.current?.load()
		if (document.getElementsByTagName("video")?.length) {
			document.getElementsByTagName("video")[0].load();

		}
		if (isSafari && _videoRef?.current) {
			let player = _videoRef?.current.children[0];
			if (player) {
				player.controls = false;
				player.playsinline = true;
				player.muted = true;
				player.setAttribute("muted", "");
				player.autoplay = true;
			}
		}
		setLoadedData(videoHeight)
		setVideoWidth(_videoWidth)

		const calculateAspectRatio = () => {
			if (_videoRef?.current) {
				const video = _videoRef?.current;

				// Calculate aspect ratio
				const aspectRatio = _videoWidth / videoHeight;

				// Set aspect ratio
				if (parent != 'commentModel')
					video.style.aspectRatio = aspectRatio;
			}
		};
		// Call the function initially
		calculateAspectRatio();

		// Attach resize event listener to recalculate aspect ratio on window resize
		window.addEventListener('resize', calculateAspectRatio);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', calculateAspectRatio);
		};
	}, [_videoRef]);

	return (<> {boztMedia?.length > 1 ? (
		isResraurant == true ? (
			<Slider responsive={responsive} arrows={loadedImageData ? true : false}>
				{boztMedia?.map((e, index) => {
					return index === 0 && e?.media?.toString().includes('mp4') ? (
						<>
							{loading && (
								<MiniSpinner>
									<ClipLoading />
								</MiniSpinner>
							)}
							<div style={{ objectFit: 'cover', objectPosition: 'center', position: videoWidth >= 620 ? '' : 'relative' }}>
								<video
									id="video"
									ref={_videoRef}
									// style={{ aspectRatio: videoWidth / loadedData }}
									onLoadStart={(e) => {
										setVideoRef(e)
										setLoading(true)
									}}
									preload={isMobile ? 'metadata' : 'auto'}
									// controls="controls"
									controls={false}
									disablePictureInPicture
									controlsList="nodownload noplaybackrate noplay nopause"
									onLoadedData={(e) => setLoadedData(e?.target?.offsetHeight)}
									onProgress={(e) => {
										setLoading(false)
									}}
									loop
									playsInline
									autoplay={false}
									muted={true}
								>
									<source src={process.env.REACT_APP_IMAGE_URL + e?.media} type="video/mp4" />
								</video>
								{!loading && imgType === false && (
									<PlayPauseButton>
										{isPlaying ? (
											<Pause
												isMobile={isMobile}
												onClick={() => {
													handleVideoPlay('pause')
												}}
											/>
										) : (
											<Play
												isMobile={isMobile}
												onClick={() => {
													handleVideoPlay('play')
												}}
											/>
										)}
									</PlayPauseButton>
								)}
								{imgType === false && (
									<VolumeButton>
										{isMuted ? (
											<VolumeMute
												onClick={() => {
													handleVolume('unmute')
												}}
											/>
										) : (
											<VolumeUnmute
												onClick={() => {
													handleVolume('mute')
												}}
											/>
										)}
									</VolumeButton>
								)}
								<MaxButton
									// onMouseEnter={() => fullScreen()}
									onClick={() => {
										fullScreen()
									}}
								/>
							</div>
						</>
					) : (
						<RestaurantImage
							style={{
								height: '288px',
								width: '100%',
								objectFit: 'cover',
								objectPosition: 'center',
								// aspectRatio: isResraurant === false ? 'auto' : e?.width / e?.height
							}}
							onLoad={(e) => {
								previuos === 0 && setLoadedImageData(e?.target?.clientHeight)
							}}
							onClick={() => { setLightBoxOpen(true); setPhotoIndex(index) }}
							src={
								isResraurant === true
									? process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false'
										? e
										: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${e?.width}&photoreference=${e?.photo_reference}&key=${process.env.REACT_APP_MAP_API_KEY}`
									: process.env.REACT_APP_IMAGE_URL + (e?.media?.data === undefined ? e?.media : e?.media?.data)
							}
							alt="image"
						/>
					)
				})}
			</Slider>
		) : (
			<Carosel
				adaptiveHeight={true}
				style={boztMedia[0].media?.toString().includes('mp4') && previuos === 0 && loadedData ? { height: loadedData } : isResraurant == true && previuos === 0 && { height: loadedImageData }}
				renderCenterLeftControls={
					previuos === 0
						? false
						: ({ previousSlide }) => (
							<ArrowIcon
								onClick={() => {
									previousSlide()
									setPrevious(previuos - 1)
									setImgType(boztMedia[0].media?.toString().includes('mp4') && previuos - 1 === 0 && false)
								}}>
								<BsChevronLeft style={{ color: 'black' }} />
							</ArrowIcon>
						)
				}
				renderCenterRightControls={
					previuos === boztMedia?.length - 1
						? false
						: ({ nextSlide }) => (
							<ArrowIcon
								onClick={() => {
									nextSlide()
									setPrevious(previuos + 1)
									setImgType(boztMedia[0].media?.toString().includes('mp4') && previuos + 1 === 1 && true)
								}}>
								<BsChevronRight style={{ color: 'black' }} />
							</ArrowIcon>
						)
				}>
				{boztMedia?.map((e, index) => {
					return index === 0 && e?.media?.toString().includes('mp4') ? (
						<>
							{loading && (
								<MiniSpinner>
									<ClipLoading />
								</MiniSpinner>
							)}
							{/* flexGrow: 1, height: '28rem', width: '26rem', */}
							<div style={{ objectFit: 'cover', objectPosition: 'center', position: videoWidth >= 620 ? '' : 'relative', width: parent == 'commentModel' ? '18rem' : '' }}>
								<video
									id="video"
									ref={_videoRef}
									// style={{ aspectRatio: videoWidth / loadedData }}
									onLoadStart={(e) => {
										setVideoRef(e)
										setLoading(true)
									}}
									preload={isMobile ? 'metadata' : 'auto'}
									controls={false}
									disablePictureInPicture
									controlsList="nodownload noplaybackrate noplay nopause"
									onLoadedData={(e) => { setLoadedData(e?.target?.offsetHeight <= 1000 ? e?.target?.offsetHeight : 600); setLoading(false); }}
									playsInline
									onProgress={(e) => {
										setLoading(false)
									}}
									autoplay={false}
									poster={thumbnailUrl}
									muted={true}
									width={parent == 'commentModel' && '100%'}
									height={parent == 'commentModel' && '100%'}

									webkit-playsinline="true"
									x-webkit-airplay="allow"
									loop
									src={isSafari ? process.env.REACT_APP_IMAGE_URL + e?.media + '#t=1.5' : process.env.REACT_APP_IMAGE_URL + e?.media}
								>
									<source src={isSafari ? process.env.REACT_APP_IMAGE_URL + e?.media + '#t=1.5' : process.env.REACT_APP_IMAGE_URL + e?.media} type="video/mp4" />
								</video>
								<canvas ref={canvasRef} style={{ display: "none" }}></canvas>
								{!loading && imgType === false && previuos == 0 && (
									<PlayPauseButton onTouchStart={() => {
										handleVideoPlay('play');
									}}
										onTouchEnd={() => {
											handleVideoPlay('pause');
										}}>
										{isPlaying ? (
											<Pause
												isMobile={isMobile}
												onClick={() => {
													handleVideoPlay('pause')
												}}
											/>
										) : (
											<Play
												isMobile={isMobile}
												onClick={() => {
													handleVideoPlay('play')
												}}
											/>
										)}
									</PlayPauseButton>
								)}
								{imgType === false && previuos == 0 && (
									<VolumeButton>
										{isMuted ? (
											<VolumeMute
												onClick={() => {
													handleVolume('unmute')
												}}
											/>
										) : (
											<VolumeUnmute
												onClick={() => {
													handleVolume('mute')
												}}
											/>
										)}
									</VolumeButton>
								)}
								{previuos == 0 && <MaxButton
									onClick={() => {
										fullScreen()
									}}
								/>}
							</div>

						</>
					) : (
						<>
							{imageLoading && (
								<MiniSpinner>
									<ClipLoading />
								</MiniSpinner>
							)}

							<Image
								mwidth={parent === 'commentModel' ? '100%' : isResraurant === true && '100%'}
								style={{
									flexGrow: 1,
									height: 'max-content',
									width: '100%',
									aspectRatio: isResraurant === true ? 'auto' : e?.media?.width / e?.media?.height
								}}
								onLoadStart={() => {
									setImageLoading(true)
								}}
								onLoad={(e) => {
									previuos === 0 && setLoadedImageData(e?.target?.clientHeight)
									setImageLoading(false)
								}}
								src={
									isResraurant === true
										? process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false'
											? e
											: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${e?.width}&photoreference=${e?.photo_reference}&key=${process.env.REACT_APP_MAP_API_KEY}`
										: process.env.REACT_APP_IMAGE_URL + (e?.media?.data === undefined ? e?.media : e?.media?.data)
								}
								alt="image"
							/>
						</>
					)
				})}
			</Carosel>
		)
	) : (
		boztMedia?.map((e, index) => {
			return e?.media?.toString()?.includes('mp4') ? (
				<VideoContainer key={index}>
					{loading && (
						<MiniSpinner>
							<ClipLoading />
						</MiniSpinner>
					)}
					<div style={{ position: videoWidth >= 620 ? '' : 'relative' }}>
						<video
							ref={_videoRef}
							// style={{ aspectRatio: videoWidth / loadedData }}
							onLoadStart={(e) => {
								setVideoRef(e)
								setLoading(true)
							}}
							controls={false}
							onLoadedData={(e) => { setLoading(false); }}
							onProgress={(e) => {
								setLoading(false)
							}}
							preload={isMobile ? 'metadata' : 'auto'}
							disablePictureInPicture
							controlsList="nodownload noplaybackrate noplay nopause"
							playsInline

							onCanPlayThrough={() => {
								setLoading(false);
							}}
							autoplay={false}
							muted={true}
							poster={thumbnailUrl}
							webkit-playsinline="true"
							x-webkit-airplay="allow"
							width={parent == 'commentModel' && '100%'}
							height={parent == 'commentModel' && '100%'}
							loop
							src={isSafari ? process.env.REACT_APP_IMAGE_URL + (e?.media?.data == undefined ? e?.media : e?.media?.data) + '#t=1.5' : process.env.REACT_APP_IMAGE_URL + (e?.media?.data == undefined ? e?.media : e?.media?.data)}

						>
							<source src={process.env.REACT_APP_IMAGE_URL + (e?.media?.data == undefined ? e?.media : e?.media?.data)} type="video/mp4" />
						</video>
						<canvas ref={canvasRef} style={{ display: "none" }}></canvas>
						{!loading && imgType === false && (
							<PlayPauseButton>
								{isPlaying ? (
									<Pause
										onClick={() => {
											handleVideoPlay('pause')
										}}
									/>
								) : (
									<Play
										onClick={() => {
											handleVideoPlay('play')
										}}
									/>
								)}
							</PlayPauseButton>
						)}
						{imgType === false && (
							<VolumeButton>
								{isMuted ? (
									<VolumeMute
										onClick={() => {
											handleVolume('unmute')
										}}
									/>
								) : (
									<VolumeUnmute
										onClick={() => {
											handleVolume('mute')
										}}
									/>
								)}
							</VolumeButton>
						)}
						<FullScreenButton
							isSingle={true}
							isWidth={videoWidth >= 620}
							onClick={() => {
								fullScreen()
							}}
						/>
					</div>
				</VideoContainer>
			) : (
				<span key={index.toString()}>
					<Image
						mwidth={parent === 'commentModel' && '100%'}
						style={{ flexGrow: 1, height: 'max-content', width: '100%', aspectRatio: e?.media?.width / e?.media?.height }}
						src={process.env.REACT_APP_IMAGE_URL + (e?.media?.data === undefined ? e?.media : e?.media?.data)}
						alt="image"
					/>
				</span>
			)
		})
	)
	}
		{lightBoxOpen && (
			<Lightbox
				mainSrc={isResraurant === true
					? process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false'
						? boztMedia[photoIndex]
						: `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${boztMedia[photoIndex]?.width}&photoreference=${boztMedia[photoIndex]?.photo_reference}&key=${process.env.REACT_APP_MAP_API_KEY}`
					: process.env.REACT_APP_IMAGE_URL + (boztMedia[photoIndex]?.media?.data === undefined ? boztMedia[photoIndex]?.media : boztMedia[photoIndex]?.media?.data)}
				nextSrc={boztMedia?.length > 1 ? boztMedia[(photoIndex + 1) % boztMedia?.length] : false}
				prevSrc={boztMedia.length > 1 ? boztMedia[(photoIndex + boztMedia?.length - 1) % boztMedia?.length] : false}
				onCloseRequest={() => setLightBoxOpen(false)}
				onMovePrevRequest={() => setPhotoIndex((photoIndex + boztMedia?.length - 1) % boztMedia?.length)}
				onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % boztMedia?.length)}
			/>
		)}

	</>
	)
}

const ArrowIcon = styled.button`
	background-color: ${color.White};
	margin: 0 0.5rem;
	opacity: 0.9;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1.8rem;
	width: 1.8rem;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	color: ${color.Black};
	&:hover {
		opacity: 0.8;
	}
`

const VideoContainer = styled.div`
	/* display: flex;
	justify-content: center; */
	position: relative;
	object-fit: cover;
	object-position: center;
	/* width: 26rem; */
	overflow: hidden;
	display: grid;
	justify-content: center;
`

const RestaurantImage = styled.img`
	object-fit: cover;
	justify-content: center;
`

export const Image = styled.img`
	object-fit: cover;
	justify-content: center;
`

const PlayPauseButton = styled.button`
	z-index: 99;
	opacity: 0.5;
	position: absolute;
	top: 41.5%;
	left: 45.4%;
	background: transparent;
	border: none;
	height: 4rem;
	width: 4rem;
`

const Pause = styled(BsPause)`
	cursor: pointer;
	color:  ${({ isMobile }) => (isMobile ? color.Black : color.White)};
	height: 3rem;
	width: 3rem;
	background-color:  ${({ isMobile }) => (isMobile ? color.White : 'transparent')};
	border-radius: 5rem;
`

const Play = styled(BsPlay)`
	cursor: pointer;
	color:  ${({ isMobile }) => (isMobile ? color.Black : color.White)};
	height: 3rem;
	width: 3rem;
	background-color:  ${({ isMobile }) => (isMobile ? color.White : 'transparent')};
	border-radius: 5rem;
`

const VolumeButton = styled.button`
	z-index: 99;
	opacity: 0.5;
	position: absolute;
	top: 5%;
	right: 1%;
	background: transparent;
	border: none;
	height: 4rem;
	width: 4rem;
`
const MaxButton = styled(BsArrowsAngleExpand)`
	cursor: pointer;
	color: ${color.White};
	height: 1.5rem;
	width: 1.5rem;
	z-index: 9009;
	position: absolute;
	/* top: 5%; */
	right: 4%;
	bottom: 1%;
	background: transparent;
	border: none;
`
const FullScreenButton = styled(BsArrowsAngleExpand)`
  cursor: pointer;
  color: ${color.White};
  height: 1.5rem;
  width: 1.5rem;
  z-index: 9009;
  position: absolute;
  right: 4%;
  ${({ isSingle, isWidth }) =>
		isWidth ? 'bottom: 2%;' : isSingle
			? 'bottom: 1.5%;'
			: 'bottom: 1.5%;'
	}
  background: transparent;
  border: none;
`;

const VolumeMute = styled(BiVolumeMute)`
	cursor: pointer;
	color: ${color.White};
	height: 2rem;
	width: 2rem;
`

const VolumeUnmute = styled(BiVolumeFull)`
	cursor: pointer;
	color: ${color.White};
	height: 2rem;
	width: 2rem;
`
const SimpleVolumeButton = styled.button`
	z-index: 99;
	opacity: 0.5;
	position: absolute;
	top: 10%;
	right: 2%;
	background: transparent;
	border: none;
	height: 4rem;
	width: 4rem;
`
export const MiniSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  height: 18.75rem;
  margin: 0 4.2rem;
  width: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.Grey};
  opacity: 0.5;
  z-index: 9;
  ${media.xs`
    height: auto;
    width: 100%;
	left: 30%;
  `};
  ${media.sm`
    height: auto;
    width: 100%;
    object-fit: contain;
	left: 30%;
  `};
  ${media.md`
    height: auto;
    width: 100%;
	left: 40%;
    object-fit: contain;
  `};
  ${media.lg`
  left: 40%;
    height: 23.125rem;
    width: 100%;
    justify-content: center;
    object-fit: contain;
  `};
`;

export const ClipLoading = styled(ClipLoader)`
	// opacity: 0.3;
	margin-top: -2.5rem;
`
export default MediaViewer
