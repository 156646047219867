import React from 'react'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import {color} from '../../assets/css/Color.js'

const Loader = (props) => {
	return (
		<Wrapper visible={props.visible} transparent={props.transparent} top={props?.top} width={props.width}>
			<Spinner icon="spinner" className="spinner" />
			<Text weight="500">{props.text}</Text>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: ${(props) => (props.visible === true ? 'flex ' : 'none')};
	position: fixed;
	cursor: pointer;
	font-weight: bold;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: ${(props) => (props.top ? props?.top : '0')};
	right: 0;
	left: 0;
	bottom: 0;
	height: ${(props) => (props.width ? 'auto' : '100vh')};
	width: ${(props) => (props.width ? 'auto' : '100vw')};
`

const Spinner = styled(ClipLoader)`
	color: ${color.Black};
	font-size: 12rem;
	font-weight: bold;
	animation: spin-logo infinite 1.5s linear;
	@keyframes spin-logo {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`
const Text = styled.span`
	font-weight: ${({weight}) => `${weight}`};
	font-family: sans-serif;
	color: ${color.DarkPepper_80};
`

export default Loader
