import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import CommentModal from './modal/CommentModal.js'
import {useNavigate, createSearchParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {boztTypes, commentBozt, userTypes} from '../utils/helpers'
import PageHeader from './common/PageHeader.js'
import RestaurantModal from './modal/RestaurantModal.js'
import EmptyMessage from './common/EmptyMessage.js'
import {doGetNotification} from './api/apis.js'

const Notification = () => {
	const dispatch = useDispatch()
	const _latLng = useSelector((state) => state?.latLng)
	const [notificationData, setNotificationData] = useState([])
	const [loading, setLoading] = useState(true)
	const [commentModalOpen, setCommentModalOpen] = useState(false)
	const [boztId, setBoztId] = useState('')
	const [restID, setRestID] = useState()
	const [openRestDet, setOpenRestDet] = useState(false)
	const [boztID, setBoztID] = useState('')
	const _navigate = useNavigate()

	useEffect(() => {
		getNotification()
	}, [])

	const getNotification = async () => {
		await doGetNotification(dispatch, setNotificationData, setLoading)
	}

	const detailsNavigation = (item) => {
		if (userTypes.includes(item.type)) {
			_navigate({
				pathname: '/profile',
				search: createSearchParams({
					id: item.u_id
				}).toString()
			})
		} else if (boztTypes.includes(item.type)) {
			_navigate({
				pathname: '/notificationDetails',
				search: createSearchParams({
					id: item.b_id,
					title: item.r_title
				}).toString()
			})
		} else if (commentBozt.includes(item.type)) {
			setBoztId(item)
			setCommentModalOpen(true)
		} else {
			setBoztID(item.b_id)
			setRestID(item?.r_id)
			setOpenRestDet(true)
		}
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader title={'Notifications'} padding={'0'} />
						{notificationData?.length > 0
							? notificationData.map((data) => {
									return (
										<span key={Math.random()}>
											<ContentWrapper>
												<LeftDiv
													onClick={() => {
														_navigate({
															pathname: '/profile',
															search: createSearchParams({
																id: data.u_id
															}).toString()
														})
													}}>
													<UserImage src={process.env.REACT_APP_IMAGE_URL + data.image} alt="img" />
												</LeftDiv>
												<VerticleRow />
												<ContentDiv
													height={data.message?.length >= 35 ? '5.063rem' : '4.063rem'}
													onClick={() => {
														detailsNavigation(data)
													}}>
													<UserName>
														{data.name} &nbsp;{' '}
														{data.degree && (
															<>
																<span style={{marginBottom: '0.5rem'}}>.</span> &nbsp;<span style={{fontSize: '0.8rem', fontWeight: '300'}}>{data.degree}</span>
															</>
														)}
													</UserName>
													<Action>{data.message}</Action>
												</ContentDiv>
											</ContentWrapper>
											<HorizontalRow />
										</span>
									)
							  })
							: loading === false && <EmptyMessage message={'No notification found'}></EmptyMessage>}
					</Col>
				</CustomRow>
				{commentModalOpen && <CommentModal handleClose={() => setCommentModalOpen(false)} data={boztId} />}
			</Wrapper>
			{loading === true ? <Loader visible={loading} text={'Loading...'} /> : ''}
			{openRestDet && <RestaurantModal setIsOpen={setOpenRestDet} r_id={restID} id={boztID} lat={_latLng?.lat} lng={_latLng?.lng} />}
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.5rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${color.White};
	cursor: pointer;
`

const ContentDiv = styled.div`
	margin-left: 0rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: ${({height}) => height};
	width: 100%;
	border-radius: 1.25rem;
`

const LeftDiv = styled.div`
	margin-left: 0.5rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
`

const UserImage = styled.img`
	height: 3.125rem;
	width: 3.125rem;
	border-radius: 1.563rem;
`
const UserName = styled.span`
	margin: 0;
	padding: 0;
	font-weight: 500;
	display: flex;
	align-items: center;
`

const Action = styled.p`
	margin: 0;
	font-weight: 300;
`

const HorizontalRow = styled.div`
	border-top: 0.0625rem solid ${color.LightGrey};
	margin-top: 0.0625rem;
	width: 100%;
`

const VerticleRow = styled.div`
	border-left: 0.0625rem solid ${color.LightGrey};
	margin-top: 1rem;
	margin-bottom: 1rem;
`

export default Notification
