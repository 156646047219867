export const color = {
	LightCream_80: '#dedbd4',
	LightCream_60: '#EAE8E1',
	LightCream_40: '#F5F3ED',
	LightCream_20: '#FBFAF8',
	LightCream_10: '#FFFEFD',
	Green: '#438d95',
	Black: '#000000',
	Orange: '#e06849',
	DarkPepper_20: '#9CA4A4',
	DarkPepper_80: '#131C1C',
	DarkPepper_60: '#2F3838',
	DarkPepper_40: '#4C5555',
	White: 'white',
	Grey: 'grey',
	Red: 'red',
	LightGrey: 'lightgrey',
	LighterGrey: '	#d3d3d38f',
	SteelBlue: 'cadetblue',
	Transparent: ' rgba(255, 255, 255, 0.5)',
	Flambe: '#E06749'
}
