import React, {useState} from 'react'
import styled from 'styled-components'
import {Container, Row, media} from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import {Modal} from 'react-responsive-modal'
import {FiMail} from 'react-icons/fi'
import {AiOutlineCopy} from 'react-icons/ai'
import {color} from '../../assets/css/Color.js'
import {useSelector} from 'react-redux'
import {BsCheckCircle} from 'react-icons/bs'

const InviteModal = (props) => {
	const _userData = useSelector((state) => state.userData)
	const [popup, setPopup] = useState(false)
	const [copyText, setCopyText] = useState(false)
	const togglePopup = () => {
		setTimeout(setValue, 800)
	}
	const setValue = () => {
		setPopup(false)
	}
	let mailText = `Join me as ${_userData?.first_name} on the bōzt App. Download the app and add me to your Circle to see my restaurant recommendations and list of favorite places to get great food. https://boztapp.com/`

	return (
		<Container>
			<Row>
				<Modal
					open={true}
					onClose={() => {
						props.openModal(false)
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal'
					}}>
					<Flexed direction="column" justify="center" align="center" width="20rem">
						<span style={{fontSize: '1.5rem', fontWeight: '500', padding: '0.5rem'}}>Invite others</span>
						<span style={{padding: '0.5rem', marginBottom: '0.5rem'}}>Bōzt is more fun with friends</span>
						<HorizontalRow />
						<MailLink
							onClick={() => {
								window.open(`mailto:Enter Email Here?subject= Please join me on the bōzt App &body=${mailText}`)
							}}>
							<IconContainer>
								<FiMail />
							</IconContainer>
							Invite via Email
						</MailLink>
						<HorizontalRow />
						<Listview
							color={copyText === true && color.Green}
							onClick={() => {
								setCopyText(true)
								navigator.clipboard.writeText(
									`I’m on the bōzt App as ${_userData?.first_name}. Download the app and add me to your Circle to see my restaurant recommendations and list of favorite places to get great food. https://boztapp.com/.`
								)
								setPopup(true)
								togglePopup()
							}}>
							<IconContainer>{copyText === false ? <AiOutlineCopy /> : <BsCheckCircle color={color.Green} />}</IconContainer>
							Copy link
						</Listview>
						{popup && (
							<Popup>
								<AiOutlineCopy />
								Copied to clipboard
							</Popup>
						)}
						<HorizontalRow />
					</Flexed>
				</Modal>
			</Row>
		</Container>
	)
}

const Flexed = styled.div`
	position: relative;
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	width: ${({width}) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({width}) => `${width}`};
  `}
`

const MailLink = styled.a`
	position: relative;
	text-decoration: none;
	padding: 1rem;
	width: 100%;
	color: ${({color}) => `${color}`};
	cursor: pointer;
	display: flex;
	justify-content: start;
	align-items: center;
	box-shadow: 0rem 0rem 0.5rem ${color.LightGrey};
`

const Listview = styled.li`
	position: relative;
	list-style: none;
	padding: 1rem;
	width: 100%;
	color: ${({color}) => `${color}`};
	cursor: pointer;
	display: flex;
	justify-content: start;
	align-items: center;
	box-shadow: 0rem 0rem 0.5rem ${color.LightGrey};
`

const HorizontalRow = styled.div`
	margin-top: 0.6rem;
	width: 100%;
	border: none;
`
const IconContainer = styled.div`
	padding: 0rem 0.5rem;
	display: flex;
	align-items: center;
`

const Popup = styled(Listview)`
	position: absolute;
	width: 15rem;
	border-radius: 0.5rem;
	background-color: ${color.Grey};
	top: 7rem;
	color: ${color.White};
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`

export default InviteModal
