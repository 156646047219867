import React, {useState, useEffect, Suspense} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import Button from './common/Button.js'
import PageHeader from './common/PageHeader.js'
import EmptyMessage from './common/EmptyMessage.js'
import {doGetBookmarks} from './api/apis.js'
const FeedCard = React.lazy(() => import('./FeedCard.js'))

const Bookmarks = () => {
	const [loading, setLoading] = useState(false)
	const [showMore, setShowMore] = useState(false)
	const [bookmarks, setBookmarks] = useState([])
	const [nextPage, setNextPage] = useState(1)
	useEffect(() => {
		getBookmarks(1, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getBookmarks = async (nextPageToken = 1, loading = false) => {
		await doGetBookmarks(nextPageToken, loading, setLoading, setShowMore, bookmarks, setBookmarks)
	}

	const getNextPage = () => {
		setNextPage(nextPage + 1)
		getBookmarks(nextPage + 1)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader title={'Bookmarks'} padding={'0'} />
					</Col>
				</CustomRow>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						{bookmarks?.length > 0 ? (
							bookmarks?.map((item, index) => {
								return (
									<Suspense key={index.toString()} fallback={'Loading...'}>
										<FeedCard feedData={item} hide={true} setFetchAgain={(e) => e === true && getBookmarks(1, false)} />
									</Suspense>
								)
							})
						) : loading ? (
							''
						) : (
							<EmptyMessage message={'No booked mark found'}></EmptyMessage>
						)}
						{showMore && (
							<Button
								width={'100%'}
								margin={'0.5rem 0'}
								color={color.White}
								bgtype={color.Orange}
								fontSize={'1rem'}
								height={'3.125rem'}
								label={'Load More'}
								onClick={() => {
									getNextPage()
								}}
							/>
						)}
					</Col>
				</CustomRow>
				{loading && <Loader visible={loading} text={'Loading...'} />}
			</Wrapper>
		</>
	)
}
const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`
const CustomRow = styled(Row)`
	justify-content: center;
`

export default Bookmarks
