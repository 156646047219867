/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import styled from 'styled-components'
import { color } from '../../assets/css/Color.js'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { BsChevronRight, BsSearch } from 'react-icons/bs'
import PageHeader from '../common/PageHeader.js'
import Input from '../common/Input.js'
import RestaurantsList from '../common/RestaurantsList.js'
import { doFetchRestaurantData, doGetllFiltersData, doSearchRestaurant } from '../api/apis.js'

const MapFilterModal = ({ nearBozt, setIsOpen, filterDataCB, myFavRest, myRest, userLat, userLng }) => {
	const _token = localStorage.getItem('token')
	const [favoritesData, setFavoritesData] = useState([])
	const [myBoztData, setMyBoztData] = useState([])
	const [circleBoztData, setCircleBoztData] = useState([])
	const [communityData, setCummunityData] = useState([])
	const [taste, setTastes] = useState([])
	const [search, setSearch] = useState(false)
	const [searchData, setSearchData] = useState([])
	const [errorMessage, setErrorMessage] = useState('')

	let _resturants = nearBozt
	let _myfavRest = myFavRest
	let _myRest = myRest

	useEffect(() => {
		getData()
		fetchRestaurantData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getData = async () => {
		await doGetllFiltersData(doFetchTaste)
	}

	const doFetchTaste = async (data) => {
		let _filteredTaste = []
		data?.map((taste) => {
			if (taste.isFilter === true) {
				let _item = { id: taste.id, name: taste.name, icon: taste.icon, isFilter: taste.isFilter, createdAt: taste.createdAt, updatedAt: taste.updatedAt }
				_filteredTaste.push(_item)
			}
			return taste
		})

		_filteredTaste = _filteredTaste.sort((a, b) => {
			let x = a.name.toLowerCase()
			let y = b.name.toLowerCase()
			if (x < y) {
				return -1
			}
			if (x > y) {
				return 1
			}
			return 0
		})
		await filterCategories(_resturants, _filteredTaste)
	}

	const filterCategories = async (data, fileterData) => {
		let _item = []
		for (let j = 0; j < fileterData.length; j++) {
			fileterData[j].count = 0
			for (let i = 0; i < data.length; i++) {
				_item = data[i].categories
				let value = JSON.stringify(_item).includes(fileterData[j].name)
				if (value) {
					fileterData[j].count++
				}
			}
		}

		setTastes(fileterData)
	}

	const fetchRestaurantData = async () => {
		await doFetchRestaurantData(_resturants, _myRest, _myfavRest, setFavoritesData, setMyBoztData, setCircleBoztData, setCummunityData)
	}

	const handleChange = async (data) => {
		if (data?.length > 0) {
			setErrorMessage('Minimum 2 characters required')
			setSearch(true)
			if (data?.length >= 2) {
				setErrorMessage('')
				await doSearch(data)
			} else if (data?.length < 2) {
				setSearchData([])
			}
		} else setSearch(false)
	}

	const doSearch = async (searchByName) => {
		await doSearchRestaurant(searchByName, _resturants, setSearchData)
	}

	const selectCatagoryData = async (catagory) => {
		const data = _resturants
		let _item = []
		let _filterData = []
		for (let i = 0; i < data.length; i++) {
			_item = data[i].categories
			let value = JSON.stringify(_item).includes(catagory)
			if (value) {
				_filterData.push(data[i])
			}
		}
		filterDataCB(_filterData, false)
	}

	const onSelectHandler = (item) => {
		let searchedItem = []
		searchedItem.push(item)
		filterDataCB(searchedItem, false, '')
		setIsOpen(false)
	}

	return (
		<Wrapper>
			<CustomRow>
				<Modal
					open={true}
					onClose={() => {
						setIsOpen(false)
						filterDataCB([], false)
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal'
					}}>
					<CustomCol xs={12} sm={12} md={12} lg={12}>
						<PageHeader title={'Map Filters'} setIsOpen={setIsOpen} filterDataCB={filterDataCB} padding={'0'} />
						<InnerWrapper>
							<SearchIcon />
							<Input
								width={'100%'}
								borderRadius={'0.2rem'}
								margin={'0'}
								padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
								onChange={(event) => {
									handleChange(event.target.value)
								}}
								placeholder="Category, Taste, or Name"
							/>
						</InnerWrapper>
						<Error>{errorMessage}</Error>
						{search === false ? (
							<>
								<DishesWrapper>
									{taste?.map((data) => {
										return (
											<span key={Math.random()}>
												<MainDiv
													onClick={async () => {
														if (data?.count > 0) {
															selectCatagoryData(data.name)
															setIsOpen(false)
														}
													}}>
													<Icon src={data.icon} />
													<Content>{data.name}</Content>
													<Content>{data.count}</Content>
												</MainDiv>
											</span>
										)
									})}
								</DishesWrapper>
								<HorizontalRow />
								<ContentWrapper
									onClick={async () => {
										if (favoritesData?.length > 0) {
											filterDataCB(favoritesData, false, 'favorite')
											setIsOpen(false)
										}
									}}>
									<TitleWrapper>
										<Option>My Favorite</Option>
										<Content>{favoritesData?.length} items</Content>
									</TitleWrapper>
									<BsChevronRight size={'1rem'} />
								</ContentWrapper>
								<HorizontalRow />
								<ContentWrapper
									onClick={async () => {
										if (myBoztData?.length > 0) {
											filterDataCB(myBoztData, false, 'bozt')
											setIsOpen(false)
										}
									}}>
									<TitleWrapper>
										<Option>My bōzts</Option>
										<Content>{myBoztData?.length} items</Content>
									</TitleWrapper>
									<BsChevronRight size={'1rem'} />
								</ContentWrapper>
								<HorizontalRow />
								<ContentWrapper
									onClick={async () => {
										if (circleBoztData?.length > 0) {
											filterDataCB(circleBoztData, false, 'myCircle')
											setIsOpen(false)
										}
									}}>
									<TitleWrapper>
										<Option>My Circle</Option>
										<Content>{circleBoztData?.length} items</Content>
									</TitleWrapper>
									<BsChevronRight size={'1rem'} />
								</ContentWrapper>
								<HorizontalRow />
								<ContentWrapper
									onClick={async () => {
										if (communityData?.length > 0) {
											filterDataCB(communityData, false)
											setIsOpen(false)
										}
									}}>
									<TitleWrapper>
										<Option>Community</Option>
										<Content> {communityData?.length} items</Content>
									</TitleWrapper>
									<BsChevronRight size={'1rem'} />
								</ContentWrapper>
							</>
						) : (
							<>
								<CenterRow>
									<Text>Restaurants Nearby </Text>
									<MapViewTxt
										onClick={() => {
											setIsOpen(false)
											// console.log('searchData', searchData)
											filterDataCB(searchData, false)
										}}>
										Map view
									</MapViewTxt>
								</CenterRow>
								{searchData?.map((item, index) => {
									return <RestaurantsList restaurantsList={item} key={index} addFavourite={(e) => onSelectHandler(e)} parent={'map'} />
								})}
							</>
						)}
					</CustomCol>
				</Modal>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const CustomCol = styled(Col)`
	width: 50rem;
	min-height: 40rem;
`

const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1rem 0rem 0rem 0rem;
`

const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 6.2rem;
	z-index: 1;
	left: 0.9rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`

const SearcInput = styled.input`
	width: 100%;
	padding: 0.8rem 0.8rem 0.8rem 2rem;
	font-size: 1rem;
	position: relative;
	border-radius: 0.2rem;
	// margin-bottom: 1.5rem;
	outline: none;
	border: 0.063rem solid ${color.LightGrey};
`
const DishesWrapper = styled.div`
	width: 100%;
	min-width: 100%;
	overflow-y: hidden;
	overflow-x: scroll;
	white-space: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
	position: relative;
	-webkit-transform: translateZ(0);
	display: flex;
`
const MainDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${color.White};
	padding: 1.2rem;
	border: 0.063rem solid ${color.LightGrey};
	border-radius: 0.3rem;
	margin: 0.3rem 0.5rem 1.5rem 0rem;
	width: 9rem;
	cursor: pointer;
`
const HorizontalRow = styled.div`
	border-top: 0.063rem solid ${color.LightCream_80};
	margin-top: 0.063rem;
	width: 100%;
`

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${color.White};
	cursor: pointer;
	padding: 0.8rem;
`

const Option = styled.p`
	margin: 0;
	padding: 0;
	font-size: 1rem;
	font-family: sans-serif;
	font-weight: 500;
	color: ${color.DarkPepper_60};
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
const Icon = styled.div`
	background: ${({ src }) => (src ? `url(${process.env.REACT_APP_IMAGE_URL + src}) no-repeat` : ``)};
	background-size: contain;
	height: 2rem;
	background-position: center;
	width: 100%;
`
const Error = styled.span`
	margin-left: 1rem;
	color: ${color.Red};
	font-size: 0.8rem;
`
const Text = styled.p`
	color: ${color.Grey};
	margin: 0.75rem 0;
`
export const InnerContentWrapper = styled.div`
	width: 100%;
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${color.White};
	cursor: pointer;
	height: auto;
	border-bottom: 0.063rem solid ${color.LightGrey};
	${media.xs`
	margin:0;
		padding:0.1rem;
		`}
	${media.sm`
	margin:0;
	padding:0.1rem;
	`}
	${media.md`
	display: flex !important;
	align-items:center;
	justify-content: space-between;

		margin:0;
		padding:0.2rem;
		`}
`

export const LeftDiv = styled.div`
	margin-left: 0.5rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	${media.xs`
	height: 3rem;
	margin-left: 0.1rem;
	padding: 0.25rem;
		`}
	${media.sm`
	height: 3rem;
	margin-left: 0.1rem;
	padding: 0.25rem;
		`}
	${media.md`
	margin-left: 0.5rem;
	padding: 0.25rem;
		`}
`

export const ResText = styled.p`
	margin: 0rem;
`
export const Title = styled(ResText)`
	font-family: sans-serif;
	font-weight: 600;
`
export const VerticleRow = styled.div`
	border-left: 0.063rem solid ${color.LightGrey};
	margin-top: 1rem;
	margin-bottom: 1rem;
	${media.xs`
	margin-top: 1rem;
	margin-bottom: 1rem;
	height:2rem;
		`}
	${media.sm`
	margin-top: 1rem;
	margin-bottom: 1rem;
	height:2rem;
		`}
  	${media.md`
	  margin-top: 1rem;
	  margin-bottom: 1rem;
		height:2.5rem;
			`}
`
export const TextDiv = styled.div`
	margin-left: 0rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	width: 100%;
	${media.xs`
		order:${({ order }) => `${order ? order : '3'}`};
		height: 3rem;
		padding: 0.1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0.25rem;
		`}
	${media.sm`
		order: ${({ order }) => `${order ? order : '3'}`};
		height: 3rem;
		padding: 0.1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0.25rem;
		`}
		${media.md`
		order:2;
	  padding: 0.75rem;
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  padding: 0.25rem;
	  `}
  	${media.lg`
	  	order:2;
		padding: 0.75rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: ${({ padding }) => `${padding ? padding : '0.25rem'}`};;
		`}
`

export const Button = styled.button`
	min-width: 7rem;
	margin: 0.8rem;
	max-height: 3rem;
	font-size: 1rem;
	background-color: ${color.White};
	border-radius: 2rem;
	color: ${color.SteelBlue};
	font-weight: bold;
	border: none;
	box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.1);
	cursor: pointer;
	${media.xs`
	margin: 0.2rem;
		order:2;
		`}
	${media.sm`
	margin: 0.2rem;
		order:2;
		`}
  	${media.md`
	  margin: 0.2rem;
	  order:3;
	  	`}
`

const CenterRow = styled(Row)`
	display: flex;
	margin: 0 0.2rem;
	justify-content: space-between;
`

export const RightWrapper = styled.div`
	display: contents;
`

const MapViewTxt = styled.p`
	text-transform: uppercase;
	cursor: pointer;
	color: ${color.Orange};
`
const Content = styled.span`
	color: ${color.DarkPepper_60};
`
export default MapFilterModal
