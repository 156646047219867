import React from 'react'
import styled from 'styled-components'
import { Container, Row, media } from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { color } from '../../assets/css/Color.js'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
const ReportModal = (props) => {
	const _own_id = useSelector((state) => state?.userData?.id)
	const [searchParams] = useSearchParams()
	let _u_id = searchParams.get('id')

	return (
		<Container>
			<Row>
				<Modal
					open={true}
					onClose={() => {
						props.cancelCB()
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'reportModal'
					}}>
					<Flexed direction="column" justify="center" align="center" width="20rem">
						{_own_id == _u_id || _own_id == props?.data?.user?.id ? (
							''
						) : (
							<ListView
								color="black"
								onClick={() => {
									props.reportOpenCB()
								}}>
								Report
							</ListView>
						)}
						<ListView
							color="black"
							onClick={() => {
								props.likenavigationHandlerCB()
							}}>
							Likes
						</ListView>

						<ListView
							color="black"
							onClick={() => {
								props.commentsHandlerCB()
							}}>
							Comment
						</ListView>
						<ListView onClick={() => props.cancelCB()}>Cancel</ListView>
					</Flexed>
				</Modal>
			</Row>
		</Container>
	)
}

const Flexed = styled.div`
	position: relative;
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin: ${({ margin }) => `${margin}`};
	width: ${({ width }) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({ width }) => `${width}`};
  `}
`
const ListView = styled.li`
	list-style: none;
	padding: 0.5rem;
	color: ${({ color }) => `${color}`};
	font-weight: ${({ weight }) => `${weight}`};
	cursor: pointer;
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	background-color: ${color.LighterGrey};
	margin-bottom: 1rem;
`

export default ReportModal
