/* eslint-disable no-loop-func */
import axios from 'axios'
import {api} from './callAxios'
import {toast} from 'react-toastify'
import {toastStyle} from '../../assets/css/CommonStyle'
import {getDistanceFromLatLonInMiles} from '../../discover/Miles'
import moment from 'moment'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export const doFetchCommunity = async (_page, dispatch, communityBozt, setHashMore, setCommunityBozt, setLoading) => {
	setLoading(true)
	await api
		.post(`/user/bozt/view_all_bozts`, {page: _page})
		.then((response) => {
			if (response.data.success === true) {
				let responseData = response.data.data.bozts.bozts
				dispatch({
					type: 'NOTIFICATIONS_COUNT',
					payload: response.data.data.bozts.notificationCount
				})

				setHashMore(responseData?.length == 0 ? false : responseData?.length < 10 ? false : true)

				if (_page > 1) {
					setCommunityBozt([...communityBozt, ...responseData])
				} else {
					setCommunityBozt(responseData)
				}

				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetSuggestedBozt = async (page, suggestedBozt, setSuggestedBozt, setSuggFetchingComplete) => {
	await api
		.get(`/user/suggested_bozters?pageNumber=${page}`)
		.then((response) => {
			if (response.data.success === true) {
				let responseData = response.data.data.suggestedBozters
				if (page > 1) {
					responseData = suggestedBozt.concat(responseData)
				}
				setSuggestedBozt(responseData)
				setSuggFetchingComplete(true)
			} else {
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doGetMyCircleBozts = async (nextPage, setLoading, dispatch, circleBozt, setHashMore, setCircleBozt) => {
	setLoading(true)
	await api
		.post('user/bozt/my_circle_bozts', {page: nextPage})
		.then((res) => {
			if (res.data.success === true) {
				let resData = res.data.data.my_cicle_bozts.bozts
				dispatch({
					type: 'NOTIFICATIONS_COUNT',
					payload: res.data.data.my_cicle_bozts.notificationCount
				})

				setHashMore(res.data.data.my_cicle_bozts.bozts?.length == 0 ? false : res.data.data.my_cicle_bozts.bozts?.length < 10 ? false : true)

				if (nextPage == 1) {
					setCircleBozt(resData)
				} else {
					setCircleBozt([...circleBozt, ...resData])
				}

				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', res.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetCurrentAddress = async (lat, lng, setSpinner, setLocation) => {
	await axios
		.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
		.then((response) => {
			setSpinner(false)
			setLocation(response.data.results[6].formatted_address)
		})
		.catch((error) => {
			setSpinner(false)
			setLocation('')
		})
}

export const doFetchUserLikesList = async (nextPage, setLoading, _id, setShowLoadMore, likedUsersList, setLikedUsersList) => {
	setLoading(true)
	await api
		.post(`/user/bozt/bozt_likes`, {b_id: _id, page: nextPage})
		.then((response) => {
			if (response.data.success === true) {
				let usersList = response.data.data.data
				setShowLoadMore(usersList.length >= 15)
				if (nextPage > 1) {
					usersList = likedUsersList.concat(usersList)
				}
				setLikedUsersList(usersList)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doFollowUnfollowUser = async (f_id, follower_status, setLoading, likedUsersList, setLikedUsersList) => {
	setLoading(true)
	const url = follower_status ? 'un_follow' : 'follow'
	try {
		await api
			.post(`/user/${url}`, {
				f_id: f_id
			})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					if (follower_status) {
						const _following = likedUsersList.map((item) => {
							if (item.id == f_id) {
								item.is_follower = false
							}
							return item
						})
						setLikedUsersList(_following)
					} else {
						const _following = likedUsersList.map((item) => {
							if (item.id == f_id) {
								item.is_follower = true
							}
							return item
						})
						setLikedUsersList(_following)
					}
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				} else {
					setLoading(false)
					toast.error(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch((error) => {
				setLoading(false)
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doFetchOtherUserBadges = async (setLoading, u_id, userBadgesHandlerCB) => {
	setLoading(true)
	try {
		await api.post(`/user/badges`, {u_id: u_id}).then(async (response) => {
			if (response.data.success === true) {
				let _allBadges = response.data.data.allBadges
				let _userBadges = response.data.data.userBadges
				await userBadgesHandlerCB(_allBadges, _userBadges)
			} else {
				console.log({type: 'error', description: response.data.message})
			}
		})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doFetchUserOwnBadges = async (setLoading, ownbBadgesHandlerCB) => {
	setLoading(true)
	await api
		.get(`/user/get_all_badges`)
		.then(async (response) => {
			if (response.data.success === true) {
				let _allBadges = response.data.data.badges.totalBadges
				let _myBadges = response.data.data.badges.myEarnBadges
				await ownbBadgesHandlerCB(_allBadges, _myBadges)
			} else {
				setLoading(false)
			}
		})
		.catch(function (error) {
			console.log(error)
			setLoading(false)
		})
}

export const doAddFavoriteRestaurant = async (item, setLoading, listData, setListData) => {
	let _address = item?.address
	// item?.address?.length > 0 ? item?.address?.map((i) => (_address += i)) : (_address = item.address)

	const data = {
		title: item?.r_title,
		lng: item?.lng,
		lat: item?.lat,
		address: _address,
		r_id: item.r_id
	}
	try {
		setLoading(true)
		await api
			.post(`/user/restaurant/add_restaurant`, data)
			.then((response) => {
				if (response?.data?.success) {
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					let _listData = listData?.filter((i) => i.r_id !== item.r_id)
					setListData(_listData)
					setLoading(false)
				} else {
					toast.error(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setLoading(false)
				}
			})
			.catch((err) => {
				setLoading(false)
				toast.error(err.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doFetchList = async (pageNum = 0, lat, lng, searchByName, setLoading, listData, setListData, setShowMore, setCopyListData, _latLng, setPageToken, pageToken, setLat, setLng, isMobile) => {
	try {
		setLoading(true)
		if (searchByName?.length >= 2) {
			let querry = searchByName.toLowerCase()
			await api
				.post(`/user/search_yulp`, {
					name: querry,
					lat: lat,
					lng: lng,
					isUser: true
				})
				.then(async (response) => {
					if (response.data.success === true) {
						let resturants = response.data.data.discoverApi
						if (isMobile) {
							if (response.data.data?.query.toLowerCase() == querry) {
								resturants = response.data.data.discoverApi
							}
						} else {
							resturants = response.data.data.discoverApi
						}

						for (let i = 0; i < resturants.length; i++) {
							const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), lat, lng)
							resturants[i].distance = distance
						}
						const _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})
						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants.sort((a, b) => {
								const nameA = a.r_title.toLowerCase()
								const nameB = b.r_title.toLowerCase()
								if (nameA.startsWith(querry.trimEnd()) && !nameB.startsWith(querry.trimEnd())) {
									return -1 // a comes before b
								} else if (!nameA.startsWith(querry.trimEnd()) && nameB.startsWith(querry.trimEnd())) {
									return 1 // b comes before a
								} else {
									return 0 // no preference, maintain order
								}
							})
						} else {
							_resturants.sort(function (a, b) {
								if (a.distance < b.distance) {
									return -1
								}
								if (a.distance > b.distance) {
									return 1
								}
								return 0
							})
						}

						setListData(_resturants)
						setLoading(false)
					} else {
						console.log({type: 'error', description: response.data.message})
					}
				})
				.catch((error) => {
					setLoading(false)
					console.log({type: 'error', description: error.message})
				})
		} else {
			await api
				.post(`/user/bozt/near_me_bozts`, {
					page: pageNum,
					lat2: lat,
					lng2: lng,
					isUser: true,
					pageToken: pageToken
				})
				.then(async (response) => {
					if (response.data.success === true) {
						let resturants = response.data.data.discoverApi.discoverApi
						setShowMore(resturants.length >= 20)
						if (response.data.data.discoverApi?.pageToken == undefined && process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							setLat(lat + 1)
							setLng(lng + 1)
						}
						setPageToken(response.data.data.discoverApi?.pageToken ? response.data.data.discoverApi?.pageToken : null)
						for (let i = 0; i < resturants.length; i++) {
							const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), _latLng?.latitude ? _latLng?.latitude : 0, _latLng?.longitude ? _latLng?.longitude : 0)
							resturants[i].distance = distance
						}
						if (pageNum > 0) {
							resturants = listData.concat(resturants)
						}
						resturants.sort(function (a, b) {
							return a.distance - b.distance
						})
						const _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						setListData(uniqueMyFavRestaurant)
						setCopyListData(uniqueMyFavRestaurant)
						setLoading(false)
					} else {
						console.log({type: 'error', description: response.data.message})
					}
				})
				.catch((error) => {
					setLoading(false)
					console.log({type: 'error', description: error.message})
				})
		}
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doGetCurrentLatLng = async (setLoading, setLocation, setLat, setLng, fetchList, setTmpLat, setTmpLng) => {
	setLoading(true)
	navigator.geolocation.getCurrentPosition(
		async (position) => {
			await doGetCurrentAddress(position.coords.latitude, position.coords.longitude, setLoading, setLocation)
			setLat(position.coords.latitude)
			setLng(position.coords.longitude)
			setTmpLat(position.coords.latitude)
			setTmpLng(position.coords.longitude)
			await fetchList(0, position.coords.latitude, position.coords.longitude, position.coords.latitude, position.coords.longitude)
			setLoading(false)
		},
		function (error) {
			// eslint-disable-next-line eqeqeq
			console.log('Location Permission Denied.', error)
			if (error.code == error.PERMISSION_DENIED) {
				setLoading(false)
			} else {
				setLoading(false)
			}
		},
		{timeout: 5000, enableHighAccuracy: true}
	)
}

export const doGetBookmarks = async (nextPageToken, loading = false, setLoading, setShowMore, bookmarks, setBookmarks) => {
	setLoading(loading)
	await api
		.post(`/user/bozt/view_all_saved_bozts`, {page: nextPageToken})
		.then((response) => {
			setLoading(false)
			if (response.data.success === true) {
				let savedBozt = response.data.data.all_saved_bozts.savedBozts
				savedBozt.sort(function (a, b) {
					return b.id - a.id
				})

				setShowMore(savedBozt.length >= 14)
				if (nextPageToken > 1) {
					savedBozt = bookmarks.concat(savedBozt)
				}

				setBookmarks(savedBozt)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doFetchAllBoztsList = async (nextPage, setLoading, props, setShowMore, boztsList, setBoztsList) => {
	setLoading(true)
	await api
		.post(`/user/bozt/restaurant_Bozts`, {r_id: props?.r_id, page: nextPage})
		.then((response) => {
			if (response.data.success === true) {
				let bozts = response.data.data.data
				bozts.sort(function (a, b) {
					return b.id - a.id
				})
				setShowMore(bozts.length >= 15)
				if (nextPage > 1) {
					bozts = boztsList.concat(bozts)
				}
				setBoztsList(bozts)
				setLoading(false)
			} else {
				setLoading(false)
				console.log(response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doFetchCircleUsers = async (setLoading, setData, setShowMore, page, data) => {
	setLoading(true)
	await api
		.post(`/user/my_circle`, {page: page})
		.then((response) => {
			if (response.data.success === true) {
				let responseData = response.data.data.myCircle
				setShowMore(response.data.data.myCircle.length >= 15)
				if (page > 1) {
					responseData = data.concat(responseData)
				}
				setData(responseData)
				// setData(response.data.data.myCircle)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
			setLoading(false)
		})
}

export const doFetchFollowers = async (setLoading, nextPage, userData, setShowMore, data, setData) => {
	setLoading(true)
	await api
		.get(`/user/get_user_follower_data?page=${nextPage}&u_id=${userData.id}&is_new=true`)
		.then((response) => {
			if (response.data.success === true) {
				let followersData = response.data.data.data.followBack
				setShowMore(followersData.length >= 15)
				if (nextPage > 1) {
					followersData = data.concat(followersData)
				}
				setData(followersData)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetSuggestedBoztOnCircle = async (nextPage, setLoading, setShowMore, setData, data, setCopyData) => {
	setLoading(true)
	await api
		.get(`/user/suggested_bozters?pageNumber=${nextPage}`)
		.then((response) => {
			if (response.data.success === true) {
				let _similarTastesUser = response.data.data.suggestedBozters
				setShowMore(_similarTastesUser.length >= 15)
				if (nextPage > 1) {
					_similarTastesUser = data.concat(_similarTastesUser)
				}
				setData(_similarTastesUser)
				setCopyData(_similarTastesUser)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doSearchBozter = async (text, setData) => {
	await api
		.post(`/user/search_by_name`, {
			name: text,
			chat: false
		})
		.then((response) => {
			if (response.data.success === true) {
				if (response.data.data?.query == text) {
					setData(response.data.data.search_by_name)
				}
			} else {
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doFollowUnfollowCBOnCircle = async (f_id, follower_status, setLoading, tabActive, data, setData, copyData, setCopyData, setCongrate) => {
	setLoading(true)
	const url = follower_status ? 'un_follow' : 'follow'
	try {
		await api
			.post(`/user/${url}`, {
				f_id: f_id
			})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					if (tabActive == 1) {
						const _following = data.filter((item) => {
							return item.id != f_id
						})
						setData(_following)
					} else {
						if (tabActive == 0) {
							let _suggestedBozters = data.map((contact) => {
								if (contact.id === f_id) {
									contact.is_follower = !follower_status
								}
								return contact
							})
							let _suggestedBoztersCopy = copyData.map((contact) => {
								if (contact.id === f_id) {
									contact.is_follower = !follower_status
								}
								return contact
							})
							setData(_suggestedBozters)
							setCopyData(_suggestedBoztersCopy)

							if (url == 'follow' && response?.data?.data?.data?.popUp?.length > 0) {
								// Congrats message!
								setCongrate(true)
							}
						}
						if (tabActive == 2) {
							const _followers = data.filter((item) => {
								if (item.id == f_id) {
									item.is_follower = url === 'follow' ? true : false
								}
								return item
							})
							setData(_followers)
						}
					}
				} else {
					setLoading(false)

					toast.error(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch((error) => {
				setLoading(false)
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doFetchDesignations = async (setLoading, userData, setDesignation) => {
	setLoading(true)
	await api
		.get(`/admin/designation/view_all_designation`)
		.then((response) => {
			if (response.data.success === true) {
				let allDesignation = response.data.data.all_designations
				for (let i = 0; i < allDesignation.length; i++) {
					if (userData?.designation?.includes(allDesignation[i].name)) {
						allDesignation[i].isSelected = true
					} else {
						allDesignation[i].isSelected = false
					}
				}
				allDesignation = allDesignation.sort(function (a, b) {
					let x = a.name.toLowerCase()
					let y = b.name.toLowerCase()
					if (x < y) {
						return -1
					}
					if (x > y) {
						return 1
					}
					return 0
				})

				setDesignation([...allDesignation])
				setLoading(false)
			} else {
				setLoading(false)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetFavorites = async (nPage, setLoading, _latLng, _id, setShowMore, favorite, setFavorites) => {
	setLoading(true)
	await api
		.post(`/user/restaurant/view_all_favorite_restaurant`, {page: nPage, lat: _latLng?.latitude, lng: _latLng?.longitude, u_id: _id})
		.then(async (response) => {
			if (response.data.success === true) {
				let resturants = response.data.data.restaurant
				setShowMore(resturants.length >= 15)
				for (let i = 0; i < resturants.length; i++) {
					const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), _latLng?.latitude ? _latLng?.latitude : 0, _latLng?.longitude ? _latLng?.longitude : 0)
					resturants[i].distance = distance
				}
				if (nPage > 1) {
					resturants = favorite.concat(resturants)
				}

				setFavorites(resturants)
				setLoading(false)
			}
		})
		.catch(function (error) {
			console.log(error)
			setLoading(false)
		})
}

export const doRemoveFromFavourite = async (id, setLoading, favorite, setFavorites) => {
	// setLoading(true)
	await api
		.post(`/user/restaurant/delete_restaurant`, {r_id: id})
		.then(async (response) => {
			if (response.data.success === true) {
				const _resturantsData = favorite.filter((item) => {
					return item.r_id !== id
				})
				setFavorites(_resturantsData)
				toast.success(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setLoading(false)
			} else {
				setLoading(false)

				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doAddBozt = async (id, setLoading, setisLiked, setLikesCount) => {
	setLoading(true)
	try {
		await api
			.post(`/user/bozt/like_bozt`, {
				b_id: id
			})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setisLiked(true)
					setLikesCount(response.data.data.like_bozt.likeCount)
				} else {
					setLoading(false)
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((error) => {
				setLoading(false)
				console.log({type: 'error', description: error.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doRemoveLike = async (id, setLoading, setLikesCount, setisLiked) => {
	setLoading(true)
	try {
		await api
			.post(`/user/bozt/unlike_bozt`, {b_id: id})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setLikesCount(response.data.data.like_bozt.likeCount)
					setisLiked(false)
				} else {
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((err) => {
				console.log({type: 'error', description: err.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doBookmarkBozts = async (id, setLoading, setisSaved) => {
	// setLoading(true)
	try {
		await api
			.post(`/user/bozt/save_bozt`, {b_id: id})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setisSaved(true)
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				} else {
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((err) => {
				console.log({type: 'error', description: err.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doRemoveBookmark = async (id, setLoading, setisSaved) => {
	// setLoading(true)
	try {
		await api
			.post(`/user/bozt/unsave_bozt`, {b_id: id})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setisSaved(false)
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				} else {
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((err) => {
				console.log({type: 'error', description: err.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doFetchNewComments = async (id, feeddata, setCommentsCount) => {
	await api
		.post(`/user/bozt/bozt_comments`, {b_id: id ? id : feeddata?.id})
		.then(async (response) => {
			if (response.data.success === true) {
				setCommentsCount(response.data?.data?.data?.boztCommentsCount)
			}
		})
		.catch((error) => console.log(error))
}

export const doAddBoztComment = async (id, comment, setAddComment, setComment, setCommentsCount, commentscount) => {
	if (comment.length > 0) {
		setAddComment(true)
		const data = {b_id: id, comment: comment, page: 1, order: 'desc'}
		await api
			.post(`/user/bozt/add_bozt_comment`, data)
			.then(async (response) => {
				if (response.data.success === true) {
					setComment('')
					setCommentsCount(commentscount + 1)
					setAddComment(false)
				} else {
					console.log('error', response.data.message)
				}
			})
			.catch(function (error) {
				setAddComment(false)
				console.log(error)
			})
	}
}

export const doInvite = async (feedData) => {
	await api
		.post(`/user/share_bozt`, {b_id: feedData.id})
		.then((response) => {})
		.catch((error) => {
			console.log('Error: ', error.message)
		})
}

export const doHandleFollowUser = async (setLoading, feedData, setCongrate, setDoFilter, setFollowId) => {
	setLoading(true)
	await api
		.post(`/user/follow`, {
			f_id: feedData?.user?.id
		})
		.then((response) => {
			if (response?.data?.data?.data?.popUp?.length > 0) {
				setFollowId(feedData.user.id)
				setCongrate(true)
				setLoading(false)
			} else {
				setFollowId(feedData.user.id)
				setDoFilter(true)
				setLoading(false)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetNotification = async (dispatch, setNotificationData, setLoading) => {
	await api
		.get(`/user/notifications`)
		.then((response) => {
			dispatch({
				type: 'NOTIFICATIONS_COUNT',
				payload: 0
			})
			setNotificationData(response.data.data.notifications)
			setLoading(false)
		})
		.catch(function (error) {
			console.log(error)
			setLoading(false)
		})
}

export const doFetchBoztDetail = async (setLoading, id, setBoztData) => {
	setLoading(true)
	await api
		.post(`/user/bozt/detail`, {b_id: id})
		.then((response) => {
			setLoading(false)
			setBoztData(response.data?.data?.bozt)
		})
		.catch(function (error) {
			setLoading(false)
			console.log('error ' + error)
		})
}

export const doFetchNotificationSettings = async (setLoading, setData) => {
	setLoading(true)
	try {
		await api
			.get(`/user/all_notification`)
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setData(response.data.data.data)
				} else {
				}
			})
			.catch((err) => {
				setLoading(false)
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doUpdateNotification = async (setLoading, data, setData) => {
	setLoading(true)
	debugger
	try {
		await api
			.post(`/user/update_notification`, data)
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setData(response.data.data.data)
				} else {
				}
			})
			.catch((err) => {
				setLoading(false)
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doViewBozt = (id, setOpenRestDet, setBoztData, setOpenBoztModel) => {
	if (id.startsWith('R')) {
		api.post('/user/bozt/view_bozt', {id: id.replace('R', '')})
			.then((e) => {
				setOpenRestDet(true)
				setBoztData(e.data.data?.bozt)
			})
			.catch(() => {})
	} else {
		api.post('/user/bozt/view_bozt', {id: id}).then((e) => {
			setOpenBoztModel(true)
			setBoztData(e.data.data?.bozt)
		})
	}
}

export const doGetUserBozt = async (nextPage, loading = false, setLoading, props, _u_id, userBozt, setuserData, setFollowing, setShowMore, setUserBozt, setFollowers, setCopyData) => {
	setLoading(loading)
	await api
		.post(`/user/bozt/my_bozts`, {page: nextPage, u_id: props?.u_id ? props.u_id : _u_id})
		.then((response) => {
			if (response.data.success === true) {
				let userBoztsData = response.data.data.my_all_bozts.bozts
				setuserData(response.data.data.my_all_bozts)
				setFollowing(response.data.data.my_all_bozts.following)
				setShowMore(response.data.data.my_all_bozts.bozts?.length == 15 && userBoztsData.length >= 15)

				setFollowers(response.data.data.my_all_bozts.followerCount)

				setUserBozt((prevData) => [...prevData, ...userBoztsData])
				setCopyData((prevData) => [...prevData, ...userBoztsData])
				setLoading(false)
			} else {
				setLoading(false)
				toast.error(response.data.message)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doSearchBozt = async (text, setUserBozt, _u_id) => {
	let cancelToken = axios.CancelToken.source()

	let token = localStorage.getItem('token')
	const config = {
		headers: {authorization: `Bearer ${token}`},
		cancelToken: cancelToken.token
	}

	try {
		const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/bozt/search_Bozt`, {page: 1, u_id: _u_id, title: text}, config)

		if (response.data.success === true) {
			setUserBozt(response.data.data.data)
		} else {
			console.log('Error:', response.data.message)
		}
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log('Request canceled:', error.message)
		} else {
			console.error('Error:', error.message)
		}
		console.log(error)
	} finally {
		cancelToken = null
	}
}

export const doUnfollowBozter = async (_u_id, setFollowers, setFollowing, setLoading, followers) => {
	await api
		.post(`/user/un_follow`, {
			f_id: _u_id
		})
		.then(async (response) => {
			if (response.data.success === true) {
				setFollowers(followers - 1)
				setFollowing(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doUnfollowUser = async (id, setLoading, status) => {
	let res = []
	const url = status ? 'un_follow' : 'follow'
	await api
		.post(`/user/${url}`, {
			f_id: id
		})
		.then(async (response) => {
			if (response.data.success === true) {
				setLoading(false)
				res = true
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				res = false
			}
		})
		.catch(function (error) {
			console.log(error)
			res = false
		})
	return res
}

export const doFollowBozter = async (_u_id, setFollowers, setFollowing, setLoading, followers) => {
	await api
		.post(`/user/follow`, {
			f_id: _u_id
		})
		.then(async (response) => {
			if (response.data.success === true) {
				setFollowers(followers + 1)
				setFollowing(true)
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})

				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doGetDetails = async (_lat, _lng, setLoading, props, setFavorite, setResturantData, setData, setDistance, setIsClosedNow, setFavoriteRestaurants) => {
	try {
		let response = []
		setLoading(true)
		await api
			.post(`/user/bozt/restaurant_Detail`, {r_id: props?.selectedRestaurant == undefined ? props.r_id : props.selectedRestaurant?.r_id, fav_id: props?.id})
			.then(async (response) => {
				setFavorite(response.data?.data?.restaurant?.isFavorited)
				setResturantData(response.data?.data.restaurant)
				let dets = {}
				dets = response?.data?.data?.restaurant?.restaurantDetails

				setFavoriteRestaurants(response?.data?.data?.restaurant?.favoritedBy)
				if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false') {
					setData({
						title: dets?.name,
						lng: dets?.coordinates.longitude,
						lat: dets?.coordinates.latitude,
						address: dets?.location.display_address.length > 0 ? dets.location.display_address.toString() : '',
						r_id: props.r_id,
						open: dets?.hours ? 'Open till ' + moment(dets?.hours[0]?.open[0].end, 'HHmm').format('h:mm a') : '',
						price: dets?.price ? dets.price : ''
					})
					let _distance = await getDistanceFromLatLonInMiles(parseFloat(dets?.coordinates?.latitude), parseFloat(dets?.coordinates?.longitude), parseFloat(props.lat ? props.lat : _lat), parseFloat(props.lng ? props.lng : _lng))
					_distance = _distance.toFixed(2)
					if (_distance !== 'NaN') {
						setDistance(_distance + 'mi. ')
					}
					setIsClosedNow(response.data.data.restaurant.restaurantDetails.hours ? response.data.data.restaurant.restaurantDetails.hours[0].is_open_now : false)
				} else {
					setData({
						title: dets?.name,
						lng: dets?.geometry?.location?.lng,
						lat: dets?.geometry?.location?.lat,
						address: dets?.formatted_address ? dets?.formatted_address : '',
						r_id: props.r_id,
						open: dets?.opening_hours ? 'Open till ' + moment(dets?.opening_hours?.periods[0]?.open.time, 'HHmm').format('h:mm a') : '',
						price: dets?.price ? dets.price : ''
					})
					let _distance = await getDistanceFromLatLonInMiles(parseFloat(dets?.geometry?.location?.lat), parseFloat(dets?.geometry?.location?.lng), parseFloat(props.lat ? props.lat : _lat), parseFloat(props.lng ? props.lng : _lng))
					_distance = _distance.toFixed(2)
					if (_distance !== 'NaN') {
						setDistance(_distance + 'mi. ')
					}
					setIsClosedNow(response.data.data?.restaurant?.restaurantDetails?.opening_hours ? response?.data?.data?.restaurant?.restaurantDetails?.opening_hours?.open_now : false)
				}
				response = true
				setLoading(false)
			})
			.catch((err) => {
				response = false
				setLoading(false)
				console.log({type: 'error', description: err.message})
			})

		return response
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doGetBoztedByDetails = async (setLoading, props, setFavoriteRestaurants, setBoztedBy) => {
	try {
		setLoading(true)

		await api
			.post(`/user/bozt/restaurant_fav_Detail`, {r_id: props?.selectedRestaurant == undefined ? props.r_id : props.selectedRestaurant?.r_id, fav_id: props?.id})
			.then(async (response) => {
				setFavoriteRestaurants(response?.data?.data?.favoritedBy)
				setBoztedBy(response?.data?.data?.boztedBy)

				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				console.log({type: 'error', description: err.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doAddToWishlist = async (data, setLoading) => {
	try {
		await api
			.post(`/user/restaurant/add_to_wishlist`, data)
			.then((response) => {
				if (response.data.success === true) {
					toast.success(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					setLoading(false)
					return true
				} else {
					setLoading(false)
					toast.error(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
					return false
				}
			})
			.catch((err) => {
				setLoading(false)
				console.log({type: 'error', description: err.message})
				return false
			})
	} catch (error) {
		console.log({type: 'error', description: error.message})
	}
}

export const doFavoriteResturaunt = async (resturantData, props, setFavorite, setLoading, isFeed) => {
	const data = isFeed
		? {
				title: resturantData?.r_title,
				lng: resturantData?.lng,
				lat: resturantData?.lat,
				address: resturantData?.address,
				r_id: resturantData.r_id
		  }
		: process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false'
		? {
				title: resturantData.restaurantDetails?.name,
				lng: resturantData.restaurantDetails?.coordinates?.longitude,
				lat: resturantData.restaurantDetails?.coordinates?.latitude,
				address: resturantData?.restaurantDetails?.location?.display_address?.length > 0 ? resturantData.restaurantDetails.location.display_address.toString() : '',
				r_id: props.r_id
		  }
		: {
				title: resturantData.restaurantDetails?.name,
				lng: resturantData.restaurantDetails?.geometry?.location?.lng,
				lat: resturantData.restaurantDetails?.geometry?.location?.lat,
				address: resturantData?.restaurantDetails?.formatted_address ? resturantData.restaurantDetails.formatted_address.toString() : '',
				r_id: props.r_id
		  }

	try {
		let res = false
		await api
			.post(`/user/restaurant/add_restaurant`, data)
			.then((response) => {
				if (response?.data?.success) {
					if (props?.parent == 'discover') {
						props?.isFavorited({id: props.r_id, status: true})
					}

					setFavorite(true)
					setLoading(false)
					res = true
				} else if (!response?.data?.success) {
					toast.error(response?.data?.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch((err) => {
				setLoading(false)

				toast.error(err.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				res = true
			})
		return res
	} catch (error) {
		setLoading(false)
		toast.error(error.message, {
			position: toastStyle.position,
			autoClose: toastStyle.closeDuration
		})
	}
}

export const doRemoveFavoritesResturaunt = async (props, setFavorite, setLoading) => {
	try {
		let res = false
		await api
			.post(`/user/restaurant/delete_restaurant`, {
				r_id: props.r_id
			})
			.then((response) => {
				if (props?.parent == 'discover') {
					props?.isFavorited({id: props.r_id, status: false})
				}
				setFavorite(false)
				setLoading(false)
				res = true
			})
			.catch((error) => {
				setLoading(false)
				toast.error(error.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				res = false
			})
		return res
	} catch (error) {
		setLoading(false)
		toast.error(error.message, {
			position: toastStyle.position,
			autoClose: toastStyle.closeDuration
		})
	}
}

export const doFetchRestaurantsList = async (pageNum, lat, lng, uLat, uLng, searchByName, setLoading, setListData, setShowMore, listData, setCopyListData, setPageToken, pageToken, setLat, setLng, isMobile) => {
	try {
		if (searchByName?.length >= 2) {
			setLoading(true)
			let query = searchByName.toLowerCase()
			await api
				.post(`/user/search_yulp`, {
					name: query,
					lat: lat,
					lng: lng,
					isUser: true
					// pageToken: pageToken
				})
				.then(async (response) => {
					if (response.data.success === true) {
						setLoading(false)
						let resturants = []
						if (isMobile) {
							if (response.data.data?.query.toLowerCase() == query) {
								resturants = response.data.data.discoverApi
							}
						} else {
							resturants = response.data.data.discoverApi
						}

						// setPageToken(resturants?.pageToken)
						for (let i = 0; i < resturants.length; i++) {
							if (resturants[i].lng != null && resturants[i].lat != null) {
								const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), uLat, uLng)
								resturants[i].distance = distance
							}
						}

						const _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants.sort((a, b) => {
								const nameA = a.r_title.toLowerCase()
								const nameB = b.r_title.toLowerCase()
								if (nameA.startsWith(searchByName.trimEnd()) && !nameB.startsWith(searchByName.trimEnd())) {
									return -1 // a comes before b
								} else if (!nameA.startsWith(searchByName.trimEnd()) && nameB.startsWith(searchByName.trimEnd())) {
									return 1 // b comes before a
								} else {
									return 0 // no preference, maintain order
								}
							})
						} else {
							_resturants.sort(function (a, b) {
								if (a.distance < b.distance) {
									return -1
								}
								if (a.distance > b.distance) {
									return 1
								}
								return 0
							})
						}

						setListData(_resturants)

						setLoading(false)
					} else {
						setLoading(true)
						console.log({type: 'error', description: response.data.message})
					}
				})
				.catch((error) => {
					setLoading(false)
					console.log({type: 'error', description: error.message})
				})
		} else {
			setLoading(true)
			await api
				.post(`/user/bozt/near_me_bozts`, {
					page: pageNum,
					lat2: lat,
					lng2: lng,
					isUser: true,
					pageToken: pageToken
				})
				.then(async (response) => {
					if (response.data.success === true) {
						let resturants = response.data.data.discoverApi.discoverApi
						if (response.data.data.discoverApi?.pageToken == undefined && process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							setLat(lat + 1)
							setLng(lng + 1)
						}
						setPageToken(response.data.data.discoverApi?.pageToken ? response.data.data.discoverApi?.pageToken : null)
						setShowMore(process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true' ? resturants?.length >= 20 : resturants?.length >= 50)
						for (let i = 0; i < resturants.length; i++) {
							const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), uLat, uLng)
							resturants[i].distance = distance
						}

						if (pageNum > 0) {
							resturants = listData.concat(resturants)
						}

						const _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})
						_resturants.sort(function (a, b) {
							if (a.distance < b.distance) {
								return -1
							}
							if (a.distance > b.distance) {
								return 1
							}
							return 0
						})

						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)

						setListData(uniqueMyFavRestaurant)
						setCopyListData(uniqueMyFavRestaurant)
						setLoading(false)
					} else {
						setLoading(false)
						console.log({type: 'error', description: response.data.message})
					}
				})
				.catch((error) => {
					setLoading(false)
					console.log({type: 'error', description: error.message})
				})
		}
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doFetchUserTastes = async (setLoading, u_id, _selectedTastesIds) => {
	setLoading(true)
	await api
		.post(`/user/tastes`, {page: 1, u_id: u_id})
		.then((response) => {
			if (response.data.success === true) {
				let _allTastes = response.data.data.userTastes
				_allTastes.forEach((t) => {
					_selectedTastesIds.push(JSON.parse(t.id))
				})
				setLoading(false)
			} else {
				console.log({type: 'error', description: response.data.message})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doFetchTastes = async (setLoading, _selectedTastesIds, selectedTastesIds, setTasteList, setTasteListCopy) => {
	setLoading(true)
	await api
		.get(`/admin/taste/view_all_taste`)
		.then((response) => {
			setLoading(false)
			if (response.data.success === true) {
				let _allTastes = response.data.data.all_tastes
				if (_selectedTastesIds.length > 0) {
					for (let index = 0; index < _allTastes.length; index++) {
						for (let index1 = 0; index1 < _selectedTastesIds.length; index1++) {
							if (_allTastes[index].id === _selectedTastesIds[index1]) {
								selectedTastesIds.push(_selectedTastesIds[index1])
								_allTastes[index].isSelected = true
							}
						}
					}
				} else {
					_allTastes.map((taste) => {
						return (taste.isSelected = false)
					})
				}

				_allTastes = _allTastes.sort(function (a, b) {
					let x = a.name.toLowerCase()
					let y = b.name.toLowerCase()
					if (x < y) {
						return -1
					}
					if (x > y) {
						return 1
					}
					return 0
				})
				setTasteList(_allTastes)
				setTasteListCopy(_allTastes)
			} else {
				console.log({type: 'error', description: response.data.message})
			}
		})
		.catch((error) => {
			setLoading(false)

			console.log({type: 'error', description: error.message})
		})
}

export const doSetCircle = async (_u_id, setData, setLoading) => {
	await api
		.post(`/user/bozt/user_followings`, {page: 1, u_id: _u_id})
		.then((response) => {
			if (response.data.success === true) {
				setData(response.data.data.followingDetail)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetFavouriteBozt = async (nPage, setLoading, _latLng, setShowMore, favRest, setFavRest, _u_id) => {
	setLoading(true)
	await api
		.post(`/user/restaurant/view_all_favorite_restaurant`, {page: nPage, lat: _latLng?.latitude, lng: _latLng?.longitude, u_id: _u_id})
		.then((response) => {
			if (response.data.success === true) {
				let restaurants = response.data.data.restaurant
				setShowMore(restaurants.length >= 15)
				if (nPage > 1) {
					restaurants = favRest.concat(restaurants)
				}
				setFavRest(restaurants)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetFollowers = async (nextPage, setLoading, u_id, setShowMore, data, setData) => {
	setLoading(true)
	await api
		.get(`/user/get_user_follower_data?page=${nextPage}&u_id=${u_id}&is_new=true`)
		.then((response) => {
			if (response.data.success === true) {
				let folloerwsData = response.data.data.data.followBack
				setShowMore(folloerwsData.length >= 15)
				if (nextPage > 1) {
					folloerwsData = data.concat(folloerwsData)
				}
				setData(folloerwsData)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetAllUserTastes = async (setLoading, _u_id, setTasteList) => {
	setLoading(true)
	await api
		.post(`/user/tastes`, {page: 1, u_id: _u_id})
		.then((response) => {
			if (response.data.success === true) {
				setTasteList(response.data.data.userTastes)
				setLoading(false)
			} else {
				console.log({type: 'error', description: response.data.message})
				setLoading(false)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doGetWishlist = async (nextPage, setLoading, _latLng, setShowMore, wishlists, setWishlists) => {
	setLoading(true)
	await api
		.post(`/user/restaurant/view_all_wishlist_restaurant`, {page: nextPage})
		.then(async (response) => {
			if (response.data.success === true) {
				let resturants = response.data.data.restaurant
				for (let i = 0; i < resturants.length; i++) {
					const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i]?.lat), parseFloat(resturants[i]?.lng), _latLng?.latitude ? _latLng?.latitude : 0, _latLng?.longitude ? _latLng?.longitude : 0)
					resturants[i].distance = distance
				}
				resturants.sort(function (a, b) {
					if (a.distance < b.distance) {
						return -1
					}
					if (a.distance > b.distance) {
						return 1
					}
					return 0
				})
				setShowMore(resturants.length >= 15)
				if (nextPage > 1) {
					resturants = wishlists.concat(resturants)
				}
				setWishlists(resturants)
				setLoading(false)
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doRemoveFromWishlist = async (id, setLoading, getWishlist) => {
	setLoading(true)
	await api
		.post(`/user/restaurant/delete_from_wishlist`, {r_id: id})
		.then(async (response) => {
			if (response.data.success === true) {
				toast.success(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				await getWishlist()
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doFetchNearByResturant = async (nextPage, searchByName = '', _lat2, _lng2, _loading = false, setSpinner, setNextPageToken, filtterType, boztData, myFavRestData, myRestData, copyBoztData, clat, clng, setMyRestData, setMyFavRestData, setCopyBoztData, setBoztData, page, setChangeLocation, setPage) => {
	try {
		setSpinner(true)
		await api
			.post(`/user/bozt/near_me_bozts`, {
				page: page,
				lat2: _lat2,
				lng2: _lng2,
				isUser: true,
				pageToken: nextPage
			})
			.then(async (response) => {
				if (response.data.success === true) {
					setPage(page)
					let resturants = response.data.data.discoverApi.discoverApi
					let _myfavRest = response.data.data.discoverApi.myFavorite
					let myRest = response.data.data.discoverApi.mybozts
					setNextPageToken(response.data.data.discoverApi?.pageToken ? response.data.data.discoverApi?.pageToken : null)
					if (filtterType === 'favorite') {
						for (let i = 0; i < resturants.length; i++) {
							for (let j = 0; j < _myfavRest.length; j++) {
								if (resturants[i].r_id == _myfavRest[j].r_id) {
									boztData.push(resturants[i])
								}
							}
						}
						if (nextPage) {
							_myfavRest = myFavRestData.concat(_myfavRest)
							myRest = myRestData.concat(myRest)
							resturants = copyBoztData.concat(resturants)
						}

						for (let index = 0; index < resturants.length; index++) {
							let distance = await getDistanceFromLatLonInMiles(resturants[index].lat, resturants[index].lng, clat, clng)
							distance = distance.toFixed(2)
							resturants[index].distance = distance
						}
						resturants.sort(function (a, b) {
							return (a.distance === 'N/A') - (b.distance === 'N/A') || a.distance - b.distance
						})

						let _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants = resturants.filter((item) => {
								return item.billboard || item.is_awarded || item.is_favorite || item.is_bozted
							})
						}

						setMyRestData([...myRestData, ...myRest])

						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueMyRestaurant = Object.values(
							myRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueRestaurant = Object.values(
							_myfavRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)

						setCopyBoztData(uniqueMyFavRestaurant)
						setMyRestData(uniqueMyRestaurant)
						setMyFavRestData(uniqueRestaurant)
					} else if (filtterType === 'bozt') {
						for (let i = 0; i < resturants.length; i++) {
							for (let j = 0; j < myRest.length; j++) {
								if (resturants[i].r_id == myRest[j].r_id) {
									let _item = {
										address: resturants[i].address,
										categories: resturants[i].categories,
										distance: resturants[i].distance,
										is_bozted: resturants[i].is_bozted,
										is_favorite: false,
										lat: resturants[i].lat,
										lng: resturants[i].lng,
										r_id: resturants[i].r_id,
										r_title: resturants[i].r_title,
										user: resturants[i].user
									}
									boztData.push(_item)
								}
							}
						}
						if (nextPage) {
							_myfavRest = myFavRestData.concat(_myfavRest)
							myRest = myRestData.concat(myRest)
							resturants = copyBoztData.concat(resturants)
						}
						for (let index = 0; index < resturants.length; index++) {
							let distance = await getDistanceFromLatLonInMiles(resturants[index].lat, resturants[index].lng, clat, clng)
							distance = distance.toFixed(2)
							resturants[index].distance = distance
						}
						resturants.sort(function (a, b) {
							return (a.distance === 'N/A') - (b.distance === 'N/A') || a.distance - b.distance
						})
						let _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants = resturants.filter((item) => {
								return item.billboard || item.is_awarded || item.is_favorite || item.is_bozted
							})
						}
						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueMyRestaurant = Object.values(
							myRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueRestaurant = Object.values(
							_myfavRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)

						setMyRestData(uniqueMyRestaurant)
						setMyFavRestData(uniqueRestaurant)
						setCopyBoztData(uniqueMyFavRestaurant)

						setSpinner(false)
					} else if (filtterType === 'myCircle') {
						for (let i = 0; i < resturants.length; i++) {
							for (let j = 0; j < resturants[i].user.length; i++) {
								if (resturants[i].user[j].degree === '1st' || resturants[i].user[j].degree === '2nd' || resturants[i].user[j].degree === ' ' || resturants[i].user[j].degree === '') {
									boztData.push(resturants[i])
									break
								}
							}
						}
						if (nextPage) {
							_myfavRest = myFavRestData.concat(_myfavRest)
							myRest = myRestData.concat(myRest)
							resturants = copyBoztData.concat(resturants)
						}
						for (let index = 0; index < resturants.length; index++) {
							let distance = await getDistanceFromLatLonInMiles(resturants[index].lat, resturants[index].lng, clat, clng)
							distance = distance.toFixed(2)
							resturants[index].distance = distance
						}
						resturants.sort(function (a, b) {
							return (a.distance === 'N/A') - (b.distance === 'N/A') || a.distance - b.distance
						})

						let _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants = resturants.filter((item) => {
								return item.billboard || item.is_awarded || item.is_favorite || item.is_bozted
							})
						}

						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueMyRestaurant = Object.values(
							myRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueRestaurant = Object.values(
							_myfavRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)

						setMyRestData(uniqueMyRestaurant)
						setMyFavRestData(uniqueRestaurant)
						setCopyBoztData(uniqueMyFavRestaurant)
						setSpinner(false)
					} else {
						for (let i = 0; i < resturants.length; i++) {
							let ress = false
							if (copyBoztData.some((obj) => obj?.r_id == resturants[i]?.r_id)) {
								ress = copyBoztData.some((obj) => obj?.r_id == resturants[i]?.r_id)
							}

							const distance = await getDistanceFromLatLonInMiles(parseFloat(resturants[i].lat), parseFloat(resturants[i].lng), clat, clng)
							resturants[i].distance = distance

							if (i == resturants?.length - 1) {
								setChangeLocation(ress)
							}
						}

						if (nextPage) {
							_myfavRest = myFavRestData.concat(_myfavRest)
							myRest = myRestData.concat(myRest)
							resturants = copyBoztData.concat(resturants)
						}
						let _resturants = resturants.filter((item) => {
							return item.lng != null || item.lat != null
						})

						if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true') {
							_resturants = resturants.filter((item) => {
								return item.billboard || item.is_awarded || item.is_favorite || item.is_bozted
							})
						}

						const uniqueMyFavRestaurant = Object.values(
							_resturants.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueMyRestaurant = Object.values(
							myRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)
						const uniqueRestaurant = Object.values(
							_myfavRest.reduce((acc, item) => {
								acc[item.r_id] = item
								return acc
							}, {})
						)

						setMyRestData(uniqueMyRestaurant)
						setMyFavRestData(uniqueRestaurant)
						setCopyBoztData(uniqueMyFavRestaurant)
						setBoztData(uniqueMyFavRestaurant)

						setSpinner(false)
					}
				} else {
					setSpinner(false)
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((error) => {
				setSpinner(false)
				console.log({type: 'error', description: error.message})
			})
	} catch (error) {
		setSpinner(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doLikeComment = async (id, b_id, setLoading, setisLiked) => {
	setLoading(true)
	try {
		await api
			.post(`/user/bozt/like_comment`, {
				c_id: id,
				b_id: b_id
			})
			.then((response) => {
				setLoading(false)
				if (response.data.success === true) {
					setisLiked(true)
				} else {
					setLoading(false)
					console.log({type: 'error', description: response.data.message})
				}
			})
			.catch((error) => {
				setLoading(false)
				console.log({type: 'error', description: error.message})
			})
	} catch (error) {
		setLoading(false)
		console.log({type: 'error', description: error.message})
	}
}

export const doGetllFiltersData = async (doFetchTaste) => {
	await api
		.get(`/admin/taste/view_all_taste`)
		.then(async (response) => {
			if (response.data.success === true) {
				await doFetchTaste(response.data.data.all_tastes)
			} else {
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doFetchRestaurantData = async (_resturants, _myRest, _myfavRest, setFavoritesData, setMyBoztData, setCircleBoztData, setCummunityData) => {
	let _userCircleBoztData = []
	let _userBoztData = []
	let _myFavorites = []
	_resturants.map((item) => {
		_myRest.map((myRest) => {
			if (item.r_id === myRest.r_id) {
				let _item = {
					address: item.address,
					categories: item.categories,
					distance: item.distance,
					is_bozted: true,
					is_favorite: false,
					lat: item.lat,
					lng: item.lng,
					r_id: item.r_id,
					r_title: item.r_title,
					user: item.user
				}
				_userBoztData.push(_item)
			}
			return myRest
		})

		_myfavRest.map((myRest) => {
			if (item.r_id === myRest.r_id) {
				let _item = {
					address: item.address,
					categories: item.categories,
					distance: item.distance,
					is_bozted: false,
					is_favorite: true,
					lat: item.lat,
					lng: item.lng,
					r_id: item.r_id,
					r_title: item.r_title,
					user: item.user
				}
				_myFavorites.push(_item)
			}
			return myRest
		})
		if (item.user?.length > 0) {
			for (let i = 0; i < item.user.length; i++) {
				if (item.user[i].degree === '1st' || item.user[i].degree === '2nd' || item.user[i].degree === ' ' || item.user[i].degree === '') {
					_userCircleBoztData.push(item)
					break
				}
			}
		}

		return item
	})
	setFavoritesData(_myFavorites)
	setMyBoztData(_userBoztData)
	setCircleBoztData(_userCircleBoztData)
	setCummunityData(_resturants)
}

export const doSearchRestaurant = async (searchByName, _resturants, setSearchData) => {
	let _item = []
	for (let i = 0; i < _resturants.length; i++) {
		let categorie = _resturants[i].categories
		let name = _resturants[i].r_title
		let tastes = _resturants[i]?.tastes
		let updatedCategory = []
		let updatedTastes = []
		for (let j = 0; j < categorie.length; j++) {
			categorie[j].toLowerCase()
			updatedCategory.push(categorie[j].toLowerCase())
		}
		for (let j = 0; j < tastes?.length; j++) {
			tastes[j]?.name.toLowerCase()
			updatedTastes.push(tastes[j]?.name?.toLowerCase())
		}

		let value = JSON.stringify(updatedCategory).includes(searchByName.toLowerCase())
		let tasteValue = JSON.stringify(updatedTastes).includes(searchByName.toLowerCase())
		if (value) {
			_item.push(_resturants[i])
		} else if (name?.toLowerCase()?.includes(searchByName.toLowerCase())) {
			_item.push(_resturants[i])
		} else if (tasteValue) {
			_item.push(_resturants[i])
		}
		_item = _item.sort(function (a, b) {
			return parseFloat(a.distance) - parseFloat(b.distance)
		})
		setSearchData(_item)
	}
}

export const doSendSuggestion = async (type, suggestion, setIsOpen) => {
	let _url = type === 'des' ? `/user/designation/add_suggested_designation` : `/user/taste/add_suggested_taste`
	await api
		.post(`${_url}`, {title: suggestion})
		.then((response) => {
			if (response.data.success === true) {
				toast.success(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setIsOpen(false)
			} else {
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setIsOpen(false)
			}
		})
		.catch((error) => {
			setIsOpen(false)
			console.error(error)
		})
}

export const doSubmitReport = async (props, reportText, closeModal) => {
	await api
		.post(`/user/bozt/report_bozt`, {b_id: props.id, reason: reportText})
		.then((response) => {
			if (response.data.success === true) {
				toast.success('Report Submitted successfully!', {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setTimeout(closeModal, 1000)
			} else {
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
				setTimeout(closeModal, 1000)
			}
		})
		.catch(function (error) {
			console.log(error)
		})
}

export const doUpdateUserDesignation = async (setLoading, idList, dispatch) => {
	setLoading(true)
	await api
		.post(`/user/update_user_designation`, {designation: idList})
		.then((response) => {
			if (response.data.success === true) {
				dispatch({
					type: 'DESIGNATION',
					payload: response?.data.data.user.user.designation
				})
				setLoading(false)
				toast.success(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			console.log(error)
			setLoading(false)
		})
}

export const doSocialLogin = async (provider, email, name, socialId, setLoading, dispatch, navigate) => {
	const data = {
		provider: provider,
		first_name: name ? name : '',
		last_name: '',
		user_name: '',
		email: email,
		phone: '',
		age_group: '',
		location: '',
		lat: '',
		lng: '',
		home_location: false,
		password: '',
		social_id: socialId,
		platform: 'web'
	}
	try {
		setLoading(true)
		await api
			.post('/user/social_login', data)
			.then(async (response) => {
				console.log('response.data.success', response.data.success)
				if (response.data.success === true) {
					dispatch({
						type: 'SET_USER',
						payload: response?.data.data.user
					})
					dispatch({
						type: 'CIRCLE_BOZT',
						payload: response?.data.data?.my_cicle_bozts?.bozts
					})
					dispatch({
						type: 'NOTIFICATIONS_COUNT',
						payload: response.data.data?.my_cicle_bozts?.notificationCount
					})
					localStorage.setItem('token', response?.data.data.user.auth_token)
					setLoading(false)
					// window.location.reload()
					navigate('/')
				} else {
					setLoading(false)
					toast.error(response.data.message, {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch(function (error) {
				setLoading(false)
			})
	} catch (error) {
		setLoading(false)
	}
}

export const doUserLogin = async (setLoading, email, password, dispatch, location, navigate) => {
	setLoading(true)
	await api
		.post(`/user/login`, {
			email: email,
			password: password,
			platform: 'web'
		})
		.then((response) => {
			if (response.data.success === true) {
				if (response?.data.data.user?.is_verified == false) {
					toast.error('Please verify your account first')
					setLoading(false)
				} else {
					dispatch({
						type: 'SET_USER',
						payload: response?.data.data.user
					})
					dispatch({
						type: 'CIRCLE_BOZT',
						payload: response?.data.data.my_cicle_bozts.bozts
					})
					dispatch({
						type: 'NOTIFICATIONS_COUNT',
						payload: response.data.data.my_cicle_bozts.notificationCount
					})
					localStorage.setItem('token', response?.data.data.user.auth_token)
					setLoading(false)
					// window.location.reload()
					if (location.pathname == '/discover') {
						navigate(location.pathname + location.search)
					} else if (location.pathname == '/share') {
						navigate(location.pathname + location.search)
					} else {
						navigate('/')
					}
				}
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doUserSignup = async (setLoading, data, navigate) => {
	setLoading(true)
	await api
		.post(`/user/register`, data)
		.then((response) => {
			if (response.data.success === true) {
				setLoading(false)
				navigate('/')
			} else {
				setLoading(false)
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}

export const doUpdateUserTaste = async (setLoading, selectedTastesIds) => {
	setLoading(true)
	await api
		.post(`/user/taste/add_user_taste`, {
			t_id: JSON.stringify(selectedTastesIds)
		})
		.then((response) => {
			setLoading(false)
			if (response.data.success === true) {
				toast.success(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			} else {
				toast.error(response.data.message, {
					position: toastStyle.position,
					autoClose: toastStyle.closeDuration
				})
			}
		})
		.catch((err) => {
			setLoading(false)
			console.log(err)
		})
}

export const doFetchFavRestaurantUsers = async (nextPage, setLoading, r_id, setShowLoadMore, allUsers, setAllUsers) => {
	setLoading(true)
	await api
		.post(`/user/bozt/fav_restaurant_users`, {r_id, page: nextPage})
		.then((response) => {
			if (response.data.success === true) {
				console.log('=response.data', response.data.data)
				let usersList = response.data.data
				setShowLoadMore(usersList.length >= 15)
				if (nextPage > 1) {
					usersList = allUsers.concat(usersList)
				}
				setAllUsers(usersList)
				setLoading(false)
			} else {
				setLoading(false)
				console.log('error', response.data.message)
			}
		})
		.catch(function (error) {
			setLoading(false)
			console.log(error)
		})
}
