import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import NotificationsList from './NotificationsList'
import Button from './common/Button.js'
import PageHeader from './common/PageHeader.js'
import { doFetchNotificationSettings, doUpdateNotification } from './api/apis.js'

const NotificationSettings = () => {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		doFetchNotificationsSettings()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const doFetchNotificationsSettings = async () => {
		await doFetchNotificationSettings(setLoading, setData)
	}

	const doUpdateNotifications = async () => {
		await doUpdateNotification(setLoading, data, setData)
	}

	const status = (type, status) => {
		debugger
		if (type == 'bookmark_my_bozt') {
			data.bookmark_my_bozt = status
		} else if (type == 'bozt_added_near_me') {
			data.bozt_added_near_me = status
		} else if (type == 'circle_add_bozt') {
			data.circle_add_bozt = status
		} else if (type == 'circle_add_favotite') {
			data.circle_add_favotite = status
		} else if (type == 'comment_my_bozt') {
			data.comment_my_bozt = status
		} else if (type == 'invite_bozt') {
			data.invite_bozt = status
		} else if (type == 'like_my_bozt') {
			data.like_my_bozt = status
		} else if (type == 'share_my_bozt') {
			data.share_my_bozt = status
		} else if (type == 'circle_update_bozt') {
			data.circle_update_bozt = status
		} else {
			data.someone_follow = status
		}
	}

	const handleSwitchChange = (keyword, status) => {
		debugger
		let _dataCb = data
		Object.keys(_dataCb).forEach((key, index) => {
			if (key == keyword) {
				_dataCb[key] = status
			} else {
				_dataCb[key] = _dataCb[key]
			}
		})
		setData(_dataCb)
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader padding={'0'} title={'Notifications Settings'} />
						{data && (
							<>
								<NotificationsList type="circle_add_bozt" title={'When someone in my Circle bōzts'} statusCB={status} value={data.circle_add_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="circle_add_favotite" title={'When someone in my Circle adds a Favorite to their list'} statusCB={status} value={data.circle_add_favotite} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="like_my_bozt" title={'When someone likes my bōzt'} statusCB={status} value={data.like_my_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="comment_my_bozt" title={'When someone comments on my bōzt'} statusCB={status} value={data.comment_my_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="bookmark_my_bozt" title={'When someone bookmarks my bōzt'} statusCB={status} value={data.bookmark_my_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="invite_bozt" title={'When someone I invited creates an account on bōzt'} statusCB={status} value={data.invite_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="someone_follow" title={'When someone adds me to their Circle'} statusCB={status} value={data.someone_follow} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="share_my_bozt" title={'When someone shares my bōzt'} statusCB={status} value={data.share_my_bozt} handleSwitchChange={handleSwitchChange} />
								<NotificationsList type="circle_update_bozt" title={'When someone in my Circle updates a bōzt'} statusCB={status} value={data.circle_update_bozt} handleSwitchChange={handleSwitchChange} />
							</>
						)}
					</Col>
				</CustomRow>
				{loading === false && (
					<CustomRow>
						<Col xs={11} sm={10} md={8} lg={5}>
							<Flexed>
								<Button color={color.White} bgtype={color.Orange} fontSize={'1.25rem'} height={'3.125rem'} width={'100%'} label={'Save'} onClick={() => doUpdateNotifications()} />
							</Flexed>
						</Col>
					</CustomRow>
				)}
			</Wrapper>
			{loading === true && <Loader visible={loading} />}
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
	margin-bottom: 1rem;
`

const Flexed = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`
export default NotificationSettings
