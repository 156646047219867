/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
const FollowersList = (props) => {
	const _own_id = useSelector((state) => state?.userData?.id)
	const {item, index} = props

	return (
		<span key={index}>
			<ContentWrapper>
				<LeftDiv
					onClick={() => {
						props.navigationHandlerCB(item.id)
					}}>
					<UserImage src={process.env.REACT_APP_IMAGE_URL + item.profile_image} alt="img" />
				</LeftDiv>
				<VerticleRow />
				<ContentDiv
					onClick={() => {
						props.navigationHandlerCB(item.id)
					}}>
					<UserName>
						{item.full_name}{' '}
						{item?.degree?.trim().length ? (
							<>
								<span style={{paddingBottom: '0.5rem'}}>.</span> &nbsp;
								<span style={{fontSize: '0.8rem'}}>{item?.degree}</span>
							</>
						) : (
							''
						)}
					</UserName>
					<Action>{item?.designation}</Action>
				</ContentDiv>
				{item.id == _own_id ? null : (
					<Button
						onClick={() => {
							props.doFollowUnfollowCB(item.id, item.is_follower)
						}}>
						{item.is_follower ? 'Unfollow' : 'Follow'}
					</Button>
				)}
			</ContentWrapper>
			<HorizontalRow />
		</span>
	)
}

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${color.White};
	cursor: pointer;
`

const ContentDiv = styled.div`
	margin-left: 0rem;
	padding: 0.625rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	width: 100%;
	border-radius: 1.25rem;
`
const LeftDiv = styled.div`
	margin-left: 0.5rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	display: flex;
	justify-content: center;
	align-items: center;
	${media.xs`
	margin-left: 0rem;
	`}
	${media.sm`
	margin-left: 0rem;
		`}
	${media.md`
	margin-left: 0.5rem;
	`}
	${media.lg`
	margin-left: 0.5rem;
	`}
`

const UserName = styled.p`
	margin: 0;
	padding: 0;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 10rem;
	white-space: nowrap;
`

const Action = styled.p`
	margin: 0;
`

const HorizontalRow = styled.div`
	border-top: 0.063rem solid ${color.LightGrey};
	width: 100%;
`

const VerticleRow = styled.div`
	border-left: 0.063rem solid ${color.LightGrey};
	margin-top: 1rem;
	margin-bottom: 1rem;
`

const UserImage = styled.img`
	height: 3.125rem;
	width: 3.125rem;
	border-radius: 1.563rem;
`

const Button = styled.button`
	min-width: 7rem;
	margin: 0.8rem;
	max-height: 3rem;
	font-size: 1rem;
	background-color: ${color.White};
	border-radius: 2rem;
	color: ${color.SteelBlue};
	font-weight: bold;
	border: none;
	box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.1);
	cursor: pointer;
	${media.xs`
	margin: 0.8rem 0.3rem 0.8rem 0rem;
	min-width: 6rem;
	`}
	${media.sm`
	margin: 0.8rem 0.3rem 0.8rem 0rem;
	min-width: 6rem;
		`}
	${media.md`
	margin: 0.8rem;
	min-width: 7rem;
	`}
	${media.lg`
	margin: 0.8rem;
	min-width: 7rem;
	`}
`

export default FollowersList
