import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import { BiArrowBack } from 'react-icons/bi'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import UserCard from './common/UserCard.js'
import { doGetFollowers, doUnfollowUser } from './api/apis.js'

const UserFollower = (props) => {
	const [searchParams] = useSearchParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	const [nextPage, setNextPage] = useState(1)
	const [showMore, setShowMore] = useState(false)
	let u_id = searchParams.get('id')
	const _navigate = useNavigate()

	useEffect(() => {
		getFollowers()
	}, [])

	const getFollowers = async (nextPage = 1) => {
		await doGetFollowers(nextPage, setLoading, u_id, setShowMore, data, setData)
	}

	const fetchMoreFollowers = () => {
		setNextPage(nextPage + 1)
		getFollowers(nextPage + 1)
	}

	const doFollowUnfollowCB = async (id, status) => {
		let res = await doUnfollowUser(id, setLoading, status)
		if (res) {
			getFollowers()
		}
		// await doFollowUnfollowCBOnCircle(f_id, follower_status, setLoading, 1, data, setData, copyData, setCopyData, setCongrate)
	}

	const handleClick = (data) => {
		_navigate({
			pathname: '/profile',
			search: createSearchParams({
				id: data
			}).toString()
		})
	}

	return (
		<div style={{ top: '0.5rem', position: 'relative' }}>
			<UserCard userData={data} title="Followers" emptyMessage={'No one in followers list'} loading={loading} showMore={showMore} fetchMore={() => fetchMoreFollowers()} userFolow={true} onFollowCb={doFollowUnfollowCB} />
		</div>
	)
}

export const ToggleButton = styled.div`
	padding: 0.5rem 0rem;
`
export const ToggleIcon = styled(BiArrowBack)`
	font-size: 2rem;
	margin-top: 0.5rem;
	font-weight: bold;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

export default UserFollower
