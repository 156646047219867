import React, {useState, useEffect, Suspense} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import Button from './common/Button.js'
import PageHeader from './common/PageHeader.js'
import {doFetchAllBoztsList} from './api/apis.js'
const FeedCard = React.lazy(() => import('./FeedCard.js'))

const BoztersList = (props) => {
	const [loading, setLoading] = useState(false)
	const [boztsList, setBoztsList] = useState([])
	const [nextPage, setNextPage] = useState(1)
	const [showMore, setShowMore] = useState(false)
	const [fetchAgain, setFetchAgain] = useState(false)
	useEffect(() => {
		doFetchBoztsList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchAgain])

	const doFetchBoztsList = async (nextPage = 1) => {
		await doFetchAllBoztsList(nextPage, setLoading, props, setShowMore, boztsList, setBoztsList)
	}
	const getNextPage = () => {
		setNextPage(nextPage + 1)
		doFetchBoztsList(nextPage + 1)
	}

	return (
		<Wrapper>
			<CustomRow>
				<PageHeader title={'Bōzted By'} padding={'0'} setIsRestPage={props.setIsRestPage} />
			</CustomRow>
			<CustomRow>
				{boztsList?.map((item, index) => {
					return (
						<Suspense fallback={'Loading...'}>
							<FeedCard feedData={item} hide={true} key={index.toString()} setFetchAgain={(e) => setFetchAgain(e)} />
						</Suspense>
					)
				})}
				{showMore && (
					<Button
						width={'100%'}
						margin={'0.5rem 0'}
						color={color.White}
						bgtype={color.Orange}
						fontSize={'1rem'}
						height={'3.125rem'}
						label={'Load More'}
						onClick={() => {
							getNextPage()
						}}
					/>
				)}
			</CustomRow>
			{loading && <Loader visible={loading} />}
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
`

const CustomRow = styled(Row)`
	justify-content: center;
`
export default BoztersList
