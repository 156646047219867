/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {color} from '../assets/css/Color.js'
import styled from 'styled-components'
import title from '../assets/images/logo_title.png'
import {useNavigate} from 'react-router-dom'
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai'
import Input from './common/Input'
import Button from './common/Button.js'
import {ClipLoader} from 'react-spinners'
import {BsChevronLeft} from 'react-icons/bs'
import {doUserSignup} from './api/apis.js'

const Signup = () => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('+1')
	const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('+1')
	const [userName, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const _token = localStorage.getItem('token')
	const _navigate = useNavigate()
	const [showPass, setShowPass] = useState(false)
	const [isPassErr, setIsPassErr] = useState(false)
	const [isEmailErr, setIsEmailErr] = useState(false)
	const [firstNameErr, setFirstNameErr] = useState(false)
	const [lastNameErr, setLastNameErr] = useState(false)
	const [phoneNumberErr, setPhoneNumberErr] = useState(false)
	const [confirmPhoneNumberErr, setConfirmPhoneNumberErr] = useState(false)
	const [matchPhone, setMatchPhone] = useState(false)
	const [userNameErr, setUserNameErr] = useState(false)

	useEffect(() => {
		if (_token) {
			navigate('/')
		}
	}, [])

	const handleValidation = () => {
		let isValid = true
		if (firstName?.trim().length === 0) {
			isValid = false
			setFirstNameErr(true)
		} else {
			setFirstNameErr(false)
		}

		if (lastName?.trim().length === 0) {
			isValid = false
			setLastNameErr(true)
		} else {
			setLastNameErr(false)
		}

		if (userName?.trim().length === 0) {
			isValid = false
			setUserNameErr(true)
		} else {
			setUserNameErr(false)
		}

		if (phoneNumber?.trim().length < 10) {
			isValid = false
			setPhoneNumberErr(true)
		} else {
			setPhoneNumberErr(false)
		}

		if (confirmPhoneNumber?.trim().length < 10) {
			isValid = false
			setConfirmPhoneNumberErr(true)
		} else {
			setConfirmPhoneNumberErr(false)
		}
		if (phoneNumber != confirmPhoneNumber) {
			isValid = false
			setMatchPhone(true)
		} else {
			setMatchPhone(false)
		}

		if (!email) {
			isValid = false
			setIsEmailErr(true)
		}
		if (email?.trim().length === 0) {
			isValid = false
			setIsEmailErr(true)
		}

		if (password?.trim().length < 8) {
			isValid = false
			setIsPassErr(true)
		} else {
			setIsPassErr(false)
		}
		return isValid
	}

	const validateEmail = (e) => {
		const re = /\S+@\S+\.\S+/
		let isValid = true
		if (e.trim()?.length === 0) {
			isValid = false
		} else if (!re.test(e)) {
			isValid = false
		}
		return isValid
	}

	const validatePassword = (e) => {
		let isValid = true
		if (e.trim()?.length === 0) {
			isValid = false
		}
		return isValid
	}

	const data = {
		first_name: firstName,
		last_name: lastName,
		user_name: userName,
		email: email,
		phone: phoneNumber,
		password: password,
		fcm_token: ''
	}

	const doSignup = async () => {
		await doUserSignup(setLoading, data, navigate)
	}

	const changePhone = (text) => {
		if (text.startsWith('+1')) {
			let reg = new RegExp(`^[0-9]{1,45}$`)
			let txt = text.replace('+', '')
			if (reg.test(txt)) {
				if (text.length <= 12) {
					setPhoneNumber(text)
				}
			}
		}
	}

	return (
		<>
			<NavWrapper>
				<CustomRow>
					<AppLogo xs={3} sm={3} md={2} lg={3}>
						<a href="https://bozt.app/">
							<LogoImage src={title} />
						</a>
					</AppLogo>
				</CustomRow>
			</NavWrapper>
			<CustomContainer>
				<Row justifyContent="center" style={{width: '100%'}}>
					<ContentCol xs={12} sm={12} md={5} lg={5}>
						<Wrapper>
							<Top>
								<BoztTitle>
									<HeaderWrapper>
										<BackBtn
											xs={2}
											sm={2}
											md={1}
											lg={1}
											onClick={() => {
												_navigate('/')
											}}>
											<Icon /> Back
										</BackBtn>
										<Title xs={10} sm={10} md={11} lg={11} weight={'500'}>
											{'Create Account'}
										</Title>
									</HeaderWrapper>
								</BoztTitle>
								<LoginContainer>
									<Input
										width={'80%'}
										value={firstName}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="First Name"
										onChange={(event) => {
											setFirstName(event.target.value)
										}}
									/>
									{firstName
										? ' '
										: firstNameErr && (
												<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
													First Name is required
												</ErrorMessage>
										  )}
									<Input
										width={'80%'}
										value={lastName}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Last Name"
										onChange={(event) => {
											setLastName(event.target.value)
										}}
									/>
									{lastName
										? ' '
										: lastNameErr && (
												<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
													Last Name is required
												</ErrorMessage>
										  )}
									<Input
										width={'80%'}
										value={phoneNumber}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Phone Number"
										onChange={(event) => {
											changePhone(event.target.value)
										}}
									/>
									{phoneNumber
										? ' '
										: phoneNumberErr && (
												<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
													Phone Number is required
												</ErrorMessage>
										  )}

									<Input
										width={'80%'}
										value={confirmPhoneNumber}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Confirm Phone Number"
										onChange={(event) => {
											setConfirmPhoneNumber(event.target.value)
										}}
									/>
									{matchPhone ? (
										<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
											Phone Number and Confirm Phone Number not Matched
										</ErrorMessage>
									) : confirmPhoneNumber ? (
										' '
									) : (
										confirmPhoneNumberErr && (
											<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
												Confirm Phone Number is required
											</ErrorMessage>
										)
									)}
									<Input
										width={'80%'}
										value={email}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Email"
										onChange={(event) => {
											validateEmail(event.target.value.toLowerCase())
											setEmail(event.target.value.toLowerCase())
										}}
									/>
									{email
										? ' '
										: isEmailErr && (
												<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
													Valid email is required
												</ErrorMessage>
										  )}

									<Input
										width={'80%'}
										value={userName}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Username"
										onChange={(event) => {
											setUserName(event.target.value)
										}}
									/>
									{userName
										? ' '
										: userNameErr && (
												<ErrorMessage position={'start'} lg={'4rem'} md={'2.8rem'} sm={'4rem'} xs={'3rem'}>
													Username is required
												</ErrorMessage>
										  )}
									<InputField>
										<Input
											width={'80%'}
											padding={'0'}
											margin={'0'}
											borderRadius={'0.5rem'}
											type={showPass ? 'text' : 'password'}
											placeholder="Password"
											height={'2.1rem'}
											border={'0'}
											onChange={(event) => {
												validatePassword(event.target.value)
												setPassword(event.target.value)
											}}
										/>
									</InputField>
									<EyeContainer lg={isEmailErr && email === '' ? '29.6rem' : ''} md={isEmailErr && email === '' ? '29.6rem' : ''} sm={isEmailErr && email === '' ? '29.6rem' : ''} xs={isEmailErr && email === '' ? '29.6rem' : ''}>
										{showPass ? <AiFillEyeInvisible onClick={() => setShowPass(false)} /> : <AiFillEye onClick={() => setShowPass(true)} />}{' '}
									</EyeContainer>

									{password
										? ''
										: isPassErr && (
												<ErrorMessage lg={'-4rem'} md={'0rem'} sm={'-2rem'} xs={'-2rem'} marginTop={isEmailErr && email === '' ? '0.5rem' : '0'}>
													Password must contains 8 letters or more!
												</ErrorMessage>
										  )}
									<Button
										label={loading === true ? 'Please wait' : 'Signup'}
										width={'80%'}
										borderRadius={'1.5rem'}
										bgtype={color.Orange}
										border={'0.063rem solid transparent'}
										fontWeight={'bolder'}
										color={color.White}
										margin={'1rem 0rem'}
										display={'flex'}
										padding={'0.7rem'}
										fontSize={'1rem'}
										label2={loading === true && <Spinner />}
										onClick={() => {
											if (handleValidation()) {
												doSignup()
											}
										}}
									/>
								</LoginContainer>
							</Top>
							<Spacer />
						</Wrapper>
					</ContentCol>
				</Row>
			</CustomContainer>
		</>
	)
}

const CustomContainer = styled(Container)`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
`

const ContentCol = styled(Col)`
	margin-top: 2rem;
	max-width: 100%;
	${media.xs`
    justify-content: center;
    margin-top: 0;
  `}
	${media.sm`
      justify-content: center;
      margin-top: 0;
  `}
  ${media.md`
	justify-content: flex-start;
      margin-top: 2rem;
  `}
  ${media.lg`
  justify-content: flex-start;
  margin-top: 2rem;
  `}
`

const Wrapper = styled.div``
const Top = styled.div`
	border: 0.063rem solid ${color.LightGrey};
	max-width: 100%;
	background-color: ${color.White};
`

const BoztTitle = styled.div`
	display: flex;
	justify-content: center;
`

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const ErrorMessage = styled.p`
	font-size: 0.68rem;
	margin: 0;
	padding: 0;
	align-self: ${({position}) => (position ? position : 'center')};
	color: ${color.Orange};
	margin-left: ${({margin}) => (margin ? margin : '0')};
	margin-top: ${({marginTop}) => (marginTop ? marginTop : '0')};
	${media.xs`
  margin-left: ${({xs}) => (xs ? xs : '0')};
  `}
	${media.sm`
  margin-left: ${({sm}) => (sm ? sm : '0')};
  `}
  ${media.md`
  margin-left: ${({md}) => (md ? md : '0')};
  `}
  ${media.lg`
  margin-left: ${({lg}) => (lg ? lg : '0')};
  `}
`
const EyeContainer = styled.div`
	position: absolute;
	right: 5rem;
	top: ${({top}) => (top ? top : '24.6rem')};
	cursor: pointer;
	${media.xs`
  top: ${({xs}) => (xs ? xs : '24.6rem')};
  right: 4rem;
  `}
	${media.sm`
  top: ${({sm}) => (sm ? sm : '24.6rem')};
  right: 4rem;
  `}
  ${media.md`
  top: ${({md}) => (md ? md : '24.6rem')};
  right: 3rem;
  `}
  ${media.lg`
  top: ${({lg}) => (lg ? lg : '24.6rem')};
  right: 5rem;
  `}
`

const Spinner = styled(ClipLoader)`
	color: ${color.Black};
	font-size: 12rem;
	font-weight: bold;
	margin-left: 0.3rem;
	height: 0.8rem !important;
	width: 0.8rem !important;
	animation: spin-logo infinite 1.5s linear;
	@keyframes spin-logo {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`
const InputField = styled.span`
	width: ${({width}) => (width ? width : '80%')};
	height: ${({height}) => (height ? height : '80%')};
	border: ${({border}) => (border ? border : `0.063rem solid ${color.LightGrey}`)};
	border-radius: 0.5rem;
	padding: 0 0.5rem 0 0.5rem;
`

const Spacer = styled.div`
	margin-top: 1rem;
`

const HeaderWrapper = styled(Col)`
	width: 100%;
	padding: ${({padding}) => `${padding ? padding : '0'}`};
	display: flex;
	font-weight: 500;
	align-items: center;
	padding: 0rem 0.8rem;
`

const BackBtn = styled.div`
	padding: 1.6rem 0rem;
	display: flex;
	font-size: 1rem;
	display: flex;
	align-items: center;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Icon = styled(BsChevronLeft)`
	font-size: 1rem;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Title = styled.h3`
	text-align: center;
	font-weight: ${({weight}) => `${weight}`};

	background-color: transparent;
	width: 84%;
	margin: 1.5rem 0;
`

const NavWrapper = styled(Container)`
	max-width: 100%;
	margin: 0;
	padding-left: 0;
	padding-right: 0;
	background-color: ${color.LightCream_10};
	${media.xs`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${color.LightCream_10};
`}
	${media.sm`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${color.LightCream_10};
`}
${media.md`
  padding-top: 0.5rem;
  padding-bottom:0.5rem;
  background-color: ${color.LightCream_10};
`}
${media.lg`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${color.LightCream_20};
  `}
`
const CustomRow = styled(Row)`
	justify-content: start;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	${media.xs`
	height:2.5rem;
  `}
	${media.sm`
	height:2.5rem;

  `}
  ${media.md`


  `}
`
const AppLogo = styled(Col)`
	@media (max-width: 768px) {
		max-width: 100%;
		display: flex;
		justify-content: start;
		margin-left: 0;
		padding-left: 0;
	}
`

const LogoImage = styled.img`
	margin-left: 2rem;
	cursor: pointer;
	${media.xs`
  height: auto;
  width: 3.5rem;
  margin-left: 1rem;
`}
	${media.sm`
  height: auto;
  width: 6.25rem;
`}
${media.md`
height: auto;
  width: 6.25rem;
`}
${media.lg`
height: auto;
width: 6.25rem;`}
`
export default Signup
