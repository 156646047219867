/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {color} from '../assets/css/Color.js'
import styled from 'styled-components'
import image from '../assets/images/insta.png'
import image2 from '../assets/images/insta1.png'
import MobileStoreButton from 'react-mobile-store-button'
import Logo from '../assets/images/logo_title.png'
import {useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {AiFillEye, AiFillEyeInvisible, AiFillApple} from 'react-icons/ai'
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'
import Input from './common/Input'
import Button from './common/Button.js'
import {ClipLoader} from 'react-spinners'
import AppleLogin from 'react-apple-login'
import jwt_decode from 'jwt-decode'
import {doSocialLogin, doUserLogin} from './api/apis.js'
import AppStore from '../assets/images/App_Store_Badge.svg'

const Login = ({onLogin}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const _token = localStorage.getItem('token')
	const location = useLocation()

	const [showPass, setShowPass] = useState(false)
	const [isPassErr, setIsPassErr] = useState(false)
	const [isEmailErr, setIsEmailErr] = useState(false)

	// useEffect(() => {
	// 	if (_token) {
	// 		navigate('/')
	// 	}
	// }, [])

	const iOSUrl = 'https://apps.apple.com/us/app/bozt/id1500087580'
	const androidUrl = 'https://play.google.com/store/apps/details?id=com.bozt.Bozt'

	const handleValidation = () => {
		let isValid = true
		if (!email) {
			isValid = false
			setIsEmailErr(true)
		}
		if (email?.trim().length === 0) {
			isValid = false
			setIsEmailErr(true)
		}

		if (password?.trim().length < 8) {
			isValid = false
			setIsPassErr(true)
		} else {
			setIsPassErr(false)
		}
		return isValid
	}

	const validateEmail = (e) => {
		const re = /\S+@\S+\.\S+/
		let isValid = true
		if (e.trim()?.length === 0) {
			isValid = false
		} else if (!re.test(e)) {
			isValid = false
		}
		return isValid
	}

	const validatePassword = (e) => {
		let isValid = true
		if (e.trim()?.length === 0) {
			isValid = false
		}
		return isValid
	}

	const doLogin = async () => {
		await doUserLogin(setLoading, email, password, dispatch, location, navigate, onLogin)
	}

	const appleSignIn = async (response) => {
		try {
			if (response?.authorization?.id_token) {
				let userData = jwt_decode(response?.authorization?.id_token)
				socialLogin('apple', userData?.email, '', userData?.sub)
			}
		} catch (error) {
			console.log('apple signin error', error.message)
		}
	}

	const googleSignIn = async (response) => {
		try {
			if (response) {
				let decodedData = jwt_decode(response?.credential)
				socialLogin('google', decodedData?.email, decodedData?.name, decodedData?.sub)
			}
		} catch (error) {
			console.log('google signin error', error.message)
		}
	}

	const socialLogin = async (provider, email, name, socialId) => {
		await doSocialLogin(provider, email, name, socialId, setLoading, dispatch, navigate)
	}

	// const iframes = document.querySelectorAll('iframe')

	// for (let i = 0; i < iframes.length; i++) {
	// 	const iframe = iframes[i]
	// iframe.style.width = '100%'
	// iframe.style.margin = '0px'
	// }

	// const iframe = document.querySelector('iframe');
	// if (iframe) {
	// 	iframe.style.width = '100%'
	// 	iframe.style.margin = '0px';
	// 	const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document
	// 	if (iframeDocument) {
	// 		iframeDocument.style.width = '100%'
	// 	}
	// }

	return (
		<>
			<CustomContainer>
				<Row>
					<ImageCol xs={12} sm={12} md={6} lg={6}>
						<FirstImage src={image} alt="img" />
						<Image src={image2} alt="img" />
					</ImageCol>
					<ContentCol xs={12} sm={12} md={6} lg={6}>
						<Wrapper>
							<Top>
								<BoztTitle>
									<ImageforProfile src={Logo} alt="logo"></ImageforProfile>
								</BoztTitle>
								<LoginContainer>
									<Input
										width={'80%'}
										value={email}
										type="text"
										borderRadius={'0.5rem'}
										placeholder="Email or Username"
										onChange={(event) => {
											validateEmail(event.target.value.toLowerCase())
											setEmail(event.target.value.toLowerCase())
										}}
									/>
									{email
										? ' '
										: isEmailErr && (
												<ErrorMessage position={'start'} lg={'2rem'} md={'1.8rem'} sm={'2rem'} xs={'2rem'}>
													Valid email or username is required
												</ErrorMessage>
										  )}
									<InputField>
										<Input
											width={'80%'}
											padding={'0'}
											margin={'0'}
											borderRadius={'0.5rem'}
											type={showPass ? 'text' : 'password'}
											placeholder="Password"
											height={'2.1rem'}
											border={'0'}
											onChange={(event) => {
												validatePassword(event.target.value)
												setPassword(event.target.value)
											}}
										/>
									</InputField>
									<EyeContainer lg={isEmailErr && email === '' ? '16.1rem' : ''} md={isEmailErr && email === '' ? '15.1rem' : ''} sm={isEmailErr && email === '' ? '15.6rem' : ''} xs={isEmailErr && email === '' ? '15.6rem' : ''}>
										{showPass ? <AiFillEyeInvisible onClick={() => setShowPass(false)} /> : <AiFillEye onClick={() => setShowPass(true)} />}{' '}
									</EyeContainer>
									{password
										? ''
										: isPassErr && (
												<ErrorMessage lg={'-4rem'} md={'0rem'} sm={'-2rem'} xs={'-2rem'} marginTop={isEmailErr && email === '' ? '0.5rem' : '0'}>
													Password must contains 8 letters or more!
												</ErrorMessage>
										  )}
									<Button
										label={loading === true ? 'Please wait' : 'Login'}
										width={'80%'}
										borderRadius={'1.5rem'}
										bgtype={color.Orange}
										border={'0.063rem solid transparent'}
										fontWeight={'bolder'}
										color={color.White}
										margin={'1rem 0rem'}
										display={'flex'}
										padding={'0.7rem'}
										fontSize={'1rem'}
										label2={loading === true && <Spinner />}
										onClick={() => {
											if (handleValidation()) {
												doLogin()
											}
										}}
									/>
								</LoginContainer>
								<ForgotButton>Forgot password?</ForgotButton>

								<SignUpButton style={{marginTop: '1rem'}}>{/* Don't have account? &nbsp; <ForgotButton onClick={() => navigate('/signup')}> Sign Up</ForgotButton> */}</SignUpButton>
							</Top>
							<Spacer />
							{/* <GoogleLoginDive>
								<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
									<div className="oauth-container">
										<GoogleLogin text="Sign in with google" onSuccess={(e) => googleSignIn(e)} onFailure={(e) => console.log('error', e)} cookiePolicy={'single_host_origin'} logo_alignment={'center'} size={'large'} theme={'outline'} data-with width="337" height="47" useOneTap={false} />
									</div>
								</GoogleOAuthProvider>
							</GoogleLoginDive>

							<AppleLoginButton
								clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
								redirectURI={process.env.REACT_APP_REDIRECT_URL}
								usePopup={true}
								callback={(e) => appleSignIn(e)}
								scope="email name"
								responseMode="query"
								render={(renderProps) => (
									<AppleButton onClick={() => renderProps.onClick()}>
										<AppleIcon />
										Sign in with Apple
									</AppleButton>
								)}
							/> */}
							<GetApp>
								<GetAppText>Get the App</GetAppText>
							</GetApp>
							{/* <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
								<img
									src={AppStore}
									alt="Download on the App Store"
									style={{ width: '200px', height: '60px' }} // Adjust the size as needed
								/>
							</a> */}
							<ButtonsForApps>
								<ButtonContainerForApple>
									{/* <AppStoreButton store="ios" url={iOSUrl} linkProps={{ title: 'iOS Store Button' }} /> */}
									<a href={iOSUrl} target="_blank" rel="noopener noreferrer">
										<CustomImage src={AppStore} alt="iOS Store Button" />
									</a>
								</ButtonContainerForApple>
								<ButtonContainer>
									<GoogleStoreButton store="android" url={androidUrl} linkProps={{title: 'Android Store Button'}} />
								</ButtonContainer>
							</ButtonsForApps>
						</Wrapper>
					</ContentCol>
				</Row>
			</CustomContainer>
		</>
	)
}

const CustomContainer = styled(Container)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`
const CustomImage = styled.img`
	${media.xs`
	width: 10rem;
`}
	${media.sm`
	width: 9rem;
`}
${media.md`
  width: 9rem;
`}
${media.lg`
  width: 9rem;
`}
`
const ImageCol = styled(Col)`
	display: flex;
	justify-content: end;
	padding: 2rem 1rem 0rem 0rem;
	margin-top: 2rem;
	${media.xs`
	display:none;
  `}
	${media.sm`
	display:none;
  `}
  ${media.md`
    display: flex;
	justify-content: end;
    margin-top: 2rem;
  `}
  ${media.lg`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
  `}
`
const ContentCol = styled(Col)`
	display: flex;
	justify-content: flex-start;
	padding: 2rem 0rem 0rem 1rem;
	margin-top: 2rem;
	${media.xs`
    justify-content: center;
    margin-top: 0;
  `}
	${media.sm`
      justify-content: center;
      margin-top: 0;
  `}
  ${media.md`
	justify-content: flex-start;
      margin-top: 2rem;
  `}
  ${media.lg`
  justify-content: flex-start;
  margin-top: 2rem;
  `}
`
const Image = styled.img`
	height: 30rem;
	width: auto;
`

const FirstImage = styled(Image)`
	position: absolute;
	top: 0.375rem;
	right: 8rem;
	z-index: -1;
`

const Wrapper = styled.div``
const Top = styled.div`
	border: 0.063rem solid ${color.LightGrey};
	max-width: 100%;
	background-color: ${color.White};
	padding: 2rem 0rem 2rem 0rem;
`

const GetApp = styled.div`
	display: flex;
	justify-content: center;
`
const BoztTitle = styled.div`
	display: flex;
	justify-content: center;
`

const ImageforProfile = styled.img`
	max-width: 65%;
	max-height: 65%;
	display: block;
	padding: 1.5rem 0rem 1.5rem 0rem;
`

const GetAppText = styled.span`
	font-size: 0.875rem;
	line-height: 1.125rem;
	margin: 0.625rem 1.25rem 0.625rem 1.25rem;
	text-align: center;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`
const ButtonContainerForApple = styled(ButtonContainer)`
	margin-top: 0.8rem;
	width: 100%;
`
const ButtonsForApps = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 0.6rem;
	${media.xs`
	flex-direction:column;
  `}
	${media.sm`
	flex-direction:column;
  `}
  ${media.md`
	flex-direction:column;
  `}
  ${media.lg`
  flex-direction:row;
  `}
`

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const AppStoreButton = styled(MobileStoreButton)`
	width: 9.5rem !important;
`
const GoogleStoreButton = styled(MobileStoreButton)`
	width: 11.5rem !important;
`
const ErrorMessage = styled.p`
	font-size: 0.68rem;
	margin: 0;
	padding: 0;
	align-self: ${({position}) => (position ? position : 'center')};
	color: ${color.Orange};
	margin-left: ${({margin}) => (margin ? margin : '0')};
	margin-top: ${({marginTop}) => (marginTop ? marginTop : '0')};
	${media.xs`
  margin-left: ${({xs}) => (xs ? xs : '0')};
  `}
	${media.sm`
  margin-left: ${({sm}) => (sm ? sm : '0')};
  `}
  ${media.md`
  margin-left: ${({md}) => (md ? md : '0')};
  `}
  ${media.lg`
  margin-left: ${({lg}) => (lg ? lg : '0')};
  `}
`
const EyeContainer = styled.div`
	position: absolute;
	right: -1rem;
	top: ${({top}) => (top ? top : '15.4rem')};
	cursor: pointer;
	${media.xs`
  top: ${({xs}) => (xs ? xs : '14.8rem')};
  right: 3rem;
  `}
	${media.sm`
  top: ${({sm}) => (sm ? sm : '14.8rem')};
  right: 3rem;
  `}
  ${media.md`
  top: ${({md}) => (md ? md : '14.4rem')};
  right: 2rem;
  `}
  ${media.lg`
  top: ${({lg}) => (lg ? lg : '15.4rem')};
  right: -1rem;
  `}
`

const AppleLoginButton = styled(AppleLogin)`
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	cursor: pointer !important;
	margin-top: 0.8rem !important;
	border: 0.063rem solid ${color.LightGrey} !important;
	max-width: 100% !important;
	width: 100% !important;
	background-color: ${color.White} !important;
	padding: 0.4rem 0rem 0.4rem 0rem !important;
`

const GoogleLoginDive = styled.div`
	cursor: pointer !important;
	max-width: 100% !important;
	width: 100% !important;
	padding: 0rem 0rem 0rem 0rem !important;
`

const AppleButton = styled.button`
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	cursor: pointer !important;
	margin-top: 1rem !important;
	border: 0.063rem solid ${color.LightGrey} !important;
	max-width: 100% !important;
	background-color: ${color.White} !important;
	padding: 0.6rem 0rem 0.6rem 0rem !important;
	width: 100% !important;
	color: rgba(0, 0, 0, 0.54);
	align-items: center;
	font-weight: 500 !important;
`

const AppleIcon = styled(AiFillApple)`
	font-size: 1.6rem;
	margin-right: 1rem;
`
const Spinner = styled(ClipLoader)`
	color: ${color.Black};
	font-size: 12rem;
	font-weight: bold;
	margin-left: 0.3rem;
	height: 0.8rem !important;
	width: 0.8rem !important;
	animation: spin-logo infinite 1.5s linear;
	@keyframes spin-logo {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`
const InputField = styled.span`
	width: ${({width}) => (width ? width : '80%')};
	height: ${({height}) => (height ? height : '80%')};
	border: ${({border}) => (border ? border : `0.063rem solid ${color.LightGrey}`)};
	border-radius: 0.5rem;
	padding: 0 0.5rem 0 0.5rem;
`

const Spacer = styled.div`
	margin-top: 1rem;
`
const ForgotButton = styled.div`
	display: flex;
	justify-content: center;
	color: ${color.Green};
	cursor: pointer;
`

const SignUpButton = styled.div`
	display: flex;
	justify-content: center;
	color: rgba(0, 0, 0, 0.54);
`
export default Login
