import React, {useState} from 'react'
import 'react-responsive-modal/styles.css'
import {Modal} from 'react-responsive-modal'
import styled from 'styled-components'
import {color} from '../../assets/css/Color.js'
import {Container, Row, media} from 'styled-bootstrap-grid'
import 'react-toastify/dist/ReactToastify.min.css'
import {doSendSuggestion} from '../api/apis.js'

const SuggestModal = ({setIsOpen, type}) => {
	const [active, setActive] = useState(false)
	const [suggestion, setSuggestion] = useState('')
	let _heading = type === 'des' ? 'Suggest Designations' : 'Suggest Tastes'
	let _text = 'We want to to make sure we find the best places for you.Send us your ideas!'
	let _placeholder = type === 'des' ? 'Enter a Designation' : 'Enter a Taste'

	const sendSuggestion = async () => {
		await doSendSuggestion(type, suggestion, setIsOpen)
	}

	return (
		<Wrapper>
			<CustomRow>
				<Modal
					open={true}
					onClose={() => {
						setIsOpen(false)
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal'
					}}>
					<Flexed width={'30rem'}>
						<Heading> {_heading}</Heading>
						<p>{_text}</p>
						<Input
							placeholder={_placeholder}
							onChange={(event) => {
								if (event.target.value.length === 0) {
									setActive(false)
								} else {
									setActive(true)
									setSuggestion(event.target.value)
								}
							}}></Input>
						<Suggest
							active={active}
							onClick={() => {
								if (suggestion.length > 0) {
									sendSuggestion()
								}
							}}>
							Suggest
						</Suggest>
					</Flexed>
				</Modal>
			</CustomRow>
		</Wrapper>
	)
}
const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
`
const CustomRow = styled(Row)`
	justify-content: center;
`
const Flexed = styled.div`
	position: relative;
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	width: ${({width}) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({width}) => `${width}`};
  `}
`

const Suggest = styled.button`
	font-size: 1.25rem;
	color: ${color.White};
	width: 100%;
	display: flex;
	background-color: ${({active}) => (active === true ? `${color.Orange}` : `${color.LighterGrey}`)};
	justify-content: center;
	height: 3.125rem;
	align-items: center;
	cursor: pointer;
	border: none;
	outline: none;
`
const Heading = styled.h1`
	text-align: center;
	margin: 0rem;
	color: ${color.Green};
`
const Input = styled.input`
	padding: 0.5rem;
	outline: none;
	border: 0.063rem solid ${color.LightGrey};
	margin: 1rem 0rem;
	font-size: 1rem;
`

export default SuggestModal
