import React, {useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import UserCard from './common/UserCard.js'
import {doSetCircle} from './api/apis.js'

const UserCircle = (props) => {
	const [searchParams] = useSearchParams()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)
	let _u_id = searchParams.get('id')

	const setCircle = async () => {
		await doSetCircle(_u_id, setData, setLoading)
	}

	useEffect(() => {
		setCircle()
	}, [])

	return (
		<div style={{top: '0.5rem', position: 'relative'}}>
			<UserCard userData={data} title="Circle" emptyMessage={'No one in following list'} loading={loading} />
		</div>
	)
}

export default UserCircle
