import React, { useState } from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import styled from 'styled-components'
import { Container, Row, media } from 'styled-bootstrap-grid'
import RestaurantDetails from '../ResturantDetails.js'
import BoztersList from '../BoztersList.js'

import UserRestaurantLikesList from '../common/UserRestaurantLikesList.js'

const RestaurantModal = ({ setIsOpen, r_id, lat, lng, isShare, id, parent, isFavorited }) => {
	const [isRestPage, setIsRestPage] = useState(true)
	const [isFavouritePage, setIsFavouritePage] = useState(false)

	const restCBHandler = async () => {
		setIsOpen(false)
	}

	return (
		<Wrapper>
			<CustomRow>
				<Modal
					open={true}
					onClose={() => {
						console.log('')
					}}
					center
					blockScroll={false}
					classNames={{
						overlay: 'customOverlay',
						modal: 'customRestaurantModal'
					}}>
					<Flexed>
						{isRestPage && !isFavouritePage ? (
							<RestaurantDetails restCBHandler={restCBHandler} background={true} r_id={r_id} lat={lat} lng={lng} setIsRestPage={setIsRestPage} id={id} isShare={isShare} parent={parent} isFavorited={isFavorited} setIsFavouritePage={setIsFavouritePage} />
						) : (
							!isFavouritePage &&
							<BoztersList r_id={r_id} setIsRestPage={setIsRestPage} />
						)}

						{isFavouritePage && <UserRestaurantLikesList id={r_id} setIsRestPage={setIsFavouritePage} />}
					</Flexed>
				</Modal>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const Flexed = styled.div`
	position: relative;
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	// margin: ${({ margin }) => `${margin}`};
	width: ${({ width }) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({ width }) => `${width}`};
  `}
`

export default RestaurantModal
