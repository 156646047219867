import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import { ThemeProvider } from 'styled-components'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from './redux/reducers/Reducers.js'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
const store = createStore(reducer)

const gridTheme = {
	gridColumns: 12,
	breakpoints: {
		xxl: 1440,
		xl: 1200,
		lg: 992,
		md: 768,
		sm: 576,
		xs: 575
	},
	row: {
		padding: 15
	},
	col: {
		padding: 5
	},
	container: {
		padding: 15,
		maxWidth: {
			xxl: 1700,
			xl: 1700,
			lg: 1700,
			md: 1700,
			sm: 1700,
			xs: 1700
		}
	}
}

const styledTheme = {
	mainColor: 'purple'
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={styledTheme}>
				<GridThemeProvider gridTheme={gridTheme}>
					<App />
					<ToastContainer position="top-center" />
				</GridThemeProvider>
			</ThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)
