const _R = 6371 // Radius of the earth in miles
export const getDistanceFromLatLonInMiles = async (lat1, lon1, lat2, lon2) => {
	let _lat1 = lat1 ? lat1 : 0
	let _lng1 = lon1 ? lon1 : 0
	let _lat2 = lat2 ? lat2 : 0
	let _lng2 = lon2 ? lon2 : 0

	let _dLat = deg2rad(_lat1 - _lat2)
	let _dLon = deg2rad(_lng1 - _lng2)
	let _a = Math.sin(_dLat / 2) * Math.sin(_dLat / 2) + Math.cos(deg2rad(_lat1)) * Math.cos(deg2rad(_lat2)) * Math.sin(_dLon / 2) * Math.sin(_dLon / 2)
	let _c = 2 * Math.atan2(Math.sqrt(_a), Math.sqrt(1 - _a))
	let _d = _R * _c //km
	let _m = _d * 0.621371 //miles
	return _m
}

const deg2rad = (deg) => {
	return deg * (Math.PI / 180)
}
