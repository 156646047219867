import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, media } from 'styled-bootstrap-grid'
import List from '../components/List.js'
import { useSelector } from 'react-redux'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import 'react-multi-carousel/lib/styles.css'
import { color } from '../assets/css/Color.js'
import { BsXLg } from 'react-icons/bs'
import bozt from '../assets/images/map_bozt.svg'
import boztdefualt from '../assets/images/map_default.svg'
import boztfavt from '../assets/images/map_favt.svg'
import filter from '../assets/images/filter.png'
import location_arrow from '../assets/images/location-arrow.png'
import add from '../assets/images/add.png'
import { toast } from 'react-toastify'
import { toastStyle } from '../assets/css/CommonStyle.js'
import Loader from '../components/common/Spinner.js'
import MapFilterModal from '../components/modal/FilterModal.js'
import RestaurantModal from '../components/modal/RestaurantModal.js'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Input from '../components/common/Input.js'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { useSearchParams } from 'react-router-dom'
import { doFetchNearByResturant, doGetCurrentAddress } from '../components/api/apis.js'

const Discover = () => {
	const [clat, setCLat] = useState()
	const [clng, setCLng] = useState()
	const [_clat, _setCLat] = useState()
	const [_clng, _setCLng] = useState()
	const [showlist, setShowList] = useState(false)
	const [nextPageToken, setNextPageToken] = useState('')
	const [page, setPage] = useState(0)
	const [boztData, setBoztData] = useState([])
	const [myRestData, setMyRestData] = useState([])
	const [myFavRestData, setMyFavRestData] = useState([])
	const [copyBoztData, setCopyBoztData] = useState([])
	const [mapRef, setMapRef] = useState(null)
	const [spinner, setSpinner] = useState(false)
	const [location, setLocation] = useState('')
	const [mapFilter, setMapFilter] = useState(false)
	const [filtterType, setFiltterType] = useState('')
	const [r_id, setR_id] = useState('')
	const [openRestDetails, setOpenRestDetails] = useState(false)
	const [searchParams] = useSearchParams()
	const [favRestaurant, setIsFav] = useState()
	const [changeLocation, setChangeLocation] = useState(false)

	let id = searchParams.get('id')

	const [isBoundsChanged, setIsBoundsChanged] = useState(false)

	const _userData = useSelector((state) => state?.userData)

	//to fetch the current location of user when page is rendered
	useEffect(() => {
		getCurrentLatLng()
	}, [])

	useEffect(() => {
		if (id) {
			setOpenRestDetails(id)
		}
	}, [id])

	//It will zoom into the current area of map that is being focused
	const defaultCenter = {
		lat: _clat ? _clat : 38.892708,
		lng: _clng ? _clng : -94.6426741,
		zoom: 15
	}

	//Function to Handle Select from Google Place Autocomplete to select a specific location and to get lat and lng from value
	const handleSelect = async (value) => {
		setNextPageToken('')
		if (!value == '') {
			setLocation(value)
			setSpinner(true)
			geocodeByAddress(value)
				.then((results) => getLatLng(results[0]))
				.then(async (latLng) => {
					// console.log('location:', value, 'latLng', latLng)
					_setCLat(latLng.lat)
					_setCLng(latLng.lng)
					await doFetchNearByResturants(nextPageToken, '', latLng.lat, latLng.lng, true, 0)
				})
				.catch((error) => console.error('Error', error))
		}
	}

	//Function to get Nearby resturants
	const doFetchNearByResturants = async (nextPage, searchByName = '', _lat2, _lng2, _loading = false, page = 0) => {
		if (!nextPage) {
			setBoztData([])
		}

		await doFetchNearByResturant(
			nextPage,
			searchByName,
			_lat2,
			_lng2,
			_loading,
			setSpinner,
			setNextPageToken,
			filtterType,
			boztData,
			myFavRestData,
			myRestData,
			copyBoztData,
			clat,
			clng,
			setMyRestData,
			setMyFavRestData,
			setCopyBoztData,
			setBoztData,
			page,
			setChangeLocation,
			setPage
		)
	}

	const fetchNextNearByResturants = async () => {
		let checkInGoogle = copyBoztData.length % 60 == 0 || changeLocation || nextPageToken == null
		if (process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false' ? process.env.REACT_APP_GOOGLE_RESTAURANTS == 'false' && copyBoztData.length >= 200 : process.env.REACT_APP_GOOGLE_RESTAURANTS == 'true' && checkInGoogle) {
			toast.error('Please move to another location on the map to update your search.', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else {
			doFetchNearByResturants(nextPageToken, '', _clat, _clng, true, page + 50)
		}
	}

	const getCurrentLatLng = async () => {
		setSpinner(true)
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setCLat(position.coords.latitude)
				setCLng(position.coords.longitude)
				_setCLat(position.coords.latitude)
				_setCLng(position.coords.longitude)
				await doGetCurrentAddress(position.coords.latitude, position.coords.longitude, setSpinner, setLocation)
				await doFetchNearByResturants('', '', position.coords.latitude, position.coords.longitude, true, page)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
					setSpinner(false)
				} else {
					setSpinner(false)
				}
			},
			{ timeout: 5000, enableHighAccuracy: true }
		)
	}

	const filterDataCBHandler = async (data, flag, type) => {
		if (data.length === 0) {
			setFiltterType('')
			setBoztData(copyBoztData)
			setLocation(location)
		} else {
			setIsBoundsChanged(true)
			setFiltterType(type)
			setBoztData(data)
		}
	}

	const showRestDetailHandler = (id) => {
		setOpenRestDetails(true)
		setR_id(id)
	}

	const handleboundschange = async () => {
		const _currentCenter = JSON.parse(JSON.stringify(mapRef.getCenter()))
		_setCLat(_currentCenter.lat)
		_setCLng(_currentCenter.lng)
		mapRef.panTo({ lat: _currentCenter?.lat, lng: _currentCenter?.lng })
		const _currentBounds = JSON.parse(JSON.stringify(mapRef.getBounds()))
		_currentCenter.latitude = _currentCenter.lat
		_currentCenter.longitude = _currentCenter.lng
		_currentCenter.longitudeDelta = _currentBounds?.east - _currentBounds?.west
		setIsBoundsChanged(true)
	}

	const handleUpdateMap = async () => {
		const _currentCenter = JSON.parse(JSON.stringify(mapRef.getCenter()))
		_setCLat(_currentCenter.lat)
		_setCLng(_currentCenter.lng)
		mapRef.panTo({ lat: _currentCenter?.lat, lng: _currentCenter?.lng })
		await doGetCurrentAddress(_currentCenter?.lat, _currentCenter?.lng, setSpinner, setLocation)
		await doFetchNearByResturants('', '', _currentCenter.lat, _currentCenter.lng, true, 0)
		setIsBoundsChanged(false)
	}

	let imgData = document.getElementsByTagName('img')
	if (imgData.length) {
		for (let i = 0; i <= imgData.length; i++) {
			if (imgData[i]?.src === process.env.REACT_APP_IMAGE_URL + _userData?.profile_image) {
				imgData[i].style.borderRadius = '50%'
				imgData[i].style.background = 'transparent'
			}
		}
	}

	return (
		<>
			{showlist === false && (
				<div className="divWrapper">
					<LocationDiv>
						<Icon
							src={location_arrow}
							onClick={() => {
								setPage(0)
								setNextPageToken('')
								spinner === false && getCurrentLatLng()
							}}
						/>
					</LocationDiv>

					<AddDiv>
						<Icon
							src={add}
							onClick={() => {
								spinner === false && fetchNextNearByResturants()
							}}
						/>
					</AddDiv>
					<FilterDive>
						<Icon
							src={filter}
							onClick={() => {
								spinner === false && setBoztData([])
								spinner === false && setMapFilter(true)
							}}
						/>
					</FilterDive>
					<ToogleDiv
						onClick={() => {
							spinner === false && setShowList(true)
						}}>
						<Togle />
					</ToogleDiv>
					<GoogleMap
						mapContainerStyle={mapStyles}
						zoom={defaultCenter.zoom}
						center={defaultCenter}
						onLoad={(map) => {
							setMapRef(map)
						}}
						options={{
							mapTypeControl: false,
							streetViewControl: false,
							fullscreenControl: false,
							rotateControl: false,
							zoomControl: false,
							scaleControl: false,
							panControl: true,
							gestureHandling: false
						}}
						onDragEnd={handleboundschange}>
						<>
							{_userData && spinner === false && mapRef && (
								<MarkerF
									position={{ lat: parseFloat(clat), lng: parseFloat(clng) }}
									// icon={{
									// 	url: `${process.env.REACT_APP_IMAGE_URL + _userData?.profile_image}`,
									// 	scaledSize: new window.google.maps.Size(40, 40),
									// 	optimized: false,
									// 	anchor: new window.google.maps.Point(20, 20),
									// 	labelOrigin: new window.google.maps.Point(20, 10)
									// }}
									icon={{
										url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
										scaledSize: new window.google.maps.Size(50, 50)
									}}
									shape={{ coords: [11, 17, 18], type: <code>circle</code> }}
								/>
							)}
							<>
								{boztData?.length > 0 &&
									boztData?.map((item, index) => {

										let offset = 0
										for (let j = 0; j < index; j++) {
											if (item?.distance == boztData[j]?.distance) {
												offset = index * 0.00010;
											}
										}

										return (
											<MarkerF
												key={index.toString()}
												onClick={() => {
													setOpenRestDetails(true);
													setR_id(item.r_id);
												}}
												position={{
													lat: parseFloat(item.lat) + offset,
													lng: parseFloat(item.lng) + offset,
												}}
												icon={{
													url: item.billboard === true
														? `${process.env.REACT_APP_IMAGE_URL + item.billboard_image}`
														: item.is_awarded === true
															? `${process.env.REACT_APP_IMAGE_URL + item.awarded_image}`
															: item.is_favorite == true
																? boztfavt
																: item.is_bozted == true
																	? bozt
																	: boztdefualt,
													scaledSize: (item.billboard === true || item.is_awarded === true)
														? new window.google.maps.Size(50, 40)
														: undefined,
												}}
											/>
										);
									})}

							</>
						</>
						<Wrapper>
							<CustomCol xs={9} sm={8} md={7} lg={5}>
								<PlacesAutocomplete
									value={location}
									onChange={(e) => {
										setNextPageToken('')
										setLocation(e)
									}}
									onSelect={(e) => {
										setNextPageToken('')
										handleSelect(e)
									}}>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<div>
											<InnerWrapper>
												<PinIcon src={location_arrow} />
												<Input width={'100%'} borderRadius={'0.2rem'} margin={'0'} padding={'0.8rem 0.8rem 0.8rem 2.5rem'} value={location} {...getInputProps()} placeholder="Search location" />
												<CrossIcon
													onClick={() => {
														setNextPageToken('')
														setLocation('')
													}}
												/>
											</InnerWrapper>
											<ListItems>
												{loading ? <div style={{ padding: '0.5rem', marginTop: '0.5rem' }}>Fetching location</div> : null}

												{location
													? suggestions.map((suggestion, i) => {
														const style = {
															backgroundColor: suggestion.active ? '#F5F3ED' : 'white'
														}
														return (
															<LocationList
																key={i + 'mapkey'}
																{...getSuggestionItemProps(suggestion, {
																	style
																})}>
																{suggestion?.description}
															</LocationList>
														)
													})
													: ''}
											</ListItems>
										</div>
									)}
								</PlacesAutocomplete>
								{isBoundsChanged && (
									<UpdateMap>
										<UpdateMapBtn onClick={handleUpdateMap}>Update search</UpdateMapBtn>
									</UpdateMap>
								)}
							</CustomCol>
						</Wrapper>
					</GoogleMap>
				</div>
			)}

			{showlist === true && <List boztData={boztData} fetchNextNearByResturantsCB={fetchNextNearByResturants} showRestDetailCB={showRestDetailHandler} setShowList={setShowList} />}

			{spinner && <Loader visible={spinner} text={'Loading...'} />}

			{mapFilter && <MapFilterModal setIsOpen={setMapFilter} filterDataCB={filterDataCBHandler} nearBozt={copyBoztData} myRest={myRestData} myFavRest={myFavRestData} userLat={clat} userLng={clng} />}
			{openRestDetails && <RestaurantModal setIsOpen={setOpenRestDetails} r_id={r_id} lat={clat} lng={clng} id={id} parent="discover" isFavorited={setIsFav} />}
		</>
	)
}

const Togle = styled(AiOutlineUnorderedList)`
	height: 1.7rem;
	width: 1.7rem;
	position: absolute;
	z-index: 100;
	cursor: pointer;
	filter: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(4286%) hue-rotate(138deg) brightness(96%) contrast(73%);
`

const IconContainer = styled.div`
	cursor: pointer;
	height: 2.4rem;
	width: 2.4rem;
	background-color: ${color.White};
	border-radius: 1.563rem;
	position: absolute;
	z-index: 99;
	right: 0.625rem;
	padding: 0.3rem;
	display: flex;
	justify-content: center;
	margin-left: 0.625rem;
	text-decoration: none;
`

const LocationDiv = styled(IconContainer)`
	margin-top: 4rem;
`

const AddDiv = styled(IconContainer)`
	margin-top: 7rem;
`

const FilterDive = styled(IconContainer)`
	margin-top: 10rem;
`

const ToogleDiv = styled(IconContainer)`
	margin-top: 13rem;
`

const mapStyles = {
	height: '100vh',
	width: '100%'
}

const Wrapper = styled(Row)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	justify-content: center;
	margin: 0;
`

const ListItems = styled.div`
	position: absolute;
	background: ${color.White};
	z-index: 1;

	${media.xs`
	width: 96% !important;

	`};
	${media.sm`
	width: 96% !important;
		`};
	${media.md`
	width: 98.1% !important;
		`};
	${media.lg`
	width: 98.1% !important;

	`};
	${media.xl`
	width: 98.1% !important;

	`};
	${media.xxl`
	width: 98% !important;

	`}
`

const InnerWrapper = styled.div`
	width: 100%;
	padding: 1rem 0rem 0rem 0rem;
`

const CrossIcon = styled(BsXLg)`
	cursor: pointer;
	position: absolute;
	top: 2rem;
	right: 1rem;
	z-index: 1;
	font-size: 0.85rem;
	padding: 0.2rem;
	color: ${color.Black};
	background-color: ${color.White};
	border-radius: 50%;
	box-shadow: 0.5px 1px 2px 0.5px ${color.Grey};
`

const PinIcon = styled.img`
	position: absolute;
	height: 1.4rem;
	top: 1.7rem;
	z-index: 1;
	left: 1rem;
	filter: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(4286%) hue-rotate(138deg) brightness(96%) contrast(73%);
	cursor: pointer;
`

const LocationList = styled.div`
	background-color: ${color.Transparent};
	text-align: left;
	padding: 0.5rem;
	cursor: pointer;
`

const CustomCol = styled(Col)``

const Icon = styled.img`
	width: 2.5rem;
	z-index: 100;
	filter: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(4286%) hue-rotate(138deg) brightness(96%) contrast(73%);
`

const LocationMarker = styled(MarkerF)`
	border-radius: 50% !important;
	& > img {
		border-radius: 50% !important;
		border: 1px solid #000 !important;
		display: none !important;
	}
`

const UpdateMap = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: center;
`

const UpdateMapBtn = styled.button`
	background: ${color.LightCream_10};
	padding: 1rem;
	border: none;
	border-radius: 0.025rem;
	cursor: pointer;
`

export default Discover
