import React, { useState, useEffect, Suspense } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import Slider from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import RightArrow from '../assets/images/right_black.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, createSearchParams } from 'react-router-dom'
import Congrate from '../components/modal/CongrateModal.js'
import Loader from '../components/common/Spinner.js'
import InfiniteScroll from 'react-infinite-scroll-component'
import EmptyMessage from '../components/common/EmptyMessage.js'
import { TabListContainer } from '../components/Circle.js'
import Login from '../components/Login.js'
import { doFetchCommunity, doGetMyCircleBozts, doGetSuggestedBozt } from '../components/api/apis.js'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from '../components/common/Button.js'
const FeedCard = React.lazy(() => import('../components/FeedCard.js'))

const Content = () => {
	const dispatch = useDispatch()
	const _navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const auth_token = localStorage.getItem('token')
	const [circleBozt, setCircleBozt] = useState([])
	const [page, setPage] = useState(1)
	const [suggestedBozt, setSuggestedBozt] = useState([])
	const [suggFetchingComplete, setSuggFetchingComplete] = useState(false)
	const [suggestBoztPage, setSuggestBoztPage] = useState(1)
	const [doFilter, setDoFilter] = useState(false)
	const [followId, setFollowId] = useState('')
	const [congrate, setCongrate] = useState(false)
	const [tabActive, setTabActive] = useState(0)
	const [communityBozt, setCommunityBozt] = useState([])
	const [hashMore, setHashMore] = useState(true)
	const circleBozts = useSelector((state) => state.circleBozts)
	const userData = useSelector((state) => state.userData)
	const [fetchingInProgress, setFetchingInProgress] = useState(false);
	let triggerTime

	useEffect(() => {
		if (auth_token || userData) {
			getSuggestedBozt()
		}
		if (circleBozts?.length && circleBozt?.length == 0) {
			setLoading(true)
			let resData = circleBozts
			setHashMore(circleBozts?.length == 0 ? false : circleBozts?.length < 10 ? false : true)
			setCircleBozt([...circleBozt, ...resData])
			setLoading(false)
		} else if (auth_token || userData) {
			myCircleBozts()
		}
	}, [circleBozts,])

	useEffect(() => {
		if (loading) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'scroll'
		}
	}, [loading])

	// filter on follow button
	useEffect(() => {
		if (doFilter && followId) {
			let filter = tabActive === 0 ? circleBozt : communityBozt
			let _circleBozt = filter.filter((_bozt) => {
				return _bozt.user.id != followId
			})
			tabActive === 0 ? setCircleBozt(_circleBozt) : setCommunityBozt(_circleBozt)
			setFollowId('')
			setDoFilter(false)
		}
		setFollowId('')
	}, [doFilter, followId])

	const fetchCommunity = async (_page) => {
		await doFetchCommunity(_page, dispatch, communityBozt, setHashMore, setCommunityBozt, setLoading)
	}

	const getSuggestedBozt = async (page = 1) => {
		await doGetSuggestedBozt(page, suggestedBozt, setSuggestedBozt, setSuggFetchingComplete)
	}

	const myCircleBozts = async (nextPage = 1) => {
		await doGetMyCircleBozts(nextPage, setLoading, dispatch, circleBozt, setHashMore, setCircleBozt)
	}



	const fetchMoreSuggestedBozt = async () => {
		if (fetchingInProgress) {
			return;
		}
		setFetchingInProgress(true);
		try {
			setSuggestBoztPage(suggestBoztPage + 1);
			await getSuggestedBozt(suggestBoztPage + 1);
			setSuggFetchingComplete(false);
		} catch (error) {

		} finally {
			setFetchingInProgress(false);
		}
	};


	const circleLoadFunc = () => {
		if (tabActive === 1 && hashMore) {
			fetchCommunity(page + 1)
			setPage(page + 1)
		} else if (tabActive === 0 && hashMore) {
			myCircleBozts(page + 1)
			setPage(page + 1)
		}
	}

	const closeCongrateModal = () => {
		setCongrate(false)
		setDoFilter(true)
	}

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
			slidesToSlide: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 4,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 3,
			slidesToSlide: 1
		}
	}

	const feedData = tabActive === 1 ? communityBozt : circleBozt

	const FeedCardLoader = () => {
		return (
			<div>
				<Skeleton height={500} width={'100%'} />
			</div>
		)
	}

	return auth_token ? (
		<Wrapper>
			<CustomRow>
				<Col xs={11} sm={10} md={8} lg={5}>
					<TabListContainer justifyContent="space-between" marginBottom={tabActive === 0 && '1rem'}>
						<Col lg={6} style={{ padding: '0' }}>
							<FirstTab
								active={tabActive}
								onClick={() => {
									setCircleBozt([])
									setTabActive(0)
									myCircleBozts()
									setPage(1)
								}}>
								My Circle
							</FirstTab>
						</Col>
						<Col lg={6} style={{ padding: '0' }}>
							<SecondTab
								active={tabActive}
								onClick={() => {
									setCommunityBozt([])
									setTabActive(1)
									fetchCommunity()
									setPage(1)
								}}>
								Community
							</SecondTab>
						</Col>
					</TabListContainer>
					{suggestedBozt?.length > 0 && tabActive === 1 && <Heading weight="500">Suggested bōzters</Heading>}
					<SliderScroll responsive={responsive} customRightArrow={<CustomRightArrow fetchMoreSuggestedBozt={() => fetchMoreSuggestedBozt()} suggFetchingComplete={suggFetchingComplete} />}>
						{tabActive === 1 &&
							suggestedBozt?.map((data) => {
								return (
									<Col key={Math.random()}>
										<NestedDiv
											onClick={(e) => {
												if (triggerTime > 100) return
												else
													_navigate({
														pathname: '/profile',
														search: createSearchParams({
															id: data?.id
														}).toString()
													})
											}}
											onMouseDown={() => {
												triggerTime = new Date().getTime()
											}}
											onMouseUp={() => {
												let thisMoment = new Date().getTime()
												triggerTime = thisMoment - triggerTime
											}}>
											<ImageContainer>
												<Image src={data.profile_image} />
											</ImageContainer>
											<NameDiv>{data?.first_name + ' ' + data?.last_name}</NameDiv>
										</NestedDiv>
									</Col>
								)
							})}
					</SliderScroll>
					{feedData?.length > 0
						? feedData?.map((data, index) => {
							return (
								<>
									<Suspense key={Math.random()} fallback={<FeedCardLoader />}>
										<FeedCard feedData={data} setDoFilter={setDoFilter} setFollowId={setFollowId} setCongrate={setCongrate} feedCircle={tabActive === 0 ? true : false} />
									</Suspense>

									{feedData?.length > 5 && index === 4 && suggestedBozt?.length > 0 && tabActive === 0 && <Heading weight="500">Suggested bōzters</Heading>}
									<SliderScroll responsive={responsive} customRightArrow={<CustomRightArrow fetchMoreSuggestedBozt={() => fetchMoreSuggestedBozt()} suggFetchingComplete={suggFetchingComplete} />}>
										{feedData?.length > 5 &&
											index === 4 &&
											tabActive === 0 &&
											suggestedBozt?.map((data) => {
												return (
													<Col xs={12} sm={12} md={12} lg={12} key={Math.random()}>
														<NestedDiv
															onClick={(e) => {
																if (triggerTime > 100) return
																else
																	_navigate({
																		pathname: '/profile',
																		search: createSearchParams({
																			id: data?.id
																		}).toString()
																	})
															}}
															onMouseDown={() => {
																triggerTime = new Date().getTime()
															}}
															onMouseUp={() => {
																let thisMoment = new Date().getTime()
																triggerTime = thisMoment - triggerTime
															}}>
															<ImageContainer>
																<Image src={data.profile_image} />
															</ImageContainer>
															<NameDiv>{data?.first_name + ' ' + data?.last_name}</NameDiv>
														</NestedDiv>
													</Col>
												)
											})}
									</SliderScroll>
								</>
							)
						})
						: !loading && feedData.length === 0 && <EmptyMessage message="No bōzts found" top="4rem" />}{' '}
					{loading == false && hashMore && feedData.length ? <Button color={color.White} bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} width={'100%'} label={'Load More'} onClick={() => circleLoadFunc()} /> : ''}
					{loading && <Loader visible={loading} top={'1rem'} text={'Loading...'} />}
				</Col>
			</CustomRow>

			{congrate && <Congrate closeCB={closeCongrateModal} parent="follow" />}
		</Wrapper>
	) : (
		<Login />
	)
}

const CustomRightArrow = ({ onClick, ...props }) => {
	if (props?.carouselState?.currentSlide == props?.carouselState?.totalItems - 5 && props?.carouselState?.totalItems >= 15 && props?.suggFetchingComplete == true) {
		props.fetchMoreSuggestedBozt()
	}
	return (
		<ArrowIcon onClick={() => onClick()}>
			<ArrowImage src={RightArrow} />
		</ArrowIcon>
	)
}

const ArrowIcon = styled.button`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	background-color: ${color.Black};
	margin: 0 1.5rem;
	opacity: 0.5;
	padding: 0.4rem;
	min-height: 43px;
	min-width: 43px;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	color: ${color.White} !important;
	&:hover {
		opacity: 0.8;
	}
`

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 4.2rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`

const NestedDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${color.White};
	position: relative;
	border-radius: 0.313rem;
	padding: 0;
	width: 100%;
	margin-bottom: 0.625rem;
`

const ImageContainer = styled.div`
	margin: 0.9rem;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${media.xs`
		margin: 0.3rem;

	`}
	${media.sm`
	margin:0.6rem;

  `}
  	${media.md`

	`}
  	${media.lg`
	  	margin: 1.3rem 0 0 0;

		`}
`

const Image = styled.div`
	background: ${({ src }) => (src ? `url(${process.env.REACT_APP_IMAGE_URL + src}) no-repeat` : `${color.LighterGrey}`)};
	background-size: cover;
	background-position: center;
	border: 0.1rem solid ${color.LightCream_80};
	height: 7.8rem;
	width: 7.8rem;
	border-radius: 50%;
	background-position: center;
	object-fit: contain !important;
	${media.xs`
		height: 5.5rem;
		width: 5.5rem;
	`}
	${media.sm`
		height: 4.8rem;
		width: 4.8rem;
  	`}
  	${media.md`
		height: 4.8rem;
		width: 4.8rem;
	`}
  	${media.lg`
		height: 4.8rem;
		width: 4.8rem;

	  `}
`

const ArrowImage = styled.div`
	background: ${({ src }) => (src ? `url(${src}) no-repeat` : `${color.LighterGrey}`)};
	background-size: cover;
	background-position: center;
	height: 1.6rem;
	width: 1.6rem;
	border-radius: 50%;
	background-position: center;
	object-fit: contain !important;
	text-align: center;
	opacity: 1;
	filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(272deg) brightness(106%) contrast(101%);
`

const NameDiv = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: 3rem;
	align-items: end;
	background-color: ${color.White};
	font-size: 1rem;
	text-align: center;
	padding: 0.625rem;
	border-bottom-left-radius: 0.313rem;
	border-bottom-right-radius: 0.313rem;
	&:hover {
		color: ${color.Orange};
	}
	${media.xs`
		font-size: 0.625rem;
		height: 2rem;
		padding: 0 0 0.9rem 0;
		`}
	${media.sm`
		font-size: 0.75rem;
		padding: 0 0 0.9rem 0;
		`}
	${media.md`
		font-size: 1rem;
		padding: 0 0 0.9rem 0;
		`}
	${media.lg`
		font-size: 1rem;
		padding: 0 0 0.9rem 0;

	`}
`

const SliderScroll = styled(Slider)``

const Heading = styled.p`
	font-weight: ${({ weight }) => `${weight}`};
	font-size: 1rem;
	font-family: sans-serif;
	background-color: transparent;
	margin: 0.4rem;
`

const LoadingMoreDiv = styled(InfiniteScroll)`
	overflow-x: hidden !important;
	min-height: 10rem;
`

const TabMenu = styled.div`
	width: 100%;
	padding: 1rem 1.5rem 1rem 1.5rem;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 0.2rem;
	display: flex;
	justify-content: center;
	font-size: 1rem;
	align-items: center;
`

const FirstTab = styled(TabMenu)`
	background-color: ${(props) => (props.active === 0 ? `${color.LighterGrey}` : 'none')};
`
const SecondTab = styled(TabMenu)`
	background-color: ${(props) => (props.active === 1 ? `${color.LighterGrey}` : 'none')};
`

export default Content
