import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import { color } from '../../assets/css/Color.js'
import { BsChevronLeft } from 'react-icons/bs'
import { useNavigate, useLocation } from 'react-router-dom'

const PageHeader = (props) => {
	const _navigate = useNavigate()
	const _location = useLocation()

	return (
		<>
			{props.title == 'Restaurant Details' ? (
				<HeaderWrapper xs={12} sm={props.background ? 12 : 10} md={props.background ? 12 : 8} lg={props.background ? 12 : 5}>
					<BackBtn
						xs={2}
						sm={2}
						md={1}
						lg={1}
						onClick={() => {
							if (props.title === 'Restaurant Details') {
								props.setResturantData([])
								if (props.isShare) {
									_navigate('/')
								} else if (props.background === true) {
									props.restCBHandler()
								} else if (_location.pathname === '/rest') {
									_navigate(-1)
								} else {
									props.setOpenRestDetails(false)
								}
							}
						}}>
						<Icon /> Back
					</BackBtn>
					<Title xs={10} sm={10} md={11} lg={11} weight={'500'}>
						{props.title}
					</Title>
				</HeaderWrapper>
			) : props.title == 'Favorites' ? (
				<HeaderWrapper xs={11} sm={10} md={8} lg={5}>
					<BackBtn
						xs={2}
						sm={2}
						md={1}
						lg={1}
						onClick={() => {
							_navigate(-1)
						}}>
						<Icon /> Back
					</BackBtn>
					<Title xs={5} sm={5} md={5} lg={5} weight={'500'}>
						{props.title}
					</Title>
					<AddFav
						onClick={() => {
							_navigate({
								pathname: '/addFavoriteRest'
							})
						}}>
						Add Favorite
					</AddFav>
				</HeaderWrapper>
			) : (
				<HeaderWrapper
					padding={props.padding}
					xs={11}
					sm={10}
					md={props.title === 'Bōzted By' ? 12 : props.title === 'Map Filters' ? 12 : props.title == 'Notifications' ? 12 : 12}
					lg={props.title === 'Bōzted By' ? 12 : props.title === 'Add Favorite' ? 12 : props.title === 'Map Filters' ? 12 : props.title === 'Want to go' ? 5 : 12}>
					<BackBtn
						xs={2}
						sm={2}
						md={1}
						lg={1}
						onClick={() => {
							if (props.title === 'Bōzted By') {
								props.setIsRestPage(true)
							} else if (props.title === 'Map Filters') {
								props.setIsOpen(false)
								props.filterDataCB([], false)
							} else if (props.title == 'Favorites By') {
								props.setIsRestPage(false)
							} else {
								_navigate(-1)
							}
						}}>
						<Icon /> Back
					</BackBtn>
					<Title xs={10} sm={10} md={11} lg={11} weight={'500'}>
						{props.title}
					</Title>
				</HeaderWrapper>
			)}
		</>
	)
}

const HeaderWrapper = styled(Col)`
	width: 100%;
	padding: ${({ padding }) => `${padding ? padding : '0 1.5rem'}`};
	display: flex;
	justify-content: start;
	font-weight: 500;
	align-items: center;
`

const BackBtn = styled.div`
	padding: 1.6rem 0rem;
	display: flex;
	font-size: 1rem;
	display: flex;
	align-items: center;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Icon = styled(BsChevronLeft)`
	font-size: 1rem;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`

const Title = styled.h3`
	text-align: center;
	font-weight: ${({ weight }) => `${weight}`};
	background-color: transparent;
	width: 84%;
`

const AddFav = styled.p`
	font-size: 1rem;
	margin: 1.5rem 0;
	font-family: sans-serif;
	font-weight: bold;
	text-align: end;
	width: 100%;
	color: ${color.Green};
	cursor: pointer;
`

export default PageHeader
