import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color'

const NotificationsList = ({type, statusCB, value, title, handleSwitchChange}) => {
	const [val, setVal] = useState(value)

	useEffect(() => {
		setVal(value)
	}, [value])

	useEffect(() => {
		handleSwitchChange(type, val)
	}, [value])

	return (
		<span key={Math.random()}>
			<ContentWrapper>
				<ContentDiv>
					<Action>{title}</Action>
				</ContentDiv>
				<ToogleContainer>
					<Toggle>
						<label className="switch">
							<input
								type="checkbox"
								checked={val}
								onChange={() => {
									setVal(!val)
									handleSwitchChange(type, !val)
								}}
							/>
							<span className="slider round"></span>
						</label>
					</Toggle>
				</ToogleContainer>
			</ContentWrapper>
			<HorizontalRow />
		</span>
	)
}

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${color.White};
	cursor: pointer;
`

const ContentDiv = styled.div`
	margin-left: 0rem;
	display: flex;
	justify-content: start;
	align-items: center;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	width: 100%;
	border-radius: 1.25rem;
`
const ToogleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Toggle = styled.div`
	position: relative;
	width: 75px;
	display: inline-block;
	text-align: center;
`

const Action = styled.p`
	margin: 0;
`

const HorizontalRow = styled.div`
	border-top: 0.0625rem solid ${color.LightGrey};
	margin-top: 0.0625rem;
	width: 100%;
`

export default NotificationsList
