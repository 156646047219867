import React from 'react'
import styled from 'styled-components'
import {Container, Row, media} from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import {Modal} from 'react-responsive-modal'
import {color} from '../../assets/css/Color.js'
import 'react-toastify/dist/ReactToastify.min.css'
import ShieldImage from '../../assets/images/shield.png'

const Congrate = (props) => {
	const closeModal = async () => {
		props.closeCB()
	}

	return (
		<Container>
			<Row>
				<Modal
					open={true}
					onClose={closeModal}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal'
					}}>
					{props.parent === 'follow' ? (
						<Flexed direction="column" justify="center" align="center" width="20rem">
							<Listview weight="500">Congrats!</Listview>
							<Text>You have just earned the Founder and Follow Badges.</Text>

							<BadgeArea>
								<Badge src={ShieldImage} />
								<BadgeText>Founder</BadgeText>
							</BadgeArea>
							<HorizontalRow />
							<BadgeArea>
								<Badge src={ShieldImage} />
								<BadgeText>Follow</BadgeText>
							</BadgeArea>
							<HorizontalRow />
							<ContinueButton onClick={closeModal}>OK</ContinueButton>
							<HorizontalRow />
						</Flexed>
					) : (
						''
					)}
				</Modal>
			</Row>
		</Container>
	)
}

const Flexed = styled.div`
	position: relative;
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({width}) => `${width}`};
  `}
`

const Listview = styled.li`
	list-style: none;
	font-weight: ${({weight}) => `${weight}`};
	cursor: pointer;
	font-size: 1.5rem;
	font-family: sans-serif;
	background-color: transparent;
`

const Text = styled.p`
	list-style: none;
	font-size: 1rem;
	font-family: sans-serif;
	background-color: transparent;
	text-align: center;
`

const HorizontalRow = styled.div`
	margin-top: 1rem;
	width: 100%;
`

const ContinueButton = styled.button`
	width: 90%;
	padding: 0.85rem;
	border-radius: 0.2rem;
	background-color: ${({disable}) => (disable === true ? '' : `${color.Orange}`)};
	color: ${({disable}) => (disable === true ? `${color.LightGrey}` : `${color.White}`)};
	font-weight: bold;
	border: none;
	cursor: pointer;
`
const BadgeArea = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	width: 89%;
	height: 4rem;
	margin: 0;
	padding: 0 0.8rem;
	gap: 0.5rem;
	background: ${color.LightCream_60};
`

const Badge = styled.img`
	height: 1.5rem;
	width: 1.5rem;
`
const BadgeText = styled.p`
	font-size: 1rem;
	text-align: start;
`

export default Congrate
