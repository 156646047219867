/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { color } from '../assets/css/Color.js'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import Loader from './common/Spinner.js'
import InviteModal from './modal/InviteModal.js'
import { BsSearch } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom'
import EmptyMessage from './common/EmptyMessage.js'
import Congrate from './modal/CongrateModal.js'
import Button from './common/Button.js'
import Input from './common/Input.js'
import PageHeader from './common/PageHeader.js'
import { doFetchCircleUsers, doFetchFollowers, doFollowUnfollowCBOnCircle, doGetSuggestedBoztOnCircle, doSearchBozter } from './api/apis.js'

const Circle = () => {
	const [searchParams] = useSearchParams()
	const _navigate = useNavigate()
	const userData = useSelector((state) => state?.userData)
	const [loading, setLoading] = useState(false)
	const [tabActive, setTabActive] = useState(0)
	const [data, setData] = useState([])
	const [copyData, setCopyData] = useState([])
	const [error, setError] = useState('')
	const [searchName, setSearchName] = useState('')
	const [openModal, setOpenModal] = useState(false)
	const [nextPage, setNextPage] = useState(1)
	const [showMore, setShowMore] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const [congrate, setCongrate] = useState(false)
	const back = searchParams.get('back')
	let storage = localStorage.getItem('currentTab')

	useEffect(() => {
		if (back && back == 'back') {
			setTabActive(storage ? parseInt(storage) : 1)
			if (storage && parseInt(storage) == 0) {
				getSuggestedBozt()
			} else if (storage && parseInt(storage) == 1) {
				setCircle()
			} else if (storage && parseInt(storage) == 0) {
				getFollowers()
			} else {
				setCircle()
			}
		} else {
			getSuggestedBozt()
		}
	}, [])

	useEffect(() => {
		localStorage.setItem('currentTab', tabActive)
	}, [tabActive])

	const setCircle = async (nextPage = 1) => {
		await doFetchCircleUsers(setLoading, setData, setShowMore, nextPage, data)
	}

	const getFollowers = async (nextPage = 1) => {
		await doFetchFollowers(setLoading, nextPage, userData, setShowMore, data, setData)
	}

	const getSuggestedBozt = async (nextPage = 1) => {
		await doGetSuggestedBoztOnCircle(nextPage, setLoading, setShowMore, setData, data, setCopyData)
	}

	const searchBozter = async (text) => {
		await doSearchBozter(text, setData)
	}

	const doFollowUnfollowCB = async (f_id, follower_status) => {
		await doFollowUnfollowCBOnCircle(f_id, follower_status, setLoading, tabActive, data, setData, copyData, setCopyData, setCongrate)
	}

	const handleClick = (data) => {
		_navigate({
			pathname: '/profile',
			search: createSearchParams({
				id: data
			}).toString()
		})
	}

	const fetchMoreUsers = () => {
		if (tabActive == 0) {
			const res = JSON.stringify(data).includes('is_follower')
			if (res) {
				setNextPage(1)
				getSuggestedBozt(1)
			} else {
				setNextPage(nextPage + 1)
				getSuggestedBozt(nextPage + 1)
			}
		} else if (tabActive == 1) {
			setNextPage(nextPage + 1)
			setCircle(nextPage + 1)
		} else {
			setNextPage(nextPage + 1)
			getFollowers(nextPage + 1)
		}
	}

	return (
		<Wrapper>
			<CustomRow>
				<Col xs={11} sm={10} md={8} lg={5}>
					<ContentWrapper>
						<PageHeader title={'My Circle'} padding={'0'} />
					</ContentWrapper>
					<Paragraph>Grow your Circle! Follow people whose taste you trust. Circles can hold up to 28 so be selective.</Paragraph>

					<ContentWrapper>
						<TabsContainer>
							<TabListContainer>
								<FirstTab
									active={tabActive}
									onClick={() => {
										setTabActive(0)
										setNextPage(1)
										setData([])
										getSuggestedBozt()
									}}>
									Follow
								</FirstTab>
								<SecondTab
									active={tabActive}
									onClick={() => {
										setNextPage(1)
										setTabActive(1)
										setData([])
										setCircle()
									}}>
									Circle
								</SecondTab>
								<ThirdTab
									active={tabActive}
									onClick={() => {
										setNextPage(1)
										setTabActive(2)
										setData([])
										getFollowers()
									}}>
									Followers
								</ThirdTab>
							</TabListContainer>
							<HorizontalRow />
							{tabActive === 0 && (
								<OuterWrapper id="fff">
									<InnerWrapper>
										<SearchIcon />
										<Input
											width={'100%'}
											borderRadius={'0.2rem'}
											margin={'0'}
											placeholder="Search for people on bōzt"
											padding={'0.8rem 0.8rem 0.8rem 2.5rem'}
											value={searchQuery}
											onChange={(event) => {
												setSearchQuery(event.target.value)
												if (event.target.value.length >= 2) {
													setError('')
													setSearchQuery(event.target.value)

												} else if (event.target.value.length === 0) {
													setData(copyData)
													setError('')
												} else {
													setData(copyData)
													setError('Minimum 2 characters required')
												}
												setSearchName(event.target.value)
											}}
											onKeyDown={(e) => {
												if (e?.key == 'Enter' || e?.key == 'Next' || e?.key == 'Go' || e?.key == 'Done') {
													e.preventDefault();
													if (searchQuery?.trim()?.length >= 2) {
														searchBozter(searchQuery)

													}
												}
											}}
										/>
									</InnerWrapper>
									<Error> {error}</Error>
									<InnerWrapper>
										<Button width={'100%'} color={color.White} padding={'0.7rem'} bgtype={color.Orange} fontSize={'1rem'} borderRadius="0.2rem" margin={'0.5rem 0rem'} label={'Invite'} onClick={() => setOpenModal(true)} />
									</InnerWrapper>
									<SuggestedText>Suggested bōzters</SuggestedText>
								</OuterWrapper>
							)}
							<ListContainer active={tabActive} length={data?.length}>
								{data?.length > 0
									? data.map((item, index) => {
										return (
											<span key={index.toString()}>
												<ContentWrapper>
													<LeftDiv onClick={() => handleClick(item?.id)}>
														<UserImage src={process.env.REACT_APP_IMAGE_URL + item.profile_image} alt="img" />
													</LeftDiv>
													<VerticleRow />
													<ContentDiv onClick={() => handleClick(item?.id)}>
														<UserName>
															{item.first_name + ' ' + item.last_name}&nbsp;
															{item?.degree && (
																<>
																	<span style={{ paddingBottom: '0.5rem' }}>.</span> &nbsp;<span style={{ fontSize: '0.8rem' }}>{item?.degree}</span>
																</>
															)}
														</UserName>
														<Action>{item.designation}</Action>
													</ContentDiv>
													<Button
														maxHeight={'3rem'}
														minWidth={'7rem'}
														fontSize={'1rem'}
														bgtype={color.White}
														borderRadius={'2rem'}
														color={color.SteelBlue}
														shadow={'0rem 0rem 1.25rem rgba(0, 0, 0, 0.1)'}
														margin={'0.8rem'}
														fontWeight={'bold'}
														label={tabActive === 1 ? 'UnFollow' : item.is_follower ? 'Unfollow' : 'Follow'}
														onClick={() => {
															doFollowUnfollowCB(item.id, tabActive === 1 ? 'UnFollow' : item.is_follower)
														}}></Button>
												</ContentWrapper>
												<HorizontalRow />
											</span>
										)
									})
									: loading === false && <EmptyMessage top={'10rem'} message={tabActive === 0 ? 'No suggested bōzters found' : tabActive === 1 ? 'No one in your circle list' : tabActive === 2 && 'No one in your follower list'} />}
							</ListContainer>
							{showMore && !loading && (tabActive === 0 || tabActive === 1 || tabActive === 2) && searchName.length < 1 && (
								<Button width={'100%'} color={color.White} fontWeight="500" bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} label={'Load More'} onClick={() => fetchMoreUsers()} />
							)}
						</TabsContainer>
					</ContentWrapper>
				</Col>
			</CustomRow>
			{openModal && <InviteModal openModal={setOpenModal} />}
			{congrate && <Congrate closeCB={() => setCongrate(false)} parent="follow" />}
			{loading === true && <Loader visible={loading} top={tabActive == 0 ? '10rem' : '6rem'} text={tabActive === 0 ? 'Loading...' : tabActive === 1 ? 'Loading...' : tabActive === 2 && 'Loading...'} />}
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.1rem;
	height: 100%;
`

export const CustomRow = styled(Row)`
	justify-content: center;
`

export const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: max-content;
`

export const ContentDiv = styled.div`
	margin-left: 0rem;
	padding: 0.625rem;
	background-color: ${color.LightCream_10};
	/* height: max-content; */
	width: 100%;
	border-radius: 1.25rem;
`
export const LeftDiv = styled.div`
	margin-left: 0.5rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	height: 4.063rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`

export const UserName = styled.div`
	margin: 0;
	padding: 0;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 15rem;
	white-space: nowrap;
	display: flex;
	align-items: center;
	text-align: center;
	cursor: pointer;

	${media.xs`
	max-width: 6rem;
	overflow-x: scroll;
	`}
	${media.sm`
	max-width: 6rem;
	overflow-x: scroll;
	`}
	${media.md`
	max-width: 15rem;
	overflow: hidden;
	`}
	${media.lg`
	max-width: 15rem;
	overflow: hidden;
	  `}
`
export const ListContainer = styled.div`
	min-height: ${(props) => (props.length > 0 ? 'fit-content' : '12rem')};
	overflow-y: ${(props) => (props.length > 0 ? 'scroll' : 'auto')};
	margin-top: ${(props) => (props.active !== 0 ? `2rem` : '0')};
	background-color: ${(props) => (props.length > 0 ? `${color.LightCream_10}` : 'inherit')};
	color: ${(props) => props.length == 0 && `${color.Black}`};
	margin-bottom: 1rem;
	touch-action: auto;
`

export const Action = styled.p`
	margin: 0;
	color: ${color.DarkPepper_40};
	cursor: pointer;
`

export const HorizontalRow = styled.div`
	border-top: 0.063rem solid ${color.LightGrey};
	width: 100%;
`

export const VerticleRow = styled.div`
	border-left: 0.063rem solid ${color.LightGrey};
	margin-top: 1rem;
	margin-bottom: 1rem;
`

export const TabsContainer = styled.div`
	width: 100%;
`

export const TabListContainer = styled.div`
	display: flex;
	list-style-type: none;
	border: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	justify-content: ${(justifyContent) => (justifyContent ? justifyContent : 'center')};
	background-color: ${color.LightCream_10};
	margin-bottom: ${({ marginBottom }) => marginBottom};
`

const TabMenu = styled.div`
	width: 33.3%;
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 0.2rem;
	display: flex;
	justify-content: center;
	font-size: 1rem;
	align-items: center;
`

export const UserImage = styled.img`
	height: 3.125rem;
	width: 3.125rem;
	border-radius: 1.563rem;
`

export const FirstTab = styled(TabMenu)`
	background-color: ${(props) => (props.active === 0 ? `${color.LighterGrey}` : 'none')};
`
export const SecondTab = styled(TabMenu)`
	background-color: ${(props) => (props.active === 1 ? `${color.LighterGrey}` : 'none')};
`
const ThirdTab = styled(TabMenu)`
	background-color: ${(props) => (props.active === 2 ? `${color.LighterGrey}` : 'none')};
`
export const OuterWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${color.LightCream_40};
`

export const InnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1rem 0rem 0rem 0rem;
	position: relative;
`

const SearchIcon = styled(BsSearch)`
	position: absolute;
	top: 1.85rem;
	z-index: 1;
	left: 0.6rem;
	font-size: 1.3rem;
	color: ${color.Grey};
`

const Error = styled.span`
	color: ${color.Red};
	/* position: absolute; */
	top: 14.5rem;
	${media.xs`
	top: 15.5rem;
	`}
	${media.sm`
    top: 14.5rem;
	`}
	${media.md`
	top: 14.5rem;
	`}
	${media.lg`
	top: 14.5rem;
	  `}
`
const Paragraph = styled.p`
	text-align: center;
`
const SuggestedText = styled.p`
	text-align: left;
	font-weight: 500;
`

export default Circle
