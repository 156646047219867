import React, {useEffect} from 'react'
import {Routes, Route} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {api} from './components/api/callAxios.js'
import Content from './pages/index.js'
import Discover from './pages/Discover.js'
import Resturants from './components/ResturantDetails.js'
import Notification from './components/Notification.js'
import Circle from './components/Circle.js'
import Profile from './components/Profile.js'
import Menu from './components/Menu.js'
import Search from './components/Search.js'
import BoztLikedUserList from './pages/BoztLikedUserList.js'
import MyBadges from './components/MyBadges.js'
import UserBadges from './components/Badges.js'
import UserTaste from './components/UserTaste.js'
import Tastes from './components/Tastes.js'
import UserFollower from './components/UserFollowers.js'
import UserCircle from './components/UserCircle.js'
import UserFav from './components/UserFavourite.js'
import Favorite from './components/Favorites.js'
import MyBookmarks from './components/Bookmarks.js'
import Designations from './components/Designations.js'
import WishList from './components/WishList.js'
import BoztersList from './components/BoztersList.js'
import NotificationSettings from './components/NotificationSettings'
import NotificationDetails from './components/NotificationDetailPage'
import AddFavoriteRest from './components/AddFavoriteRestuarants'
import Navbar from './components/Navbar.js'
import OpenSharedModel from './components/OpenSharedModel.js'
import Signup from './components/Signup.js'

function App() {
	const dispatch = useDispatch()
	const auth_token = localStorage.getItem('token')
	const userData = useSelector((state) => state.userData)
	useEffect(() => {
		const fetchData = async () => {
			await doLogin()
			// eslint-disable-next-line react-hooks/exhaustive-deps
			await getCurrentLatLng()
		}
		fetchData()
	}, [])

	const doLogin = async () => {
		if (localStorage.getItem('token')) {
			await api
				.post(`/user/auto_login`, {fcm_token: ''})
				.then(async (response) => {
					dispatch({
						type: 'SET_USER',
						payload: response?.data.data.user
					})
					dispatch({
						type: 'DESIGNATION',
						payload: response?.data.data.user.designation
					})
					dispatch({
						type: 'CIRCLE_BOZT',
						payload: response?.data.data.my_cicle_bozts.bozts
					})
					dispatch({
						type: 'NOTIFICATIONS_COUNT',
						payload: response.data.data.my_cicle_bozts.notificationCount
					})
					localStorage.removeItem('currentTab')
					localStorage.setItem('token', response?.data.data.user.auth_token)
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				dispatch({
					type: 'LATLNG',
					payload: position?.coords
				})
			},
			function (error) {},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	return (
		<>
			{(auth_token || userData) && <Navbar />}

			{auth_token || userData ? (
				<Routes>
					<Route path="/" element={<Content />}></Route>
					<Route path="/discover" element={<Discover />}></Route>
					<Route path="/rest" element={<Resturants />}></Route>
					<Route path="/notification" element={<Notification />}></Route>
					<Route path="/circle" element={<Circle />}></Route>
					<Route path="/profile" element={<Profile />}></Route>
					<Route path="/menu" element={<Menu />}></Route>
					<Route path="/search" element={<Search />}></Route>
					<Route path="/boztLikedUserList" element={<BoztLikedUserList />}></Route>
					<Route path="/myBadges" element={<MyBadges />}></Route>
					<Route path="/userTaste" element={<UserTaste />}></Route>
					<Route path="/userFollower" element={<UserFollower />}></Route>
					<Route path="/userCircle" element={<UserCircle />}></Route>
					<Route path="/userFav" element={<UserFav />}></Route>
					<Route path="/myFav" element={<Favorite />}></Route>
					<Route path="/myTaste" element={<Tastes />}></Route>
					<Route path="/userBadges" element={<UserBadges />}></Route>
					<Route path="/myBookmarks" element={<MyBookmarks />}></Route>
					<Route path="/designations" element={<Designations />}></Route>
					<Route path="/wishList" element={<WishList />}></Route>
					<Route path="/boztersList" element={<BoztersList />}></Route>
					<Route path="/notificationSettings" element={<NotificationSettings />}></Route>
					<Route path="/notificationDetails" element={<NotificationDetails />}></Route>
					<Route path="/addFavoriteRest" element={<AddFavoriteRest />}></Route>
					<Route path="/share/*" element={<OpenSharedModel />}></Route>
					<Route path="/discover/*" element={<Discover />}></Route>
				</Routes>
			) : (
				<Routes>
					<Route path="/" element={<Content />}></Route>
					<Route path="/signup" element={<Signup />}></Route>
					<Route path="/share/*" element={auth_token || userData ? <OpenSharedModel /> : <Content />}></Route>
					<Route path="/discover/*" element={auth_token || userData ? <Discover /> : <Content />}></Route>
				</Routes>
			)}
		</>
	)
}

export default App
