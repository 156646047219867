/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import Loader from '../components/common/Spinner.js'
import 'react-toastify/dist/ReactToastify.min.css'
import CatagoryCard from './card/CatagoryCard.js'
import {useSearchParams, useNavigate} from 'react-router-dom'
import PageHeader from './common/PageHeader.js'
import {doGetAllUserTastes} from './api/apis.js'

const UserTastes = (props) => {
	const [searchParams] = useSearchParams()
	const [tasteList, setTasteList] = useState([])
	const [loading, setLoading] = useState(false)
	let _u_id = searchParams.get('id')

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetchData = async () => {
		await doFetchUserTaste()
	}
	const doFetchUserTaste = async () => {
		await doGetAllUserTastes(setLoading, _u_id, setTasteList)
	}

	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader padding={'0'} title={'Tastes'} />
					</Col>
				</Row>
				<Row justifyContent="center">
					<ContentCol xs={11} sm={10} md={8} lg={5}>
						<Flex>
							{tasteList.map((item) => {
								return <CatagoryCard cardData={item} selectionCB={() => {}} key={Math.random()} />
							})}
						</Flex>
					</ContentCol>
				</Row>
				{loading && <Loader visible={loading} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3rem;
`

const ContentCol = styled(Col)`
	min-height: 30rem;
	overflow-y: scroll;
`

const Flexed = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`
const Header = styled.p`
	font-family: sans-serif;
	font-weight: 600;
	text-align: center;
	width: 100%;
	font-size: 1.5rem;
	margin: 0;
`

export const Flex = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 0.5rem;
	${media.xs`
	grid-template-columns: 1fr 1fr;
	`}
	${media.sm`
	grid-template-columns: 1fr 1fr 1fr 1fr;
		`}
	${media.md`
	grid-template-columns: 1fr 1fr 1fr 1fr;
	`}
	${media.lg`
	grid-template-columns: 1fr 1fr 1fr 1fr ;
	`}
`

export default UserTastes
