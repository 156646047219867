import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import Loader from '../common/Spinner'
import { useNavigate, createSearchParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { toast } from 'react-toastify'
import { toastStyle } from '../../assets/css/CommonStyle.js'
import { color } from '../../assets/css/Color.js'
import { RiDeleteBin6Line } from 'react-icons/ri'
import Input from '../common/Input'
import MediaViewer, { ClipLoading, MiniSpinner } from '../common/MediaViewer'
import Button from '../common/Button'
import { api } from '../api/callAxios'
import RestaurantModal from './RestaurantModal'
import moment from 'moment-timezone'
import { ClipLoader } from 'react-spinners'
import { useSelector } from 'react-redux'

const CommentModal = (props) => {
	const [boztComment, setBoztComment] = useState([])
	const [boztID, setboztId] = useState(props.data.id ? props.data.id : props.data.b_id)
	const [comment, setComment] = useState('')
	const [commentId, setCommentId] = useState('')
	const [loading, setLoading] = useState(false)
	const [boztData, setBoztData] = useState()
	const [boztMedia, setBoztMedia] = useState()

	const [deleteHover, setDeleteHover] = useState(false)
	const [page, setPage] = useState(1)
	const [showMore, setShowMore] = useState(false)
	const _navigate = useNavigate()

	const [openRestDetails, setOpenRestDetails] = useState(false)
	const [selectedRestaurant, setSelectedRestaurant] = useState(null)
	const [addComment, setAddComment] = useState(false)
	const [selectedComment, setSelectedComment] = useState('')
	const scrollRef = useRef(null);
	const userData = useSelector((state) => state.userData)

	useEffect(() => {
		setBoztMedia(props?.media)
		setboztId(props.data.id ? props.data.id : props.data.b_id)
		getBoztComments(1)
		if (props?.data?.b_id !== undefined) {
			getBoztDetails()
		}
	}, [])

	useEffect(() => {
		if (selectedComment) {
			setComment(selectedComment && `@${selectedComment} ` + comment)
		}
	}, [selectedComment])

	const getBoztComments = async (page = 1) => {
		setLoading(true)
		await api
			.post(`/user/bozt/view_all_bozt_comments`, { b_id: props.data.id ? props.data.id : props.data.b_id, page: page, order: 'desc' })
			.then((response) => {
				if (response.data.success === true) {
					let responseData = response.data.data.all_bozt_comments
					if (scrollRef?.current) {
						scrollRef.current.scrollTop = 0;
					}
					setShowMore(responseData.length >= 15)
					if (page > 1) {
						responseData = boztComment.concat(responseData)
					}
					setSelectedComment('')
					setBoztComment(responseData)
					setLoading(false)
				} else {
					console.log('error', response.data.message)
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				console.log(error)
			})
	}

	const getBoztDetails = async () => {
		setLoading(true)
		await api
			.post(`/user/bozt/detail`, { b_id: props?.data?.b_id })
			.then((response) => {
				setLoading(false)
				setBoztData(response.data?.data?.bozt)
				let _media = []
				if (response.data?.data?.bozt?.images?.length > 0) {
					_media = response.data?.data?.bozt?.images
				}
				if (response.data?.data?.bozt?.video != '') {
					if (_media != undefined) {
						_media.unshift(response.data?.data?.bozt?.video)
					} else {
						_media = [response.data?.data?.bozt?.video]
					}
				}
				let uniq = [...new Set(_media)]
				_media = uniq.map((i) => {
					return { media: i, loading: true }
				})
				setBoztMedia(_media)
			})
			.catch(function (error) {
				setLoading(false)
				console.log('error ' + error)
			})
	}

	const doAddBoztComments = async () => {
		setAddComment(true)
		setLoading(true)
		if (comment == '') {
			toast.error('Please input comment text', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else {
			const data = { b_id: boztID, comment: comment, page: 1, order: 'desc' }
			await api
				.post(`/user/bozt/add_bozt_comment`, data)
				.then(async (response) => {

					if (response.data.success === true) {
						if (scrollRef?.current) {
							scrollRef.current.scrollTop = 0;
						}
						await getBoztComments()
						setComment('')
						setAddComment(false)
					} else {
						setAddComment(true)
						setLoading(false)
						console.log('error', response.data.message)
					}


				})
				.catch(function (error) {
					console.log(error)
					setAddComment(true)
					setLoading(false)
				})
		}
		setLoading(false)
	}

	const doAddBoztCommentsReply = async () => {
		setAddComment(true)
		if (comment == '') {
			toast.error('Please input comment text', {
				position: toastStyle.position,
				autoClose: toastStyle.closeDuration
			})
		} else {
			const data = { b_id: boztID, comment_id: commentId, reply: comment.replace(`@${selectedComment} `, ''), page: 1, order: 'desc' }
			await api
				.post(`/user/bozt/add_bozt_reply`, data)
				.then(async (response) => {
					if (response.data.success === true) {
						if (scrollRef?.current) {
							scrollRef.current.scrollTop = 0;
						}

						await getBoztComments()
						setComment('')
						setSelectedComment('')
						setAddComment(false)
					} else {
						setAddComment(true)
						console.log('error', response.data.message)
					}
				})
				.catch(function (error) {
					console.log(error)
					setAddComment(true)
				})
		}
	}

	const deleteComment = async (c_id, b_id) => {
		await api
			.post(`/user/bozt/bozt_comment_delete`, {
				c_id: c_id,
				b_id: b_id
			})
			.then((response) => {
				if (response.data.success === true) {
					const _messages = boztComment.filter((item) => {
						return item.id != c_id
					})
					setBoztComment(_messages)
					toast.success('Comment Delete Successfully!', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				} else {
					toast.error('comment cannot be Deleted!', {
						position: toastStyle.position,
						autoClose: toastStyle.closeDuration
					})
				}
			})
			.catch((error) => {
				console.log({ type: 'error', description: error.message })
			})
	}

	const handleShowMore = () => {
		setPage(page + 1)
		getBoztComments(page + 1)
	}

	const handleClose = () => {
		props.handleClose(boztComment.length)
	}

	return (
		<>
			{!openRestDetails ? (
				<Modal
					open={true}
					onClose={handleClose}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'commentModal'
					}}>
					<Wrapper>
						<CustomRow>
							{boztMedia !== undefined && boztMedia?.length > 0 ? (
								<LeftCol xs={12} sm={12} md={12} lg={5}>
									<Flex>{boztMedia?.length > 0 && <MediaViewer boztMedia={boztMedia} parent={'commentModel'} />}</Flex>
								</LeftCol>
							) : null}
							<RightCol xs={12} sm={12} md={12} lg={boztMedia !== undefined && boztMedia?.length > 0 ? 7 : 12} >
								<HeaderWrapper>
									<div>
										<UserIcon
											src={
												props?.data?.user !== undefined
													? props?.data?.user?.profile_image
														? process.env.REACT_APP_IMAGE_URL + props?.data?.user?.profile_image
														: ``
													: boztData !== undefined && process.env.REACT_APP_IMAGE_URL + boztData?.user?.profile_image
											}
											onClick={() => {
												_navigate({
													pathname: '/profile',
													search: createSearchParams({
														id: props.data?.u_id ? props.data?.u_id : props.data?.user?.id
													}).toString()
												})
											}}
										/>
									</div>
									<div>
										<Flexed align="center" gap="0.5rem">
											<Title
												onClick={() => {
													_navigate({
														pathname: '/profile',
														search: createSearchParams({
															id: props.data?.u_id ? props.data?.u_id : props.data?.user?.id
														}).toString()
													})
												}}>
												{props.data?.user !== undefined ? props.data?.user?.full_name : boztData !== undefined && boztData?.user?.full_name}
											</Title>
											<Flexed align="center" gap="0.3rem">
												{props?.data?.user?.degree || props.data?.degree && userData?.id != props?.data?.user?.id || userData?.id != props?.data?.id && (
													<>
														<Dot />
														<DegreeText>{props.data?.degree ? props.data?.degree : props?.data?.user?.degree}</DegreeText>
													</>
												)}
											</Flexed>
										</Flexed>
										<Flexed gap="0.3rem">
											<Address
												onClick={() => {
													setSelectedRestaurant(props.data)
													setOpenRestDetails(true)
												}}>
												{props.data?.r_title !== undefined ? props.data?.r_title : boztData !== undefined && boztData?.r_title}
											</Address>
											<Flexed gap="0.3rem">
												<div>
													<Dot marginTop="0.3rem" />
												</div>{' '}
												<AddressDetail> {props.data?.city !== undefined ? props.data?.city + ', ' + props?.data?.state : boztData !== undefined && boztData?.city + ', ' + boztData?.state}</AddressDetail>
											</Flexed>
										</Flexed>
									</div>
								</HeaderWrapper>
								<Body>
									<SrollView ref={scrollRef}>
										{addComment === true
											? ''
											: loading && (
												<MiniSpinner>
													<ClipLoading />
												</MiniSpinner>
											)}
										{boztComment?.map((comment, index) => {
											// eslint-disable-next-line no-lone-blocks
											return (
												<Flexed margin="0rem 0rem 0.5rem 0rem" gap="0.5rem" direction="column" onMouseEnter={() => setDeleteHover(index)} onMouseLeave={() => setDeleteHover(false)} key={index.toString()}>
													<Flexed gap="0.3rem">
														<div>
															<ProfileImage
																src={comment.user.avatar ? `${comment.user.avatar}` : ``}
																onClick={() =>
																	_navigate({
																		pathname: '/profile',
																		search: createSearchParams({
																			id: comment?.user?._id
																		}).toString()
																	})
																}
															/>
														</div>
														<Flexed gap="0.2rem" direction="column">
															<UserName
																onClick={() =>
																	_navigate({
																		pathname: '/profile',
																		search: createSearchParams({
																			id: comment?.user?._id
																		}).toString()
																	})
																}>
																{comment.user.name}
															</UserName>
															<UserMessage color={color.DarkPepper_40}> {comment.text} </UserMessage>
															<Flexed gap="0.5rem">
																<UserMessage color={color.DarkPepper_20} margin={'0'}>
																	{' '}
																	{moment(comment.createdAt).fromNow(true)}{' '}
																</UserMessage>
																{comment?.likes > 0 && (
																	<UserMessage color={color.DarkPepper_20} margin={'0'}>
																		{' '}
																		{comment?.likes}
																		{comment?.likes > 1 ? 'likes' : 'like'}{' '}
																	</UserMessage>
																)}

																<UserMessage
																	color={color.DarkPepper_20}
																	margin={'0'}
																	cursor={'pointer'}
																	onClick={() => {
																		setSelectedComment(comment.user.name)
																		setCommentId(comment.id)
																	}}>
																	{' '}
																	Reply{' '}
																</UserMessage>
																<DeleteComment>{comment?.isMeComment && <DeleteIcon visible={index === deleteHover} onClick={() => deleteComment(comment.id, comment.user.b_id)} />}</DeleteComment>
															</Flexed>
														</Flexed>
													</Flexed>

													<Flexed margin="0rem 0rem 0rem 3rem">
														<CustomCol display={'grid'} marginLeft={'-1.5em'} height={comment?.reply?.length > 6 && '15rem'} overflow={comment?.reply?.length > 6 && 'scroll'} width={'max-content'}>
															{comment?.reply?.length
																? comment?.reply?.map((e, i) => {
																	return (
																		<CustomCol display={'grid'} height={'max-content'}>
																			<Flexed gap="0.3rem">
																				<div>
																					<ProfileImage
																						src={e?.repliedUser?.profile_image ? `${process.env.REACT_APP_IMAGE_URL + e?.repliedUser?.profile_image}` : ``}
																						onClick={() =>
																							_navigate({
																								pathname: '/profile',
																								search: createSearchParams({
																									id: e?.repliedUser?.id
																								}).toString()
																							})
																						}
																					/>
																				</div>
																				<Flexed gap="0.2rem" direction="column">
																					<UserName
																						onClick={() =>
																							_navigate({
																								pathname: '/profile',
																								search: createSearchParams({
																									id: e?.repliedUser?.id
																								}).toString()
																							})
																						}>
																						{e.repliedUser.first_name + ' ' + e.repliedUser.last_name}
																					</UserName>
																					<UserMessage margin={'1em 0 0 0em'} color={color.DarkPepper_40}>
																						{' '}
																						{e.reply}{' '}
																					</UserMessage>
																					<Flexed gap="0.5rem">
																						<UserMessage color={color.DarkPepper_20} margin={'0'}>
																							{' '}
																							{moment(e.createdAt).fromNow(true)}
																						</UserMessage>
																						<UserMessage
																							color={color.DarkPepper_20}
																							margin={'0'}
																							cursor={'pointer'}
																							onClick={() => {
																								setSelectedComment(comment.user.name)
																								setCommentId(comment.id)
																							}}>
																							{' '}
																							Reply{' '}
																						</UserMessage>
																					</Flexed>
																				</Flexed>
																			</Flexed>
																		</CustomCol>
																	)
																})
																: ''}
														</CustomCol>
													</Flexed>
												</Flexed>
											)
										})}
										{showMore && <Button margin={'0rem 0 0.3rem 0rem'} onClick={handleShowMore} label="Load More"></Button>}
									</SrollView>
									<Footer>
										<WriteComment>
											<Input
												placeholder="Type a comment..."
												onChange={(event) => {
													setComment(event.target.value)
												}}
												value={comment}
												border={'none'}
												padding={'0'}
												margin={'0'}
											/>
											{addComment && (
												<Spinner left={boztMedia !== undefined && boztMedia?.length > 0 ? '-5rem' : '-16rem'} position={'relative'} top={'0.5rem'}>
													<ClipLoader color="#000000" height={50} width={50} />
												</Spinner>
											)}
											<Button
												disabled={comment.trimStart()?.length == 0 || loading ? true : false}
												onClick={() => {
													comment.search(`@${selectedComment} `) == 0 ? doAddBoztCommentsReply() : doAddBoztComments()
												}}
												label="Post"
												fontSize={'1rem'}
												color={comment.trimStart()?.length === 0 || loading ? color.Grey : color.Orange}></Button>
										</WriteComment>
									</Footer>
								</Body>
							</RightCol>
						</CustomRow>
					</Wrapper>
				</Modal>
			) : (
				<RestaurantModal setIsOpen={setOpenRestDetails} background={true} r_id={selectedRestaurant.r_id} id={selectedRestaurant?.id} lat={selectedRestaurant.lat} lng={selectedRestaurant.lng} />
			)}
		</>
	)
}

const Wrapper = styled(Container)`

	padding: 0;
	max-width: 70rem;
	min-height: 20rem;
	height: 100% !important;
	max-height: 100% !important;
	border-radius: 0.75rem !important;
	// overflow: auto;
	text-align: center;

	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: 40rem;
   `}
`

const Flex = styled(Row)`
	display: flex;
	align-items: space-between;
	align-items: center;
	position: relative;
	padding: 0;
	margin: 0;
	height: inherit;
	overflow: hidden;
	overflow-y: scroll;
`

const Dot = styled.div`
	width: 0.25rem;
	height: 0.25rem;
	border-radius: 1rem;
	background: ${color.DarkPepper_60};
	margin-top: ${({ marginTop }) => marginTop};
`
const DegreeText = styled.p`
	margin: 0;
	font-size: 0.7rem;
	color: ${color.DarkPepper_60};
	${media.md`
		font-size: 0.8rem;
	`}
`

const RightCol = styled(Col)`
	display: flex;
	flex-direction: rows;
	justify-content: space-between;
	height: 26rem;
	max-height: 26rem;
	flex-wrap: wrap;
	align-content: space-between;
	overflow: hidden;
`

const LeftCol = styled(Col)`
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;
	justify-content: center;
	// height: 26rem;
	// max-height: 26rem;
	${media.lg`
	border-right: 0.2px solid rgba(0, 0, 0, 0.5);
	`}
`

const CustomRow = styled(Row)`
	display: flex;
	justify-content: center;
	max-height: 26rem;
`

const CustomCol = styled(Col)`
	display: ${({ display }) => (display ? display : 'flex')};
	gap: ${({ gap }) => gap};
	justify-content: start;
	align-items: flex-start;
	margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '')};
	overflow-y: ${({ overflow }) => (overflow ? overflow : '')};
	height: ${({ height }) => (height ? height : '')};
	width: ${({ width }) => (width ? width : '')};
	margin-top: ${({ marginTop }) => (marginTop ? marginTop : '')};
	padding: ${({ padding }) => (padding ? padding : '')};
`

const UserName = styled.p`
	text-align: left;
	font-size: 0.8rem;
	// overflow: hidden;
	// display: inline-block;
	// text-overflow: ellipsis;
	// white-space: nowrap;
	cursor: pointer;
	font-weight: 500;
	color: ${color.Black};
	margin: 0;
`

const UserMessage = styled.p`
	text-align: left;
	font-size: 0.8rem;
	margin: ${({ margin }) => (margin ? margin : '')};
	cursor: ${({ cursor }) => (cursor ? cursor : '')};
	color: ${({ color }) => (color ? color : 'color.DarkPepper_40')};
	margin: 0;
	overflow-wrap: anywhere;
`

const UserIcon = styled.img`
	cursor: pointer;
	height: 2.313rem;
	width: 2.313rem;
	min-width: 2.313rem;
	border-radius: 1.563rem;
`
const ProfileImage = styled.img`
	cursor: pointer;
	height: 1.8rem;
	width: 1.8rem;
	min-width: 1.8rem;
	border-radius: 1.563rem;
	margin-top: 0.15rem;
`
const WriteComment = styled.div`
	margin-left: 0rem;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 3.125rem;
	width: 100%;
	border-top: 0.3px solid rgba(0, 0, 0, 0.5);
`

const Footer = styled.div`
	display: flex;
`

const SrollView = styled.div`
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	max-height: 18rem;
`
const DeleteComment = styled.div`
	position: relative;
`
const DeleteIcon = styled(RiDeleteBin6Line)`
	color: ${color.Red};
	cursor: pointer;
	display: ${({ visible }) => (visible === true ? 'block' : 'none')};
	position: absolute;
`
const HeaderWrapper = styled.div`
	min-width: 100%;
	min-height: 3.6rem;
	max-height: 5rem;
	display: flex;
	justify-content: start;
	align-items: center;
	border-bottom: 0.3px solid ${color.Grey};
	gap: 0.5rem;
`
const CustomCol2 = styled(Col)`
	display: inline-block;
	height: inherit;
	text-align: start;
`
const Title = styled.h5`
	cursor: pointer;
	font-weight: 500;
	line-height: 1.4rem;
	font-family: sans-serif;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
	max-width: 28rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 1;
	font-size: 1rem;
	text-align: left;
`
const Description = styled.p`
	font-size: 0.75rem;
	padding: 0;
	margin: 0;
	overflow: hidden;
	// display: inline-block;
	text-overflow: ellipsis;
	height: 2rem;
	color: ${color.Grey};
`
const Address = styled.span`
	color: ${color.Orange};
	cursor: pointer;
	font-size: 0.75rem;
	text-align: left;
`
const AddressDetail = styled.span`
	font-size: 0.75rem;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
	max-width: 28rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
	text-align: left;
`
const Body = styled.div`
	min-width: 100%;
`

const HeartIcon = styled.img`
	height: 1rem;
	width: 1rem;
	/* margin-right: 0.313rem;
	margin-bottom: 0.313rem; */
	cursor: pointer;
	filter: ${({ isLike }) => (isLike ? `invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%)` : `brightness(50%)`)};
	${media.xs`
  height: 0.938rem;
  width: 0.938rem;
  `}
	${media.sm`
  height: 1rem;
  width: 1rem;
  `}
  ${media.md`
  height: 1rem;
  width: 1rem;
  `}
`
const LikesCount = styled.p``

const LikesDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Flexed = styled.div`
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	gap: ${({ gap }) => gap};
	margin: ${({ margin }) => margin};
`

export const Spinner = styled.div`
	position: ${({ position }) => (position ? position : 'absolute')};
	z-index: 99;
	right: 0;
	bottom: 0;
	top: ${({ top }) => (top ? top : 0)};
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	${media.xs`
	left: -5.5rem;
  	`};
	${media.sm`
	left: -5.5rem;
  	`};
	${media.md`
	  left: -16rem;
  	`};
	${media.lg`
	  left: ${({ left }) => (left ? left : 0)};
  	`};
	${media.xl`
	left: ${({ left }) => (left ? left : 0)};
  	`}
`
export default CommentModal
