import styled from 'styled-components'
import {Row} from 'styled-bootstrap-grid'

const EmptyMessage = ({message, top}) => {
	console.log('message', message)
	return (
		<MessageContainer top={top}>
			<div>
				<Centeralized>
					<Message>{message}</Message>
				</Centeralized>
			</div>
		</MessageContainer>
	)
}

const MessageContainer = styled(Row)`
	display: flex;
	width: 100%;
	justify-content: center;
	position: relative;
	top: ${({top}) => (top ? top : '10rem')};
	margin-left: 0.5rem;
`

const Centeralized = styled.div`
	display: flex;
	justify-content: center;
`
const Message = styled.div`
	font-size: 1rem;
	top: 4rem !important;
`

export default EmptyMessage
