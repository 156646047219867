let initialState = {
	userData: '',
	userLastName: '',
	latLng: null,
	notificationCount: 0,
	circleBozts: []
}

export default function reducer(currentState = initialState, action) {
	switch (action.type) {
		case 'SET_USER':
			return Object.assign({}, currentState, {
				userData: action.payload
			})
		case 'LATLNG':
			return Object.assign({}, currentState, {
				latLng: action.payload
			})
		case 'DESIGNATION':
			return Object.assign({}, currentState, {
				userDesignation: action.payload
			})
		case 'NOTIFICATIONS_COUNT':
			return Object.assign({}, currentState, {
				notificationCount: action.payload
			})
		case 'CIRCLE_BOZT':
			return Object.assign({}, currentState, {
				circleBozts: action.payload
			})
		default:
			return currentState
	}
}
