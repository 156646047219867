import React from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { Col, Container, Row, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { color } from '../../assets/css/Color'
import { RightWrapper } from '../modal/FilterModal'
import Button from './Button'
import EmptyMessage from './EmptyMessage'
import Loader from './Spinner'
import { useSelector } from 'react-redux'

const UserCard = ({ userData, loading, title, emptyMessage, showMore, fetchMore, path, userFav, removeRestaurant, loaderText, setOpenRestDet, setRestID, setBoztID, userFolow, onFollowCb }) => {
	const _navigate = useNavigate()
	const [searchParams] = useSearchParams()
	let u_id = searchParams.get('id')
	const _own_id = useSelector((state) => state?.userData?.id)
	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<ToogleContainer userFav={userFav}>
							<ToggleButton
								onClick={() => {
									_navigate(-1)
								}}>
								<Icon /> Back
							</ToggleButton>
							<TextContainer width={userFav === true && 'auto'}>
								<Text>{title} </Text>
							</TextContainer>
							{userFav === true && (
								<AddFavText
									onClick={() => {
										_navigate({
											pathname: '/addFavoriteRest'
										})
									}}>
									Add Favorite
								</AddFavText>
							)}
						</ToogleContainer>
						{userFav === true && (
							<Col>
								<CustomParagraph>To create your Favorites List, click on Add Favorite. You can add places from near and far by changing the location.</CustomParagraph>
							</Col>
						)}
						{userData.length
							? userData?.map((data) => {
								return (
									<ContentWrapper
										key={Math.random()}
										onClick={() => {
											if (userFav) {
											} else if (path === '/rest') {
											} else if (userFolow) { } else {
												_navigate({
													pathname: path ? path : '/profile',
													search: createSearchParams({
														id: path ? data?.r_id : data?.id
													}).toString()
												})
											}
										}}>
										<LeftDiv onClick={() => {
											if (userFolow) {
												_navigate({
													pathname: path ? path : '/profile',
													search: createSearchParams({
														id: path ? data?.r_id : data?.id
													}).toString()
												})
											}
										}}>
											{title === 'Favorites' ? (
												<CustomDiv>
													<Content fontWeight={userFav === true && '500'} color={data?.billboard ? color.Orange : data?.is_awarded ? color.Green : color.DarkPepper_40}>
														{data?.distance && data?.distance?.toFixed(2)}
													</Content>
													<Content color={data?.billboard ? color.Orange : data?.is_awarded ? color.Green : color.DarkPepper_40}>miles</Content>
												</CustomDiv>
											) : (
												<UserImage src={process.env.REACT_APP_IMAGE_URL + data?.profile_image} alt="img" />
											)}
										</LeftDiv>
										<VerticleRow />
										{userFav === true ? (
											<RightWrapper>
												<Details
													order={'0'}
													padding={'0.3rem 0 0 0.75rem'}
													onClick={() => {
														setRestID(data?.r_id)
														setBoztID(data?.id)
														setOpenRestDet(true)
													}}>
													<Title style={{ color: title === 'Favorites' && data?.billboard ? color.Orange : data?.is_awarded ? color.Green : '' }}>{data.title}</Title>
													<Content color={title === 'Favorites' && data?.billboard ? color.Orange : data?.is_awarded ? color.Green : color.DarkPepper_40}>{data?.address}</Content>
												</Details>
												<Button
													maxHeight={'3rem'}
													minWidth={'7rem'}
													fontSize={'1rem'}
													bgtype={color.White}
													borderRadius={'2rem'}
													color={color.SteelBlue}
													shadow={'0rem 0rem 1.25rem rgba(0, 0, 0, 0.1)'}
													fontWeight={'bold'}
													label={'Remove'}
													padding={'0.8rem'}
													onClick={() => removeRestaurant(data?.r_id)}></Button>
											</RightWrapper>
										) : (
											<ContentDiv
												onClick={() => {
													if (path === '/rest') {
														setRestID(data?.r_id)
														setOpenRestDet(true)
													}
													if (userFolow) {
														_navigate({
															pathname: path ? path : '/profile',
															search: createSearchParams({
																id: path ? data?.r_id : data?.id
															}).toString()
														})
													}
												}}>
												{title === 'Favorites' ? (
													<>
														<Title>{data?.title}</Title>
														<Content color={color.DarkPepper_60}>{data?.address}</Content>
													</>
												) : (
													<>
														<UserName>
															{title === 'Followers' ? data?.first_name + ' ' + data?.last_name : data?.full_name}&nbsp;
															{data?.degree?.length > 2 && (
																<>
																	<span style={{ paddingBottom: '0.5rem' }}>.</span>&nbsp;<span style={{ fontSize: '0.8rem' }}>{data?.degree}</span>
																</>
															)}
														</UserName>
														<Action>{data?.designation}</Action>
													</>
												)}

											</ContentDiv>

										)}
										{userFolow && _own_id == u_id ? <RightWrapper>
											<Button
												maxHeight={'3rem'}
												minWidth={'7rem'}
												fontSize={'1rem'}
												bgtype={color.White}
												borderRadius={'2rem'}
												color={color.SteelBlue}
												shadow={'0rem 0rem 1.25rem rgba(0, 0, 0, 0.1)'}
												fontWeight={'bold'}
												label={data?.is_follower ? 'Follow' : 'UnFollow'}
												padding={'0.8rem'}
												onClick={() => onFollowCb(data?.id, data?.is_follower)}></Button>
										</RightWrapper> : ''}
									</ContentWrapper>
								)
							})
							: loading === false && <EmptyMessage message={emptyMessage} top={'8rem'} />}
						{showMore && userData?.length ? (
							<ShowMore>
								<Button width={'100%'} color={color.White} bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} label={'Load More'} onClick={fetchMore} />
							</ShowMore>
						) : (
							''
						)}
					</Col>
				</CustomRow>
				{loading && <Loader visible={loading} text={loaderText} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.5rem;
`
const CustomDiv = styled.div`
	display: block;
`
const CustomRow = styled(Row)`
	justify-content: center;
`

const ContentWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: ${color.White};
	cursor: pointer;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem;
	&:not(:last-child) {
		border-bottom: 0.063rem solid ${color.LightGrey};
	}
`

const LeftDiv = styled.div``

const UserImage = styled.img`
	height: 3.125rem;
	width: 3.125rem;
	border-radius: 1.563rem;
	background-color: #e4e6e7;
`

const UserName = styled.p`
	margin: 0;
	padding: 0;
	font-weight: 500;
	display: flex;
	align-items: center;
`

const VerticleRow = styled.div`
	border-left: 0.063rem solid ${color.LightGrey};
	height: 3rem;
`

const ContentDiv = styled.div`
	margin-left: 0rem;
	padding: 0.75rem;
	background-color: ${color.LightCream_10};
	/* height: 4.063rem; */
	width: 100%;
	border-radius: 1.25rem;
`
const Action = styled.p`
	margin: 0rem;
	color: ${color.DarkPepper_60};
`

const ToogleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${({ userFav }) => (userFav ? 'space-between' : '')};
`

const ToggleButton = styled.div`
	padding: 0.5rem 0rem;
	display: flex;
	align-items: center;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`
const Icon = styled(BsChevronLeft)`
	font-size: 1rem;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`
const TextContainer = styled.div`
	width: ${({ width }) => (width ? width : '100%')};
	text-align: center;
`
const Content = styled.p`
	margin: 0rem;
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '500')};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
	color: ${({ color }) => color};
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
	overflow: hidden;
	line-height: 1.2rem;
	${media.xs`
    -webkit-line-clamp: 3;
    `};
	${media.sm`
    -webkit-line-clamp: 2;
    `};
`
const Title = styled.p`
	margin: 0rem;
	font-family: sans-serif;
	font-weight: 500;
	width: 100%;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
`
const Text = styled.h1`
	font-family: sans-serif;
	font-weight: 500;
	text-align: center;
	width: 84%;
	font-size: 1.17em;
	margin: 0;
`
const ShowMore = styled.div`
	margin-top: 0.3rem;
`
const AddFavText = styled.p`
	cursor: pointer;
	font-size: 1rem;
	font-family: sans-serif;
	font-weight: 500;
	text-align: end;
	color: ${color.Green};
`

const CustomParagraph = styled.p`
	text-align: center;
`

const Details = styled.div`
	width: calc(100% - 200px);
`

export default UserCard
