import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flex} from './Tastes.js'
import Loader from './common/Spinner.js'
import CatagoryCard from './card/CatagoryCard.js'
import {useNavigate, createSearchParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import PageHeader from './common/PageHeader.js'
import {doFetchUserOwnBadges} from './api/apis.js'

const MyBadges = () => {
	const _navigate = useNavigate()
	const [totalBadges, setTotalBadges] = useState([])
	const [loading, setLoading] = useState(false)
	const userData = useSelector((state) => state?.userData)

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		await fetchOwnBadges()
	}

	const fetchOwnBadges = async () => {
		await doFetchUserOwnBadges(setLoading, ownbBadgesHandlerCB)
	}

	//Call back for user own badges
	const ownbBadgesHandlerCB = async (_allBadges, _myBadges) => {
		for (let index = 0; index < _allBadges.length; index++) {
			for (let index1 = 0; index1 < _myBadges.length; index1++) {
				if (_allBadges[index].id == _myBadges[index1].badge_id) {
					_allBadges[index].isSelected = true
					break
				} else {
					_allBadges[index].isSelected = false
				}
			}
		}
		_allBadges.sort(function (a, b) {
			return a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1
		})
		setTotalBadges(_allBadges)
		setLoading(false)
	}

	const navigationHandler = (name) => {
		if (name == 'bozt' || name == 'bōzt' || name == 'bio' || name == 'homecity' || name == 'home city' || name == 'profile') {
			_navigate({
				pathname: '/profile',
				search: createSearchParams({
					id: userData.id
				}).toString()
			})
		} else if (name == 'designation') {
			_navigate({
				pathname: '/designations'
			})
		} else if (name == 'favorite') {
			_navigate({
				pathname: '/addFavoriteRest'
			})
		} else if (name == 'invite' || name == 'follow') {
			_navigate({
				pathname: '/circle',
				search: createSearchParams({
					back: 'back'
				}).toString()
			})
		}
	}

	return (
		<>
			<Wrapper>
				<CustomRow>
					<Col xs={11} sm={10} md={8} lg={5}>
						<PageHeader title={'Badges'} padding={'0'} />
					</Col>
				</CustomRow>
				<CustomRow>
					<ContentCol xs={11} sm={10} md={8} lg={5}>
						<TextContainer>Complete these tasks to earn the below Badges. Achieve all Badges to receive the Tier 1 Badge.</TextContainer>

						<Flex>
							{totalBadges.map((item) => {
								return <CatagoryCard cardData={item} type={'badges'} selectionCB={navigationHandler} key={Math.random()} />
							})}
						</Flex>
					</ContentCol>
				</CustomRow>
				{loading && <Loader visible={loading} />}
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 3.2rem;
`
const CustomRow = styled(Row)`
	justify-content: center;
`
const ContentCol = styled(Col)`
	min-height: 30rem;
`
const TextContainer = styled.p`
	font-size: 1rem;
	font-family: sans-serif;
	text-align: center;
	width: 100%;
`

export default MyBadges
