import React from 'react'
import styled from 'styled-components'
import {color} from '../assets/css/Color.js'
import 'react-multi-carousel/lib/styles.css'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {BsChevronLeft} from 'react-icons/bs'
import EmptyMessage from './common/EmptyMessage.js'
import RestaurantsList from './common/RestaurantsList.js'
import Button from './common/Button.js'
import mapdummy from '../assets/images/mapdummy.png'

const List = ({boztData, fetchNextNearByResturantsCB, showRestDetailCB, setShowList}) => {
	const onClose = () => setShowList(false)

	return (
		<Wrapper>
			<CustomRow>
				<Col xs={11} sm={10} md={8} lg={5}>
					<ButtonsContainer>
						<HeaderButton onClick={onClose}>
							<BackArrow /> Back
						</HeaderButton>
						<HeaderButton onClick={onClose}>
							<ToggleIcon src={mapdummy} />
						</HeaderButton>
					</ButtonsContainer>

					{boztData?.length > 0 ? (
						boztData.map((item, index) => {
							return <RestaurantsList discover={true} restaurantsList={item} key={index.toString()} setSelectedRestaurant={(e) => showRestDetailCB(e?.r_id)} />
						})
					) : (
						<EmptyMessage message={'No restaurants found'} />
					)}
					{boztData.length >= 20 ? <Button width={'100%'} color={color.White} fontWeight="500" bgtype={color.Orange} fontSize={'1rem'} height={'3.125rem'} label={'Load More'} onClick={() => fetchNextNearByResturantsCB()} /> : null}
				</Col>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	max-width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: ${color.LightCream_40};
	position: relative;
	top: 4rem;
`

const CustomRow = styled(Row)`
	justify-content: center;
`
const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const HeaderButton = styled.div`
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		filter: invert(47%) sepia(79%) saturate(477%) hue-rotate(325deg) brightness(92%) contrast(90%);
	}
`
const ToggleIcon = styled.img`
	font-size: 2rem;
	font-weight: bold;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
	filter: brightness(50%);
	${media.xs`
  height: 1.375rem;
  width: 1rem;
`}
	${media.sm`
  height: 1.2rem;
  width: 0.8rem;
`}
${media.md`
height: 1.4rem;
  width: 1rem;
`}
${media.lg`
height: 1.6rem;
  width: 1.3rem;
  `}
`

const BackArrow = styled(BsChevronLeft)`
	font-size: 1rem;
	&:hover {
		color: ${color.Orange};
		cursor: pointer;
	}
`
export default List
