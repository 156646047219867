import styled from 'styled-components'

export const Spacer = styled.div`
	height: ${({ height }) => `${height}rem`};
`

export const Flexed = styled.div`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin: ${({ margin }) => `${margin}`};
`