import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, media } from 'styled-bootstrap-grid'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { AiOutlineCopy } from 'react-icons/ai'
import { color } from '../../assets/css/Color.js'
import copy from '../../assets/images/copy.png'
import { FiMail } from 'react-icons/fi'
import { BsCheckCircle } from 'react-icons/bs'

const ShareModal = (props) => {
	const [popup, setPopup] = useState(false)
	const [copyText, setCopyText] = useState(false)

	const togglePopup = () => {
		setTimeout(setValue, 800)
	}

	const setValue = () => {
		setPopup(false)
	}

	const copyToClipboard = (text) => {
		const textarea = document.createElement('textarea')
		textarea.value = text

		document.body.appendChild(textarea)
		textarea.select()
		document.execCommand('copy')
		document.body.removeChild(textarea)

		console.log('Text copied to clipboard successfully:', text)
	}

	let _text =
		props?.parent == 'restaurant'
			? `Check out this great restaurant on the bōzt App https://boztapp.com/share?id=R${props?.id}%26platform=web Love it, bōzt it! https://boztapp.com/`
			: `Check out this restaurant recommendation on the bōzt App https://boztapp.com/share?id=${props?.id}%26platform=web Love it, bōzt it! https://boztapp.com/`

	return (
		<Container>
			<Row>
				<Modal
					open={true}
					onClose={() => {
						props.openModal(false)
					}}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModalShare'
					}}>
					<Flexed direction="column" justify="center" align="center" width="20rem">
						<Span weight={500} style={{ fontSize: '1.5rem', padding: '0.5rem' }}>
							Share Restaurant
						</Span>
						<span style={{ padding: '0.5rem' }}>How would you like to share?</span>
						<HorizontalRow />
						<Listview
							onClick={() => {
								window.open(`mailto:Enter Email Here?subject= ${props?.parent == 'restaurant' ? 'Check out this great restaurant on the bōzt App' : 'Check out this restaurant recommendation on the bōzt App'}&body=${_text}`)
								props.invitedCB()
							}}>
							<IconContainer>
								<FiMail />
							</IconContainer>
							Email
						</Listview>
						<HorizontalRow />
						<Listview
							color={copyText === true && color.Green}
							onClick={() => {
								let text = props?.parent == 'restaurant' ? `https://boztapp.com/share?id=R${props?.id}&platform=web` : `https://boztapp.com/share?id=${props?.id}&platform=web`
								if (window.location.protocol == 'http:') {
									copyToClipboard(text)
								} else {
									navigator.clipboard.writeText(text)
								}
								setCopyText(true)

								setPopup(true)
								togglePopup()
							}}>
							<IconContainer>{copyText === false ? <Copy src={copy} /> : <BsCheckCircle color={color.Green} />}</IconContainer>
							Copy Link
						</Listview>
						{popup && (
							<Popup>
								<AiOutlineCopy />
								Copied to clipboard
							</Popup>
						)}
						<HorizontalRow />
					</Flexed>
				</Modal>
			</Row>
		</Container>
	)
}

const Flexed = styled.div`
	position: relative;
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin: ${({ margin }) => `${margin}`};
	width: ${({ width }) => `${width}`};
	${media.xs`
	width: auto;
  `}
	${media.sm`
	width: auto;
  `}
  ${media.md`
  width: ${({ width }) => `${width}`};
  `}
`
const Listview = styled.li`
	background: ${color.LightCream_60};
	position: relative;
	list-style: none;
	padding: 1rem;
	width: 100%;
	color: ${({ color }) => `${color}`};
	font-weight: ${({ weight }) => `${weight}`};
	cursor: pointer;
	display: flex;
	justify-content: start;
	align-items: center;
	box-shadow: 0rem 0.05rem 0.1rem ${color.LightCream_60};
`

const HorizontalRow = styled.div`
	margin-top: 0.6rem;
	width: 100%;
	border: none;
`
const IconContainer = styled.div`
	padding: 0rem 0.5rem;
	display: flex;
	align-items: center;
`

const Popup = styled(Listview)`
	position: absolute;
	width: 15rem;
	border-radius: 0.5rem;
	background-color: ${color.Grey};
	top: 7rem;
	color: ${color.White};
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
`
const Span = styled.span`
	font-weight: ${(weight) => weight};
	font-family: sans-serif;
`
const Copy = styled.img`
	width: 1rem;
`
export default ShareModal
